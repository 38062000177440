import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { createJobSearch, fetchJobSearchesInterviews } from 'actions/jobSearchActions';
import { rescheduleInterview } from 'actions/interviewInvitationActions';

import { getAgencyId, getClientId, getClientMenuInformation, getJobSearchesInterviews, getUserId } from 'reducers';

import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import { CircularProgress } from '@material-ui/core';
import SelectFilterList from 'components/Shared/SelectFilterList';
import InterviewDetails from 'components/Shared/InterviewDetails';
import InterviewInvitePopup from 'components/Shared/InterviewInvitePopup';
import IdealCandidateFormik from 'components/Dashboard/IdealCandidateFormik';
import JobDialogFormik from 'components/Dashboard/JobDialogFormilk/JobDialogFormik';

import { ReactComponent as PlusIcon } from 'assets/plus-icon.svg';

import { ROLES } from 'constants/userConstants';
import { INTERVIEW_INVITATION_CONTRIBUTORS, INTERVIEW_INVITATION_STATUSES } from 'constants/interviewInvitationConstants';

import { getInterviewStatus } from 'utils/jobSearchesUtils';
import { getStateAgencyName } from 'utils/helpers';

import './Interviews.scss';

const Interviews = ({
    agencyId,
    clientId,
    userId,
    clientName,
    interviews,
    fetchJobSearchesInterviews,
    rescheduleInterview,
    createJobSearch,
}) => {
    const history = useHistory();

    const INTERVIEW_STATUSES = {
        UPCOMING: 'Upcoming',
        COMPLETED: 'Completed'
    };

    const [statusFilterValue, setStatusFilterValue] = useState(INTERVIEW_STATUSES.UPCOMING);
    const [areInterviewsLoading, setAreInterviewsLoading] = useState(true);
    const [interviewData, setInterviewData] = useState(null);
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);

    const [isIdealCandidateDialogOpen, setIsIdealCandidateDialogOpen] = useState(false);
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);

    const [idealCandidateFormValues, setIdealCandidateFormValues] = useState(null);

    const handleOpenIdealDialog = () => setIsIdealCandidateDialogOpen(true);
    const handleCloseIdealDialog = () => setIsIdealCandidateDialogOpen(false);

    const handleFilterChange = (_, newValue) => setStatusFilterValue(newValue);

    const handleCloseInviteDialog = () => setIsInviteDialogOpen(false);

    const applyInterviewStatusFilter = (interviews) => {
        return interviews.filter(interview => {
            const interviewStatus = getInterviewStatus(interview);
            return interviewStatus === statusFilterValue;
        });
    };

    const handleOpenJobDialog = () => setIsJobDialogOpen(true);
    const handleCloseJobDialog = () => setIsJobDialogOpen(false);

    const handleSaveIdealCandidate = (values) => {
        setIdealCandidateFormValues(values);
        handleCloseIdealDialog();
        handleOpenJobDialog();
    };

    const handlePreviousButtonClick = () => {
        handleCloseJobDialog();
        handleOpenIdealDialog();
    };

    const handleJobDialogFormSubmit = async (values) => {
        const jobSearchData = { ...values, ...idealCandidateFormValues, clientId, agencyId };

        const formData = new FormData();

        if (values.jobImgUrl) {
            formData.append('jobImgUrl', values.jobImgUrl);
        } else {
            formData.append('jobImgUrl', null);
        }

        formData.append('jobSearchData', JSON.stringify({ ...jobSearchData }));

        const jobSearchId = await createJobSearch(agencyId, clientId, formData);

        handleCloseJobDialog();

        history.push(`/${getStateAgencyName()}/talent-searches?jobSearchId=${jobSearchId}`)
    };

    const handleOpenInviteDialogWithInterview = (candidateId, interviewData) => {
        setInterviewData(interviewData);
        setSelectedCandidateId(candidateId);
        setIsInviteDialogOpen(true);
    };

    const handleInviteToInterview = (values) => {
        const { _id, clientNote, interviewTimeSlots, jobSearchInfo, candidateInfo, ...restData } = values;
        const interviewer = userId;

        const additionalData = {
            dateAdded: moment.utc().format(),
            status: INTERVIEW_INVITATION_STATUSES.PENDING,
            proposedBy: INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER,
            interviewer: interviewer,
            participant: selectedCandidateId,
            jobSearchId: interviewData.jobSearchId,
            interviewTimeSlots: interviewTimeSlots.map(slot => {
                const date = moment(slot.date).toISOString().split("T")[0];
                const time = moment(slot.time).toISOString().split("T")[1];

                return {
                    date: `${date}T${time}`,
                    time: `${date}T${time}`
                }
            })
        };

        rescheduleInterview(agencyId, interviewData._id, { ...restData, ...additionalData, note: clientNote }).then(() => {
            setAreInterviewsLoading(true);
            fetchJobSearchesInterviews(agencyId, clientId).then(() => setAreInterviewsLoading(false));
        });
    };

    useEffect(() => {
        setAreInterviewsLoading(true);
        fetchJobSearchesInterviews(agencyId, clientId).then(() => setAreInterviewsLoading(false));
    }, []);

    return (
        <div className="interviews-wrapper">
            <div className="interviews-searches-header">
                <div className="interviews-title-section">
                    <p className="heading-s color-dark-grey">Interviews</p>
                    <p className="subheading-l color-grey">{clientName}</p>
                </div>
                <div className="interviews-actions">
                    <SelectFilterList
                        name="status"
                        value={statusFilterValue}
                        title={statusFilterValue}
                        items={Object.values(INTERVIEW_STATUSES)}
                        handleChange={handleFilterChange}
                        className="client-view"
                        closeMenuOnSelect
                    />
                    <RoundedButton icon={<PlusIcon />} handleClick={handleOpenIdealDialog} />
                </div>
            </div>
            <hr className="custom-divider" />
            {areInterviewsLoading ? <CircularProgress size={50} className="page-loader deep-orange" /> :
                <div className="interviews-container">
                    {
                        applyInterviewStatusFilter(interviews).map(interview => (
                            <InterviewDetails
                                interview={interview}
                                position={interview.jobSearchInfo.position}
                                matchingCandidates={interview.jobSearchInfo.matchingCandidates}
                                jobSearchId={interview.jobSearchInfo._id}
                                openInviteDialogHandler={handleOpenInviteDialogWithInterview}
                            />
                        ))
                    }
                </div>
            }

            <InterviewInvitePopup
                displayMode={ROLES.CLIENT}
                dialogTitle="Invitation for interview"
                isWithNote={true}
                isDialogOpen={isInviteDialogOpen}
                isLocationDisabled={false}
                interviewInvitation={interviewData}
                handleCloseDialog={handleCloseInviteDialog}
                handelInterviewInvitationSubmit={handleInviteToInterview}
            />

            <IdealCandidateFormik
                handleSubmit={handleSaveIdealCandidate}
                isIdealCandidateDialogOpen={isIdealCandidateDialogOpen}
                handleCloseIdealDialog={handleCloseIdealDialog}
                initialValues={idealCandidateFormValues}
            />

            <JobDialogFormik
                handleSubmit={handleJobDialogFormSubmit}
                isJobDialogOpen={isJobDialogOpen}
                handleCloseJobDialog={handleCloseJobDialog}
                handlePreviousButtonClick={handlePreviousButtonClick}
            />
        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    clientId: getClientId(state),
    userId: getUserId(state),
    clientName: getClientMenuInformation(state).name,
    interviews: getJobSearchesInterviews(state),
});

const mapDispatchToProps = {
    fetchJobSearchesInterviews,
    rescheduleInterview,
    createJobSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(Interviews);
