import React from 'react';

import './JobSearchEmptySection.scss';

const JobSearchEmptySection = ({
    icon,
    title,
    description
}) => (
    <div className="job-search-empty-section-wrapper">
        {icon}
        <p className="subheading-l color-dark-grey">{title}</p>
        <p className="subheading-xs color-grey">{description}</p>
    </div>
);

export default JobSearchEmptySection;
