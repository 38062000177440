import React from 'react';
import moment from 'moment';

import { Avatar } from '@material-ui/core';

import defaultAvatar from 'assets/default-avatar.svg';

import { ReactComponent as SalaryIcon } from 'assets/icons-salary.svg';
import { ReactComponent as ExperienceIcon } from 'assets/icons-experiance.svg';

import './CvProfileInformation.scss';

const CvProfileInformation = ({
    profileInformation,
    iconClassName,
    matchingScore,
    blurCVInformation,
}) => {
    const experienceInYears = profileInformation.experienceSince ? moment().diff(moment(profileInformation.experienceSince), 'years') : 'N/A';
    const availability = profileInformation.availability ? profileInformation.availability : 'N/A';

    return (
        <div className="cv-profile-information-wrapper">
            <div className="cv-profile-information">
                <Avatar className="profile-avatar" src={profileInformation.profilePictureImgUrl || defaultAvatar} />
                <div className="intro-container">
                    <h1 className="heading-m color-dark-grey">{profileInformation.firstName} <span>{profileInformation.lastName.charAt(0).toUpperCase()}</span>.</h1>
                    <h3 className="heading-s color-grey">{profileInformation.position}</h3>
                    <div className="intro-details-container">
                        <p className="subheading-xxl color-dark-grey"><SalaryIcon className={iconClassName} /> Availability: {availability}</p>
                        <p className="subheading-xxl color-dark-grey"><ExperienceIcon className={iconClassName} /> Experience: {experienceInYears} {experienceInYears === 1 ? 'year' : 'years'}</p>
                    </div>
                </div>
            </div>
            {matchingScore &&
                <div className="matching-score-container">
                    <p className="heading-l color-dark-grey">{matchingScore}%</p>
                    <p className="heading-xs color-grey">Matching Score</p>
                </div>
            }
        </div>
    )
}

export default CvProfileInformation;
