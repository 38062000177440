import preferredStackGif from 'assets/gifs/preferred_stack.gif';
import programmingLanguagesGif from 'assets/gifs/programming_languages.gif';
import frameworksAndLibrariesGif from 'assets/gifs/frameworks_and_libraries.gif';
import databasesGif from 'assets/gifs/databases.gif';
import cloudsGif from 'assets/gifs/clouds.gif';
import toolsGif from 'assets/gifs/tools.gif';

export const TOOLTIP_TEXT = "Please complete your technical self-assessment to move forward in the hiring process. Be as honest as possible, as the skills and knowledge listed will be further evaluated during interviews.";

export const PREFERRED_STACK_KEY = "preferredStack";

export const SELF_ASSESSMENT_MENU_ITEMS = [
    { title: "Preferred Stack", key: PREFERRED_STACK_KEY, gifSource: preferredStackGif },
    { title: "Programming Languages", key: "programmingLanguages", gifSource: programmingLanguagesGif },
    { title: "Frameworks & Libraries", key: "frameworksAndLibraries", gifSource: frameworksAndLibrariesGif },
    { title: "Databases", key: "databases", gifSource: databasesGif },
    { title: "Clouds", key: "clouds", gifSource: cloudsGif },
    { title: "Tools", key: "tools", gifSource: toolsGif },
];
