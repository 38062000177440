import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Formik, ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

import Switcher from 'components/Shared/Switcher/Switcher';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import './AgencyMatchingBonusSystemSettings.scss';

const AgencyMatchingBonusSystemSettings = ({
    agencyMatchingBonusSystemSettings,
    updateAction,
}) => {
    const intl = useIntl();
    const formRef = useRef();

    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const handleShowHeaderButtons = () => setShowHeaderButtons(true);

    const discardHandler = () => {
        setShowHeaderButtons(false);
        formRef.current.resetForm();
    };

    return (
        <div className="agency-matching-bonus-system-settings-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "ai-matching-bonus-system-settings" })}
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "discard" })}
                            onClick={discardHandler}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "save" })}
                            onClick={() => {
                                formRef.current.handleSubmit();
                                setShowHeaderButtons(false);
                            }}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>
            <Paper className="agency-matching-settings-content">
                <Formik
                    initialValues={{
                        useAiMatchingBonusSystem: agencyMatchingBonusSystemSettings?.useAiMatchingBonusSystem || false,
                        aiMatchingBonusSystemSettings: agencyMatchingBonusSystemSettings?.aiMatchingBonusSystemSettings ? JSON.stringify(agencyMatchingBonusSystemSettings?.aiMatchingBonusSystemSettings) : "",
                    }}
                    innerRef={formRef}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);

                        updateAction(values, resetForm);
                    }}
                    enableReinitialize={true}
                >
                    {({ handleChange, setFieldValue, values, errors, touched }) =>
                        <form autoComplete="off">
                            <div className="agency-use-bonus-system-checkbox-wrapper">
                                <Switcher
                                    initialValue={values.useAiMatchingBonusSystem}
                                    isChecked={values.useAiMatchingBonusSystem}
                                    changeStatusHandler={() => {
                                        setFieldValue('useAiMatchingBonusSystem', !values.useAiMatchingBonusSystem);
                                        handleShowHeaderButtons();
                                    }}
                                />
                                <span className="checkbox-text">{intl.formatMessage({ id: "agency-uses-matching-bonus-system" })}</span>
                            </div>
                            {values.useAiMatchingBonusSystem &&
                                <div className="agency-settings-form-block">
                                    <div className="agency-settings-form-field">
                                        <TextField
                                            required
                                            label="Matching Bonus System Settings"
                                            variant="outlined"
                                            name="aiMatchingBonusSystemSettings"
                                            fullWidth
                                            multiline
                                            minRows={10}
                                            value={values.aiMatchingBonusSystemSettings}
                                            onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                            error={errors.aiMatchingBonusSystemSettings && touched.aiMatchingBonusSystemSettings}
                                        />
                                        <ErrorMessage name="aiMatchingBonusSystemSettings" component="div" className="invalid-field-message" />
                                    </div>
                                </div>
                            }
                        </form>
                    }
                </Formik>
            </Paper>
        </div>
    )
};

export default AgencyMatchingBonusSystemSettings;
