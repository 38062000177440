import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { setCandidateSelfAssessment, updateCandidateSkills } from 'actions/candidateActions';

import { getCandidatePreferences, getProfileSkills } from 'reducers';

import { Tooltip } from '@material-ui/core';
import { Rating } from '@material-ui/lab';

import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';

import { getStateAgencyName } from 'utils/helpers';

import { SELF_ASSESSMENT_MENU_ITEMS, TOOLTIP_TEXT, PREFERRED_STACK_KEY } from './TechnicalSelfAssessmentConstants';

import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';
import { ReactComponent as WarningIcon } from 'assets/info-triangle.svg';
import { ReactComponent as FilledStar } from 'assets/icons-star-filled.svg';
import { ReactComponent as EmptyStar } from 'assets/icons-star.svg';

import './TechnicalSelfAssessment.scss';

const TechnicalSelfAssessment = ({
    agencyId,
    userId,
    preferredStack,
    profileSkills,
    updateCandidateSkills,
    setCandidateSelfAssessment,
}) => {
    const history = useHistory();

    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [items, setItems] = useState([...profileSkills, ...preferredStack.map(x => ({ ...x, name: x.title, category: PREFERRED_STACK_KEY }))]);
    const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    const [isPreviousButtonVisible, setIsPreviousButtonVisible] = useState(false);
    const [nextButtonText, setNextButtonText] = useState("Next");

    const handleBackButtonClick = () => history.push(`/${getStateAgencyName()}/profile`);

    const getSkillsForCurrentStep = () => items.filter(x => x.category === SELF_ASSESSMENT_MENU_ITEMS[currentStepIndex].key);

    const handleRatingChange = (value, skillId) => {
        const index = items.findIndex(x => x._id === skillId && x.category === SELF_ASSESSMENT_MENU_ITEMS[currentStepIndex].key);

        if (index !== -1) {
            const updatedItems = [...items];

            updatedItems[index] = {
                ...updatedItems[index],
                rating: value
            };

            if (updatedItems.filter(x => x.category === SELF_ASSESSMENT_MENU_ITEMS[currentStepIndex].key).every(x => x.rating > 0)) {
                setIsNextButtonVisible(true);
            }

            setItems(updatedItems);
        }
    };

    const handleNextButtonClick = () => {
        if (currentStepIndex === SELF_ASSESSMENT_MENU_ITEMS.length - 1) {
            updateCandidateSkills(agencyId, userId, items).then(() => setCandidateSelfAssessment(agencyId, userId, true));
            history.push(`/${getStateAgencyName()}/profile`);
        }

        if (currentStepIndex + 1 === SELF_ASSESSMENT_MENU_ITEMS.length - 1) {
            setNextButtonText("Done");
        }

        if (items.filter(x => x.category === SELF_ASSESSMENT_MENU_ITEMS[currentStepIndex + 1].key).some(x => !x.rating || x.rating === 0)) {
            setIsNextButtonVisible(false);
        }

        setCurrentStepIndex((prev) => prev + 1);
        setIsPreviousButtonVisible(true);
    };

    const handlePreviousButtonClick = () => {
        if (currentStepIndex - 1 === 0) {
            setIsPreviousButtonVisible(false);
        }

        setCurrentStepIndex((prev) => prev - 1);
        setIsNextButtonVisible(true);
        setNextButtonText("Next");
    };

    return (
        <div className="technical-self-assessment-wrapper">
            <PreviousButton text="Back" handleClick={handleBackButtonClick} />
            <PaperElement classes={["technical-self-assessment-paper"]}>
                <div className="technical-self-assessment-title-wrapper">
                    <p className="heading-s color-dark-grey">Technical self-assessment</p>
                    <Tooltip placement="top-start" classes={{ tooltip: 'custom-tooltip' }} title={TOOLTIP_TEXT}>
                        <InfoIcon />
                    </Tooltip>
                </div>
                <div className="technical-self-assessment-warning">
                    <WarningIcon />
                    <p className="subheading-l color-dark-grey">Complete the self-assessment to your best knowledge, the rating will be checked on the live interview.</p>
                </div>
                <div className="technical-self-assessment-content-wrapper">
                    <div className="technical-self-assessment-menu">
                        <div className="steps-wrapper">
                            <div className="progress-wrapper">
                                <div className={`filled-progress ${SELF_ASSESSMENT_MENU_ITEMS[currentStepIndex].key}`}></div>
                            </div>
                            <div className="menu-items-wrapper">
                                <p className="heading-m color-deep-purple">0{currentStepIndex + 1}</p>
                                {SELF_ASSESSMENT_MENU_ITEMS.map((item, index) => {
                                    const stepTitle = item.title;

                                    const isLast = index === SELF_ASSESSMENT_MENU_ITEMS.length - 1;
                                    const isCurrent = SELF_ASSESSMENT_MENU_ITEMS[currentStepIndex].title === stepTitle;

                                    return <p className={`step-item subheading-l ${isCurrent ? 'color-dark-grey' : 'color-pale-gray'} ${isLast ? 'last-step' : ''}`}>{stepTitle}</p>
                                })}
                                {currentStepIndex < SELF_ASSESSMENT_MENU_ITEMS.length - 1 && <p className="heading-m color-pale-gray">0{currentStepIndex + 2}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="technical-self-assessment-skills-wrapper">
                        {getSkillsForCurrentStep().length ?
                            getSkillsForCurrentStep().map((skill, index) => (
                                <div className="technical-self-assessment-skill-wrapper">
                                    <div className="technical-self-assessment-skill-container">
                                        <div className="skill-box subheading-m color-dark-grey">{skill.name}</div>
                                        <Rating
                                            name={skill._id}
                                            id={`${skill._id}_${index}`}
                                            value={skill.rating || 0}
                                            className="rating-stars-wrapper"
                                            icon={<FilledStar />}
                                            emptyIcon={<EmptyStar />}
                                            onChange={(e) => handleRatingChange(e.target.value, skill._id)}
                                        />
                                    </div>
                                    <hr className="custom-divider" />
                                </div>
                            )) : "Nothing to show!"
                        }
                    </div>
                </div>
                <div className="fading-effect"></div>
                <div className="technical-self-assessment-actions-wrapper">
                    {isPreviousButtonVisible && <SecondaryButton className="white" text="Previous" handleClick={handlePreviousButtonClick} />}
                    {isNextButtonVisible && <PrimaryButton className="dark" text={nextButtonText} handleClick={handleNextButtonClick} />}
                </div>
            </PaperElement>
        </div>
    )
};

const mapStateToProps = (state) => ({
    profileSkills: getProfileSkills(state),
    preferredStack: getCandidatePreferences(state).technologies,
});

const mapDispatchToProps = {
    updateCandidateSkills,
    setCandidateSelfAssessment,
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalSelfAssessment);
