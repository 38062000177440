import React from 'react';
import { useIntl } from 'react-intl';

import TextField from '@material-ui/core/TextField';
import { ErrorMessage } from 'formik';
import '../PersonalInfoBasicForm.scss';

const PersonalInfoBasicFormView = ({
    values,
    handleChange,
    handleShowHeaderButtons,
    errors,
    touched,
    isAdminMode,
    isSupplierAdminMode,
    isDepartmentView,
}) => {
    const intl = useIntl();
    const isEmailFieldActive = (isAdminMode || isSupplierAdminMode || isDepartmentView);

    return (<form className="grid-form-double-row" autoComplete="off">
        <div className="form-field">
            <TextField
                required
                id="personal-info-first-name"
                label={intl.formatMessage({ id: "first-name" })}
                variant="outlined"
                name="firstName"
                value={values.firstName}
                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                error={errors.firstName && touched.firstName}
                fullWidth
            />
            <ErrorMessage name="firstName" component="div" className="invalid-field-message" />
        </div>
        <div className="form-field">
            <TextField
                required
                id="personal-info-last-name"
                label={intl.formatMessage({ id: "last-name" })}
                variant="outlined"
                name="lastName"
                value={values.lastName}
                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                error={errors.lastName && touched.lastName}
                fullWidth
            />
            <ErrorMessage name="lastName" component="div" className="invalid-field-message" />
        </div>
        <div className="form-field">
            <TextField
                required
                id="personal-info-first-name"
                label={intl.formatMessage({ id: "first-name-bg" })}
                variant="outlined"
                name="firstNameBg"
                value={values.firstNameBg}
                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                error={errors.firstNameBg && touched.firstNameBg}
                fullWidth
            />
            <ErrorMessage name="firstNameBg" component="div" className="invalid-field-message" />
        </div>
        <div className="form-field">
            <TextField
                required
                id="personal-info-last-name"
                label={intl.formatMessage({ id: "last-name-bg" })}
                variant="outlined"
                name="lastNameBg"
                value={values.lastNameBg}
                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                error={errors.lastNameBg && touched.lastNameBg}
                fullWidth
            />
            <ErrorMessage name="lastNameBg" component="div" className="invalid-field-message" />
        </div>
        <div className="form-field last">
            <TextField
                required={false}
                id="personal-info-email"
                label={intl.formatMessage({ id: "email" })}
                variant="outlined"
                name="email"
                value={values.email}
                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                disabled={!isEmailFieldActive}
                error={errors.email && touched.email}
                fullWidth
            />
            <ErrorMessage name="email" component="div" className="invalid-field-message" />
        </div>
    </form>);
};

export default PersonalInfoBasicFormView;
