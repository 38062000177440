import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { validateRequiredFields, validateInputLength, validatesArrayLength } from 'utils/formValidations';

import { getClients, getAgencyName, isAdmin, getCountries } from 'reducers';
import { fetchSkillSuggestions } from 'actions/employeeActions';
import { fetchAllCountries } from 'actions/staticDataActions';
import { fetchClients } from 'actions/clientActions';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import ImageEditDialog from 'components/Shared/ImageEditDialog';
import JobFormDialogView from 'components/Shared/JobFormDialog/JobFormDialogView';

import { FIELD_LENGTH_VALIDATION, REQUIRED_FIELDS_VALIDATION, DEFAULT_JOB_LOCATIONS } from 'constants/jobOpportunitiesConstants';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const JobFormDialog = ({
    title,
    open,
    onClose,
    clients,
    clientId,
    onSubmit,
    selectedItem,
    fetchSkillSuggestions,
    agencyId,
    agencyName,
    jobType,
    fetchClients,
    isClientRequested,
    isAdmin,
    setJobImgData,
    countries,
    fetchAllCountries,
}) => {
    const intl = useIntl();
    const jobImgRef = useRef();

    const [currentImgSelected, setCurrentImgSelected] = useState('');
    const [isEditImgDialogOpen, setIsEditImgDialogOpen] = useState(false);
    const [selectableCountries, setSelectableCountries] = useState(Array.from(new Set([...DEFAULT_JOB_LOCATIONS, ...(selectedItem.geographicLocations || [])])));

    useEffect(() => {
        if (isAdmin) {
            fetchClients(agencyId);
        }
        fetchAllCountries();
    }, []);

    const handleClose = () => {
        setSelectableCountries(Array.from(new Set([...DEFAULT_JOB_LOCATIONS, ...(selectedItem.geographicLocations || [])])))
        onClose();
    }

    const fetchSkillSuggestionsHandler = inputText => fetchSkillSuggestions(agencyId, inputText);

    const addCountryHandler = (e, geographicLocations, setFieldValue) => {
        const currentCountry = e.target.value;
        setFieldValue('geographicLocations', Array.from(new Set([...geographicLocations, currentCountry])))
        setSelectableCountries(oldValue => Array.from(new Set([...oldValue, currentCountry])))
    }

    const imageSelectedHandler = (e) => {
        const currentFile = e.target.files[0]
        const imgReader = new FileReader();

        imgReader.addEventListener('load', () => {
            const resultImg = imgReader.result;
            setCurrentImgSelected(resultImg);
        }, false);

        imgReader.readAsDataURL(currentFile);
        setIsEditImgDialogOpen(true);
    };

    const handleCloseEditImgDialog = () => {
        setIsEditImgDialogOpen(false);
        setCurrentImgSelected('');
        jobImgRef.current.value = '';
    }

    const handleUpdateJobImage = (setFieldValue, imgData) => {
        const croppedImg = imgData.get('croppedImg');
        const croppedImgInfo = JSON.parse(imgData.get('croppedImgInfo'));
        const imgReader = new FileReader();

        imgReader.addEventListener('load', () => {
            setFieldValue('jobImgUrl', imgReader.result);
            setJobImgData({ info: croppedImgInfo, file: croppedImg });
        }, false);

        imgReader.readAsDataURL(croppedImg);
    };

    const handleChangeCheckbox = (e, state, geographicLocations, setFieldValue) => {
        const currentCountry = e.target.name;
        if (state) {
            setFieldValue('geographicLocations', Array.from(new Set([...geographicLocations, currentCountry])))
        } else {
            geographicLocations = geographicLocations.filter(location => location !== currentCountry)
            setFieldValue('geographicLocations', geographicLocations)
        }
    }

    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        jobType: jobType || selectedItem.jobType || '',
                        aboutCandidate: selectedItem.aboutCandidate || '',
                        position: selectedItem.position || '',
                        aboutTheClient: selectedItem.aboutTheClient || '',
                        jobSummary: selectedItem.jobSummary || '',
                        requirements: selectedItem.requirements || '',
                        benefits: selectedItem.benefits || '',
                        niceToHaveSkills: selectedItem.niceToHaveSkills || [],
                        mandatorySkills: selectedItem.mandatorySkills || [],
                        geographicLocations: selectedItem.geographicLocations || [],
                        clientId: clientId || '',
                        jobImgUrl: selectedItem.jobOpportunityImgUrl || '',
                        profileForwardType: typeof selectedItem.profileForwardType === 'number' ? selectedItem.profileForwardType : '',
                    }}
                    validate={values => {
                        const requiredFields = [...REQUIRED_FIELDS_VALIDATION];

                        if (!isAdmin) {
                            requiredFields.splice(requiredFields.indexOf('profileForwardType'), 1);
                        }

                        return {
                            ...validateRequiredFields(values, requiredFields),
                            ...validateInputLength(values, FIELD_LENGTH_VALIDATION),
                            ...validatesArrayLength(values, 'geographicLocations', 1)
                        }
                    }}
                    onSubmit={(values) => {
                        onSubmit(values);
                    }}
                >
                    {(props) => (
                        <>
                            <CommonFormikDialog
                                className="job-form-dialog"
                                dialogTitle={intl.formatMessage({ id: convertStringToLanguageKey(title) }) || intl.formatMessage({ id: "create-job" })}
                                saveBtnText="save"
                                discardBtnText="discard"
                                size="md"
                                open={open}
                                onClose={handleClose}
                                onSave={props.handleSubmit}
                            >
                                <JobFormDialogView
                                    selectedItem={selectedItem}
                                    jobType={jobType}
                                    clients={[{ _id: agencyId, name: agencyName }, ...clients]}
                                    isClientRequested={isClientRequested}
                                    fetchSkillSuggestions={fetchSkillSuggestionsHandler}
                                    imageSelectedHandler={imageSelectedHandler}
                                    addCountryHandler={addCountryHandler}
                                    jobImgRef={jobImgRef}
                                    isAdmin={isAdmin}
                                    countries={countries}
                                    selectableCountries={selectableCountries}
                                    handleChangeCheckbox={handleChangeCheckbox}
                                    {...props}
                                />
                            </CommonFormikDialog>

                            <ImageEditDialog
                                classes={['job-image']}
                                dialogTitle={intl.formatMessage({ id: "edit-image" })}
                                isDialogOpen={isEditImgDialogOpen}
                                handleCloseDialog={handleCloseEditImgDialog}
                                currentImgSelected={currentImgSelected}
                                handleUpdateAvatar={handleUpdateJobImage.bind(null, props.setFieldValue)}
                            />
                        </>
                    )}
                </Formik>
            </MuiPickersUtilsProvider>
        </>
    )
};

const mapStateToProps = state => ({
    clients: getClients(state),
    agencyName: getAgencyName(state),
    isAdmin: isAdmin(state),
    countries: getCountries(state),
});

const mapDispatchToProps = {
    fetchSkillSuggestions,
    fetchClients,
    fetchAllCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobFormDialog);
