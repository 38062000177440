import React from 'react';
import { Formik } from 'formik';

import ClientCustomerSupportRequestFormView from './ClientCustomerSupportRequestFormView';

import { validateRequiredFields, validateInputLength } from 'utils/formValidations';

import { DESCRIPTION_MAX_LENGTH } from '../../../CustomerSupportConstants';

const ClientCustomerSupportRequestForm = ({
    requestCustomerSupport,
    accountManager,
}) => {
    return (
        <Formik
            initialValues={{
                name: '',
                description: ''
            }}

            validate={values => {
                return {
                    ...validateRequiredFields(values, ['name', 'description']),
                    ...validateInputLength(values, [
                        { property: 'description', maxLength: DESCRIPTION_MAX_LENGTH },
                    ]),
                }
            }}

            onSubmit={(values, { resetForm, setSubmitting }) => {
                setSubmitting(false);
                requestCustomerSupport({ ...values, accountManager });
                resetForm();
            }}
        >
            {(props) => (
                <div className="client-customer-support-request-container">
                    <div className="client-customer-support-form-content">
                        <p className="content-title heading-xs color-dark-grey">How can we help?</p>
                        <p className="content-helper-text subheading-m color-grey">
                            If you're experiencing problems that you need help with, please complete the form below and our customer support team will get back to you within 48 hours.
                        </p>
                        <ClientCustomerSupportRequestFormView {...props} />
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default ClientCustomerSupportRequestForm;
