import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Confetti from 'react-confetti';

import { fetchCandidateApplicationPage } from 'actions/candidateActions';
import { resetCandidateApplicationStates } from 'actions/sectionsLoadersActions';

import {
    getAgencyId,
    getDisplayMode,
    getInterviewInvitationDetails,
    getJobOpportunityDetails,
    getUserId,
    getCandidateApplicationDataFetchState,
} from 'reducers';

import { CircularProgress } from '@material-ui/core';

import ApplicationStatusBanner from 'components/Shared/ApplicationStatusBanner';
import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import JobCard from 'components/Shared/JobCard';
import Section from 'components/Shared/Section';

import ApplicationInterviewSlots from 'components/MyApplications/ApplicationDetails/Application/ApplicationInterviewSlots';
import MyUpcomingInterviewCard from 'components/MyApplications/MyUpcomingInterviewsList/MyUpcomingInterviewCard';
import AskRecruiterBanner from 'components/Shared/AskRecruiterBanner';

import { getStateAgencyName, useWindowDimensions } from 'utils/helpers';
import { areAllDatesInThePast } from 'utils/dateUtils';

import { INTERVIEW_INVITATION_CONTRIBUTORS, INTERVIEW_INVITATION_STATUSES } from 'constants/interviewInvitationConstants';

import './MyApplicationPage.scss';

const MyApplicationPage = ({
    agencyId,
    userId,
    history,
    match,
    displayMode,
    interviewInvitationDetails,
    jobDetails,
    fetchCandidateApplicationPage,
    resetCandidateApplicationStates,
    applicationDataFetchState,
}) => {
    const applicationId = match.params.id;

    const [isConfettiActive, setIsConfettiActive] = useState(false);

    const { width, height } = useWindowDimensions();

    useEffect(() => {
        fetchCandidateApplicationPage(agencyId, userId, applicationId);

        return () => resetCandidateApplicationStates();
    }, []);

    const handleGoBackClick = () => {
        history.push(`/${getStateAgencyName()}/my-applications`);
    };

    const isInterviewPending = interviewInvitationDetails.status === INTERVIEW_INVITATION_STATUSES.PENDING;
    const isInterviewConfirmed = interviewInvitationDetails?.interviewDate?.date;

    // TODO: Do this later
    const isApplicationCompleted = false;
    const isInterviewProposedByCandidate = interviewInvitationDetails?.proposedBy === INTERVIEW_INVITATION_CONTRIBUTORS.PARTICIPANT;
    const showApplicationStatusBanner = !isApplicationCompleted;
    const isInterviewUpcoming =
        isInterviewConfirmed ? moment(interviewInvitationDetails?.interviewDate?.time).isAfter(moment()) :
            Array.isArray(interviewInvitationDetails?.interviewTimeSlots) ? !areAllDatesInThePast(interviewInvitationDetails?.interviewTimeSlots.map(x => x.time)) :
                false
        ;

    if (!applicationDataFetchState?.isDataFetched) {
        return <CircularProgress thickness={5} size={50} className="page-loader" />
    }

    return (
        <div className="my-application-page-wrapper">
            {isConfettiActive &&
                <Confetti
                    width={width}
                    height={height}
                    numberOfPieces={500}
                    gravity={0.5}
                    recycle={false}
                    onConfettiComplete={confetti => {
                        setIsConfettiActive(false);
                        confetti.reset();
                    }}
                />
            }
            <PreviousButton text={"Back"} handleClick={handleGoBackClick} />
            <div className="my-application-page-container">
                {showApplicationStatusBanner &&
                    <ApplicationStatusBanner
                        status={interviewInvitationDetails.status}
                        isInterviewPending={isInterviewPending}
                        isInterviewConfirmed={isInterviewConfirmed}
                    />
                }
                {interviewInvitationDetails && interviewInvitationDetails._id && !isApplicationCompleted && isInterviewUpcoming &&
                    <Section title="">
                        {(isInterviewPending) ?
                            <ApplicationInterviewSlots
                                agencyId={agencyId}
                                userId={userId}
                                candidateId={userId}
                                clientId={interviewInvitationDetails.interviewer}
                                applicationId={applicationId}
                                displayMode={displayMode}
                                interviewInvitation={interviewInvitationDetails}
                                isWaitingForResponse={isInterviewProposedByCandidate}
                                setIsConfettiActive={setIsConfettiActive}
                                subtitle={
                                    isInterviewProposedByCandidate
                                        ? "Here you have submitted a proposal for interview days and times. Please wait for a response from the client."
                                        : "Here the client has proposed days and hours for the interview. Please choose below which is convenient for you."
                                }
                            /> :
                            <MyUpcomingInterviewCard
                                interview={{ ...interviewInvitationDetails, position: jobDetails.position, company: jobDetails.company }}
                            />
                        }
                    </Section>
                }
                <Section title="">
                    <JobCard
                        interview={interviewInvitationDetails}
                        jobDetails={jobDetails}
                    />
                </Section>
                <AskRecruiterBanner applicationId={interviewInvitationDetails._id} />
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    userId: getUserId(state),
    interviewInvitationDetails: getInterviewInvitationDetails(state),
    jobDetails: getJobOpportunityDetails(state),
    displayMode: getDisplayMode(state),
    applicationDataFetchState: getCandidateApplicationDataFetchState(state),
});

const mapDispatchToProps = {
    fetchCandidateApplicationPage,
    resetCandidateApplicationStates,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyApplicationPage);
