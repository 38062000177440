import React, { useRef, useState } from 'react';
import { ErrorMessage } from 'formik';
import { EditorState } from 'draft-js';

import ImageEditDialog from 'components/Shared/ImageEditDialog';
import RichTextEditor from 'components/Common/RichTextEditor';

import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { MenuItem, TextField } from '@material-ui/core';

import { DEFAULT_JOB_LOCATIONS, JOB_OPPORTUNITIES_TYPES_ARRAY } from 'constants/jobOpportunitiesConstants';

import { ReactComponent as CloseIcon } from 'assets/x-icon-purple.svg';
import { ReactComponent as EditIconBordered } from 'assets/icons-edit-bordered.svg';
import { ReactComponent as UncheckedIcon } from 'assets/codery-checkbox-unchecked.svg';
import { ReactComponent as CheckedIcon } from 'assets/codery-checkbox-checked.svg';

import defaultAvatar from 'assets/default-avatar.svg';

import './JobDialogFormView.scss';

const JobDialogFormView = ({
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    countries,
}) => {
    const jobImgRef = useRef();

    const [currentImgSelected, setCurrentImgSelected] = useState(values.jobImgUrl);
    const [isEditImgDialogOpen, setIsEditImgDialogOpen] = useState(false);
    const [selectableCountries, setSelectableCountries] = useState(Array.from(new Set([...DEFAULT_JOB_LOCATIONS])));
    const [aboutCompanyState, setAboutCompanyState] = useState(() => EditorState.createEmpty());
    const [aboutJobState, setAboutJobState] = useState(() => EditorState.createEmpty());

    const handleCloseEditImgDialog = () => setIsEditImgDialogOpen(false);

    const imageSelectedHandler = (e) => {
        const currentFile = e.target.files[0]
        const imgReader = new FileReader()

        imgReader.addEventListener("load", () => {
            const resultImg = imgReader.result;
            setCurrentImgSelected(resultImg);
        }, false)

        imgReader.readAsDataURL(currentFile)

        setIsEditImgDialogOpen(true);
    };

    const handleUpdateJobImage = (setFieldValue, imgData) => {
        const croppedImg = imgData.get('croppedImg');
        const imgReader = new FileReader();

        imgReader.addEventListener('load', () => {
            setFieldValue('jobImgUrl', croppedImg);
            setCurrentImgSelected(imgReader.result);
        }, false);

        imgReader.readAsDataURL(croppedImg);
    };

    const addCountryHandler = (e, geographicLocations, setFieldValue) => {
        const currentCountry = e.target.value;
        setFieldValue('countries', Array.from(new Set([...geographicLocations, currentCountry])))
        setSelectableCountries(oldValue => Array.from(new Set([...oldValue, currentCountry])))
    };

    const handleChangeCheckbox = (e, state, geographicLocations, setFieldValue) => {
        const currentCountry = e.target.name;
        if (state) {
            setFieldValue('countries', Array.from(new Set([...geographicLocations, currentCountry])))
        } else {
            geographicLocations = geographicLocations.filter(location => location !== currentCountry)
            setFieldValue('countries', geographicLocations)
        }
    };

    return (
        <form className="job-dialog-form" autoComplete="off">
            <div className="form-row">
                <div className="job-image-wrapper">
                    <Avatar className="job-image" src={currentImgSelected || defaultAvatar} />
                    <div className="job-image-actions">
                        <IconButton onClick={() => {
                            setCurrentImgSelected(null)
                            setFieldValue('jobImgUrl', null)
                        }}>
                            <Icon>
                                <CloseIcon className="delete-icon" />
                            </Icon>
                        </IconButton>

                        <input
                            hidden
                            accept="image/*"
                            className="upload-input"
                            id="upload-file-input"
                            type="file"
                            onChange={imageSelectedHandler}
                            ref={jobImgRef}
                        />

                        <IconButton className="edit-icon" onClick={() => { jobImgRef.current.click(); }}>
                            <Icon>
                                <EditIconBordered />
                            </Icon>
                        </IconButton>
                    </div>

                </div>

                <p className="assistive-text">
                    You can upload a custom logo or image for this job, which will be displayed to job candidates and any external services such as the public website. If you leave it blank, the system will show the company logo.
                </p>
            </div>
            <div className="form-row">
                <TextField
                    select
                    name="jobType"
                    value={values.jobType}
                    onChange={handleChange}
                    label={"Job Type"}
                    error={errors.jobType && touched.jobType}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                >
                    {JOB_OPPORTUNITIES_TYPES_ARRAY.map((jobType, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={jobType}
                        >
                            {jobType}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="jobType" component="div" className="invalid-field-message" />
            </div>
            <div className="form-row">
                <TextField
                    className="job-form-field"
                    name="position"
                    value={values.position}
                    onChange={handleChange}
                    label={"Position"}
                    error={errors.position && touched.position}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                />
                <ErrorMessage name="position" component="div" className="invalid-field-message" />
            </div>
            <div className="location-section">
                <div className="other-countries-field">
                    <TextField
                        select
                        name="otherCountry"
                        value={''}
                        onChange={(e) => addCountryHandler(e, values.countries, setFieldValue)}
                        label={"Other Countries"}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    >
                        {countries.filter(country => !selectableCountries.includes(country.name)).map((country, i) => (
                            <MenuItem
                                native="true"
                                key={i}
                                value={country.name}
                            >
                                {country.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

                <div className="countries-container">
                    {selectableCountries.map((country, i) => (
                        <div key={country} className={country.length > 15 ? "country-wrapper grid-row-span-2" : "country-wrapper"}>
                            <Checkbox
                                icon={<UncheckedIcon />}
                                checkedIcon={<CheckedIcon />}
                                className="country-checkbox"
                                name={country}
                                checked={values.countries.includes(country)}
                                onChange={(e, state) => handleChangeCheckbox(e, state, values.countries, setFieldValue)}
                            />
                            <div className="subheading-m color-grey">{country}</div>
                        </div>
                    ))}
                </div>

                <ErrorMessage name="countries" component="div" className="invalid-field-message" />
            </div>
            <div>
                <RichTextEditor
                    editorState={aboutCompanyState}
                    handleEditorChange={setAboutCompanyState}
                    setFieldValue={setFieldValue}
                    fieldName="aboutTheCompany"  // Name of the field in Formik
                    placeholderText={"About the Company"}
                    withControls
                />
                <ErrorMessage name="aboutTheCompany" component="div" className="invalid-field-message" />
            </div>

            <div>
                <RichTextEditor
                    editorState={aboutJobState}
                    handleEditorChange={setAboutJobState}
                    setFieldValue={setFieldValue}
                    fieldName="aboutTheJob"  // Name of the field in Formik
                    placeholderText={"About the Job"}
                    withControls
                />
                <ErrorMessage name="aboutTheJob" component="div" className="invalid-field-message" />
            </div>
            <ImageEditDialog
                dialogTitle={"Edit Picture"}
                isDialogOpen={isEditImgDialogOpen}
                handleCloseDialog={handleCloseEditImgDialog}
                currentImgSelected={currentImgSelected}
                handleUpdateAvatar={handleUpdateJobImage.bind(null, setFieldValue)}
            />
        </form>
    )
}

export default JobDialogFormView;
