import React, { Fragment } from 'react';

import { ErrorMessage } from 'formik';

import Switcher from 'components/Shared/Switcher/Switcher';
import TextField from '@material-ui/core/TextField';
import { useIntl } from 'react-intl';

const AgencyMatchingSettingsFormik = ({
    values,
    handleChange,
    setFieldValue,
    handleShowHeaderButtons,
    errors,
    touched,
}) => {
    const intl = useIntl();

    return (
        <form autoComplete="off">
            <div className="agency-use-ai-matching-checkbox-wrapper">
                <Switcher
                    initialValue={values.useAiMatching}
                    isChecked={values.useAiMatching}
                    changeStatusHandler={() => {
                        setFieldValue('useAiMatching', !values.useAiMatching);
                        handleShowHeaderButtons();
                    }}
                />
                <span className="checkbox-text">{intl.formatMessage({ id: "agency-uses-ai-matching" })}</span>
            </div>
            {values.useAiMatching &&
                <Fragment>
                    <div className="agency-settings-form-block">
                        <div className="agency-settings-form-field">
                            <TextField
                                required
                                label="Skills"
                                variant="outlined"
                                name="Skills"
                                value={values.Skills}
                                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                error={errors.Skills && touched.Skills}
                                fullWidth
                            />
                            <ErrorMessage name="Skills" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                    <div className="agency-settings-form-block">
                        <div className="agency-settings-form-field">
                            <TextField
                                required
                                label="Job Titles"
                                variant="outlined"
                                name="JobTitles"
                                value={values.JobTitles}
                                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                error={errors.JobTitles && touched.JobTitles}
                                fullWidth
                            />
                            <ErrorMessage name="JobTitles" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                    <div className="agency-settings-form-block">
                        <div className="agency-settings-form-field">
                            <TextField
                                required
                                label="Industries"
                                variant="outlined"
                                name="Industries"
                                value={values.Industries}
                                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                error={errors.Industries && touched.Industries}
                                fullWidth
                            />
                            <ErrorMessage name="Industries" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                    <div className="agency-settings-form-block">
                        <div className="agency-settings-form-field">
                            <TextField
                                required
                                label="Management Level"
                                variant="outlined"
                                name="ManagementLevel"
                                value={values.ManagementLevel}
                                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                error={errors.ManagementLevel && touched.ManagementLevel}
                                fullWidth
                            />
                            <ErrorMessage name="ManagementLevel" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                    <div className="agency-settings-form-block">
                        <div className="agency-settings-form-field">
                            <TextField
                                required
                                label="Education"
                                variant="outlined"
                                name="Education"
                                value={values.Education}
                                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                error={errors.Education && touched.Education}
                                fullWidth
                            />
                            <ErrorMessage name="Education" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                    <div className="agency-settings-form-block">
                        <div className="agency-settings-form-field">
                            <TextField
                                required
                                label="Languages"
                                variant="outlined"
                                name="Languages"
                                value={values.Languages}
                                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                error={errors.Languages && touched.Languages}
                                fullWidth
                            />
                            <ErrorMessage name="Languages" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                    <div className="agency-settings-form-block">
                        <div className="agency-settings-form-field">
                            <TextField
                                required
                                label="Certifications"
                                variant="outlined"
                                name="Certifications"
                                value={values.Certifications}
                                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                error={errors.Certifications && touched.Certifications}
                                fullWidth
                            />
                            <ErrorMessage name="Certifications" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                    <div className="agency-settings-form-block">
                        <div className="agency-settings-form-field">
                            <TextField
                                required
                                label="Executive Type"
                                variant="outlined"
                                name="ExecutiveType"
                                value={values.ExecutiveType}
                                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                error={errors.ExecutiveType && touched.ExecutiveType}
                                fullWidth
                            />
                            <ErrorMessage name="ExecutiveType" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                </Fragment>
            }
        </form>
    )
};

export default AgencyMatchingSettingsFormik;
