import React from 'react';

import { Editor, EditorState, convertFromRaw } from 'draft-js';

import "./RichTextDisplay.scss";

const RichTextDisplay = ({ rawContent }) => {
    let editorState;

    try {
        // Convert the raw content JSON back to an EditorState
        const contentState = convertFromRaw(JSON.parse(rawContent));
        editorState = EditorState.createWithContent(contentState);
    } catch (error) {
        // If there's an error parsing or if content is invalid, fallback to an empty editor state
        editorState = EditorState.createEmpty();
    }

    return (
        <div className="rich-text-display">
            {/* Render the rich text in read-only mode */}
            <Editor editorState={editorState} readOnly={true} />
        </div>
    );
};

export default RichTextDisplay;
