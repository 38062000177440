import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { getAgencyId, getClientId, getClientMenuInformation, getJobSearches } from 'reducers';

import { createJobSearch, fetchJobSearches } from 'actions/jobSearchActions';

import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import DashboardInfoCard from '../ClientDashboardComponents/DashboardInfoCard';
import DashboardNoItemsCard from '../ClientDashboardComponents/DashboardNoItemsCard';
import DashboardSavedCandidates from '../ClientDashboardComponents/DashboardSavedCandidates';
import IdealCandidateFormik from '../IdealCandidateFormik';
import JobDialogFormik from '../JobDialogFormilk';
import JobSearchCard from '../JobSearchCard';

import { ReactComponent as PlusIcon } from 'assets/plus-icon.svg';
import { ReactComponent as BillingIcon } from 'assets/client-menu-billing.svg';
import { ReactComponent as TeamIcon } from 'assets/client-menu-team.svg';
import { ReactComponent as TalentSearchesIcon } from 'assets/client-menu-talent-searches.svg';
import { ReactComponent as NoSavedTalentsIcon } from 'assets/heart-icon.svg';
import { ReactComponent as NoUpcomingInterviewsIcon } from 'assets/headphones-icon.svg';

import { getStateAgencyName } from 'utils/helpers';

import './ClientDashboard.scss';
import DashboardUpcomingInterviews from '../ClientDashboardComponents/DashboardUpcomingInterviews';

const ClientDashboard = ({
    companyName,
    createJobSearch,
    fetchJobSearches,
    jobSearches,
    clientId,
    agencyId,
}) => {
    const history = useHistory();

    const [isIdealCandidateDialogOpen, setIsIdealCandidateDialogOpen] = useState(false);
    const [idealCandidateFormValues, setIdealCandidateFormValues] = useState(null);

    const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);

    const handleOpenIdealDialog = () => setIsIdealCandidateDialogOpen(true);
    const handleCloseIdealDialog = () => setIsIdealCandidateDialogOpen(false);

    const handleOpenJobDialog = () => setIsJobDialogOpen(true);
    const handleCloseJobDialog = () => setIsJobDialogOpen(false);

    const handleSaveIdealCandidate = (values) => {
        setIdealCandidateFormValues(values);
        handleCloseIdealDialog();
        handleOpenJobDialog();
    };

    const handlePreviousButtonClick = () => {
        handleCloseJobDialog();
        handleOpenIdealDialog();
    };

    const handleJobDialogFormSubmit = async (values) => {
        const jobSearchData = { ...values, ...idealCandidateFormValues, clientId, agencyId };

        const formData = new FormData();

        if (values.jobImgUrl) {
            formData.append('jobImgUrl', values.jobImgUrl);
        } else {
            formData.append('jobImgUrl', null);
        }

        formData.append('jobSearchData', JSON.stringify({ ...jobSearchData }));

        const jobSearchId = await createJobSearch(agencyId, clientId, formData);

        handleCloseJobDialog();

        history.push(`/${getStateAgencyName()}/talent-searches?jobSearchId=${jobSearchId}`)
    };

    const getAllLikedCandidates = (jobSearches) => {
        return jobSearches?.reduce((acc, x) => {
            const likedCandidates = x.likedCandidatesInfo?.map(y => ({ ...y, matchingScore: x.matchingCandidates.find(z => z.candidateId === y._id)?.matchingScore || 0 })) || [];
            acc = [...acc, ...likedCandidates];
            return acc;
        }, [])
    };

    const getAllUpcomingInterviews = (jobSearches) => {
        return jobSearches?.reduce((acc, x) => {
            const interviewInvitations = x.interviewInvitations?.filter(y => y.interviewTimeSlots.some(z => moment(z.time).isSameOrAfter(moment())) || (y.interviewDate?.date && moment(y.interviewDate.time).isSameOrAfter(moment()))) || [];
            acc = [...acc, ...interviewInvitations];
            return acc;
        }, [])
    };

    useEffect(() => {
        fetchJobSearches(agencyId, clientId);
    }, []);

    return (
        <div className="client-dashboard-wrapper">
            <div className="dashboard-header">
                <div className="dashboard-text-items">
                    <p className="heading-s color-dark-grey">Dashboard</p>
                    <p className="subheading-l color-grey">{companyName}</p>
                </div>
                <RoundedButton icon={<PlusIcon />} handleClick={handleOpenIdealDialog} />
            </div>
            <hr className="custom-divider" />
            <div className="discover-candidates-banner">
                <div className="banner-text-items">
                    <p className="heading-s color-dark-grey">Discover your ideal candidate, right here!</p>
                    <p className="subheading-xxl color-grey">Create your first talent search. Jump in <span className="color-white">20K+</span> candidate pool.</p>
                </div>
                <PrimaryButton
                    text="Start searching"
                    className="dark"
                    handleClick={handleOpenIdealDialog}
                />
            </div>
            <div className="dashboard-info-cards">
                <DashboardInfoCard
                    isEmpty={true}
                    icon={<BillingIcon />}
                    contentText={"0"}
                    footerText={"Monthly Cost"}
                />
                <DashboardInfoCard
                    isEmpty={true}
                    icon={<TeamIcon />}
                    contentText={"0"}
                    footerText={"Hired talents"}
                />
                <DashboardInfoCard
                    isEmpty={!!!jobSearches?.length}
                    styleClass={jobSearches?.length > 0 ? 'dark-card' : ''}
                    isPercentageIncreased={true}
                    percentage={100}
                    icon={<TalentSearchesIcon />}
                    contentText={jobSearches?.length}
                    footerText={"Talent searches"}
                    handleViewAllClick={() => history.push(`/${getStateAgencyName()}/talent-searches`)}
                />
            </div>
            <div className="dashboard-additional-cards">
                {
                    getAllLikedCandidates(jobSearches).length ?
                        <DashboardSavedCandidates
                            candidatesInfo={getAllLikedCandidates(jobSearches)}
                        /> :
                        <DashboardNoItemsCard
                            title={"Saved Talents"}
                            icon={<NoSavedTalentsIcon />}
                            contentText={"You haven’t saved any talents yet"}
                        />
                }
                {
                    getAllUpcomingInterviews(jobSearches).length ?
                        <DashboardUpcomingInterviews
                            interviews={getAllUpcomingInterviews(jobSearches)}
                        /> :
                        <DashboardNoItemsCard
                            title={"Upcoming Interviews"}
                            icon={<NoUpcomingInterviewsIcon />}
                            contentText={"No upcoming Interviews"}
                        />
                }
            </div>
            {jobSearches?.length > 0 &&
                <div>
                    <p className="subheading-xxl color-grey section-title">Recent Searches: {jobSearches?.length}</p>
                    <div className="dashboard-recent-searches-cards">
                        <DashboardInfoCard
                            styleClass={'orange-card'}
                            headerText={"Discover your ideal candidate, right here!"}
                            contentText={"5000 +"}
                            contentStyle={"white-text"}
                            footerText={"Candidate pool"}
                            footerActionBtn={<RoundedButton icon={<PlusIcon />} handleClick={handleOpenIdealDialog} />}
                        />
                        {jobSearches?.map(x => (
                            <JobSearchCard jobSearch={x} />
                        ))}
                    </div>
                </div>
            }

            <IdealCandidateFormik
                handleSubmit={handleSaveIdealCandidate}
                isIdealCandidateDialogOpen={isIdealCandidateDialogOpen}
                handleCloseIdealDialog={handleCloseIdealDialog}
                initialValues={idealCandidateFormValues}
            />

            <JobDialogFormik
                handleSubmit={handleJobDialogFormSubmit}
                isJobDialogOpen={isJobDialogOpen}
                handleCloseJobDialog={handleCloseJobDialog}
                handlePreviousButtonClick={handlePreviousButtonClick}
            />
        </div>
    )
};

const mapStateToProps = (state) => ({
    companyName: getClientMenuInformation(state).name,
    jobSearches: getJobSearches(state),
    clientId: getClientId(state),
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    createJobSearch,
    fetchJobSearches,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);
