import React from 'react';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

const JobSearchResultsEmpty = ({
    title,
    subtitle,
    actionBtnText,
    actionBtnClickHandler
}) => (
    <div className="job-search-empty-wrapper">
        <p className="heading-m color-dark-grey">{title}</p>
        <p className="subheading-m color-grey">{subtitle}</p>
        <div className="action-btn-wrapper">
            <PrimaryButton className="dark" text={actionBtnText} handleClick={actionBtnClickHandler} />
        </div>
    </div>
);

export default JobSearchResultsEmpty;
