import React from 'react';

import './LabeledSwitcher.scss';

const LabeledSwitcher = ({
    options,
    selectedOption,
    setSelectedOption,
}) => (
    <div className="labeled-switcher-wrapper">
        <div className="labeled-switcher-container">
            {options.map((x, idx) => (
                <div onClick={() => x.DISABLED ? null : setSelectedOption(x)} className={`option ${selectedOption.TITLE === x.TITLE ? "selected" : ""} ${x.DISABLED ? "option-disabled" : ""}`} key={idx}>
                    <p className="subheading-l">{x.TITLE}</p>
                </div>
            ))}
        </div>
    </div>
);

export default LabeledSwitcher;
