import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import IdealCandidateFormView from './IdealCandidateFormView';

import { validateRequiredFields } from 'utils/formValidations';

const IdealCandidateFormik = ({
    handleSubmit,
    isIdealCandidateDialogOpen,
    handleCloseIdealDialog,
    initialValues,
}) => {
    const handleChangeMandatorySkills = (values, setFieldValue, newValue) => {
        setFieldValue("mandatorySkills", [...values.mandatorySkills, newValue.title]);
    };

    const handleChangeNiceToHaveSkills = (values, setFieldValue, newValue) => {
        setFieldValue("niceToHaveSkills", [...values.niceToHaveSkills, newValue.title]);
    };

    const handleDeleteMandatorySkill = (values, setFieldValue, index) => {
        setFieldValue("mandatorySkills", [...values.mandatorySkills.slice(0, index), ...values.mandatorySkills.slice(index + 1)]);

    };

    const handleDeleteNiceToHaveSkill = (values, setFieldValue, index) => {
        setFieldValue("niceToHaveSkills", [...values.niceToHaveSkills.slice(0, index), ...values.niceToHaveSkills.slice(index + 1)]);
    };

    const addNewMandatorySkill = (values, setFieldValue, newValue) => handleChangeMandatorySkills(values, setFieldValue, newValue);
    const addNewNiceToHaveSkill = (values, setFieldValue, newValue) => handleChangeNiceToHaveSkills(values, setFieldValue, newValue);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues || {
                yearsOfExperience: null,
                maximumBudget: "",
                currency: null,
                mandatorySkills: [],
                niceToHaveSkills: [],
                peopleToHire: 1,
            }}
            validate={values => {
                return {...validateRequiredFields(values, ['yearsOfExperience', 'maximumBudget', 'currency', 'mandatorySkills', 'peopleToHire'])}
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                handleSubmit(values);
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    size={"md"}
                    className="ideal-candidate-dialog"
                    open={isIdealCandidateDialogOpen}
                    onClose={handleCloseIdealDialog}
                    dialogTitle="What is your ideal candidate?"
                    saveBtnText="Next"
                    discardBtnText="Discard"
                    onSave={props.handleSubmit}
                >
                    <IdealCandidateFormView
                        {...props}
                        handleChangeMandatorySkills={handleChangeMandatorySkills}
                        handleChangeNiceToHaveSkills={handleChangeNiceToHaveSkills}
                        handleDeleteMandatorySkill={handleDeleteMandatorySkill}
                        handleDeleteNiceToHaveSkill={handleDeleteNiceToHaveSkill}
                        addNewMandatorySkill={addNewMandatorySkill}
                        addNewNiceToHaveSkill={addNewNiceToHaveSkill}
                    />
                </CommonFormikDialog>
            }
        </Formik>
    )
};

export default connect(null, null)(IdealCandidateFormik);
