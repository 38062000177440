import React from 'react';
import moment from 'moment';

import { DATE_FORMAT_WITH_SLASH_SHORT_YEAR } from 'constants/commonConstants';

import { ReactComponent as DownloadIcon } from 'assets/icons-download.svg';

const TableContent = ({
    invoices,
    downloadInvoiceHandler,
    agencyId,
    userId,
}) => (
    <table className="billing-table-content">
        <thead>
            <tr>
                <th className="subheading-xxs color-grey">Invoice</th>
                <th className="subheading-xxs color-grey">Billing date</th>
                <th className="subheading-xxs color-grey">Amount</th>
                <th className="subheading-xxs color-grey">Due date</th>
                <th className="subheading-xxs color-grey">Status</th>
                <th className="subheading-xxs color-grey">Action</th>
            </tr>
        </thead>
        <tbody>
            {invoices.map((data, index) => (
                <tr key={index}>
                    <td>{data.projectName}</td>
                    <td>{moment(data.issueDate).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR)}</td>
                    <td>{data.amount} {data.currency}</td>
                    <td>{moment(data.dueDate).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR)}</td>
                    <td>
                        <span className={`status ${data.status.toLowerCase()}`}>
                            {data.status}
                        </span>
                    </td>
                    <td><DownloadIcon onClick={() => downloadInvoiceHandler(agencyId, userId, data._id, `${data.projectName}-${moment(data.issueDate).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR)}`)} /></td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default TableContent;
