import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchClientProjectsWithEmployees } from 'actions/clientActions';
import { getClientId, getClientMenuInformation, getClientAllEmployees } from 'reducers';

import { CircularProgress, InputAdornment, InputBase } from '@material-ui/core';

import { ReactComponent as TeamIcon } from 'assets/team-menu-icon.svg';
import { ReactComponent as SearchIcon } from 'assets/icons-search.svg';

import { DATE_FORMAT_WITH_SLASH_SHORT_YEAR } from 'constants/commonConstants';

import './ClientTeam.scss';

const ClientTeam = ({
    agencyId,
    clientId,
    clientName,
    employees,
    fetchClientProjectsWithEmployees,
}) => {
    const [searchText, setSearchText] = useState("");
    const [areEmployeesLoading, setAreEmployeesLoading] = useState(false);

    const handleSearchTextChange = (event) => setSearchText(event.target.value);

    const filterSearchEmployees = (employees) => employees.filter(x => {
        const fullName = `${x.firstName} ${x.lastName}`.toLowerCase();
        return fullName.includes(searchText.toLowerCase());
    }) 

    useEffect(() => {
        setAreEmployeesLoading(true);
        fetchClientProjectsWithEmployees(agencyId, clientId).then(() => setAreEmployeesLoading(false));
    }, []);

    return (
        <div className="client-team-wrapper">
            {areEmployeesLoading ? <CircularProgress className="deep-orange page-loader" /> :
                <Fragment>
                    <div className="client-team-header">
                        <p className="heading-s color-dark-grey">Team</p>
                        <p className="subheading-l color-grey">{clientName}</p>
                        <hr className="custom-divider" />
                    </div>
                    <div className="client-team-banner">
                        <div className="client-team-employees-info">
                            <div className="client-team-employees-info-icon-wrapper">
                                <TeamIcon />
                            </div>
                            <div className="client-team-employees-info-content">
                                <p className="heading-s color-dark-grey">{employees.count}</p>
                                <p className="subheading-xs color-grey">Total Employees</p>
                            </div>
                        </div>
                    </div>
                    <div className="client-team-employees-table-wrapper">
                        <p className="heading-xs color-dark-grey">Employees</p>
                        <InputBase
                            className={"search-term-field"}
                            autoComplete="off"
                            name="searchTerm"
                            value={searchText}
                            onChange={handleSearchTextChange}
                            startAdornment={(
                                <InputAdornment position="start">
                                    <SearchIcon className="search-icon icon" />
                                </InputAdornment>
                            )}
                        />

                        <table className="client-team-employees-table">
                            <thead>
                                <tr>
                                    <th className="subheading-xxs color-grey">Name</th>
                                    <th className="subheading-xxs color-grey">Position</th>
                                    <th className="subheading-xxs color-grey">Email</th>
                                    <th className="subheading-xxs color-grey">Salary</th>
                                    <th className="subheading-xxs color-grey">Service Fee</th>
                                    <th className="subheading-xxs color-grey">Start date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterSearchEmployees(employees.result).map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.firstName} {data.lastName}</td>
                                        <td>{data.employmentInformation.contractInformation.position}</td>
                                        <td>{data.email}</td>
                                        <td>{data.currency} {data.employerTotalCost}</td>
                                        <td>{data.currency} {data.monthlyServiceFee}</td>
                                        <td>{moment(data.dateAdded).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Fragment>
            }
        </div>
    )
};

const mapStateToProps = (state) => ({
    clientName: getClientMenuInformation(state).name,
    clientId: getClientId(state),
    employees: getClientAllEmployees(state)
});

const mapDispatchToProps = {
    fetchClientProjectsWithEmployees
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientTeam);
