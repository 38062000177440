import React from 'react';
import { useIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as FilterListIcon } from 'assets/filter-icon.svg';
import { ReactComponent as UpArrow } from 'assets/arrow-up.svg';
import { ReactComponent as DownArrow } from 'assets/arrow-down.svg';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './SelectFilterList.scss'

const SelectFilterList = ({
    name,
    value,
    title,
    items,
    handleChange,
    closeMenuOnSelect,
    withTranslation,
    className,
}) => {
    const intl = useIntl();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div className={`multi-filter-list-wrapper`}>
            <Button
                className={`multi-filter-list-button${anchorEl ? " open" : ""}`}
                variant="outlined"
                onClick={handleOpen}
            >
                <div className="left-aligned">
                    <FilterListIcon className="filter-list-icon" />
                    {title && <span className="subheading-m color-grey">{title}</span>}
                </div>
                <div className="right-aligned">
                    {Boolean(anchorEl) ? <UpArrow className="arrow-icon" /> : <DownArrow className="arrow-icon" />}
                </div>
            </Button>
            <Menu
                className={`filter-list-menu ${className ? className : ""}`}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: '192px',
                        marginTop: '10px',
                        marginLeft: '0px',
                        boxShadow: '0px 1px 6px #00000033'
                    }
                }}
            >
                {items.map((item, index) => {
                    return (
                        <MenuItem
                            key={index}
                            className={value === item ? "filter-list-menu-item selected" : "filter-list-menu-item"}
                            onClick={() => {
                                handleChange(name, item);

                                if (closeMenuOnSelect) {
                                    handleClose();
                                }
                            }}
                        >
                            <span>{withTranslation ? intl.formatMessage({ id: convertStringToLanguageKey(item) }) : item}</span>
                        </MenuItem>
                    )
                })}
            </Menu>
        </div>
    );
};

export default SelectFilterList;
