import {
    JOB_OPPORTUNITIES_TYPES,
    JOB_OPPORTUNITIES_STATUSES,
    JOB_OPPORTUNITIES_PRIVACY,
    JOB_OPPORTUNITIES_PRIORITIES,
} from 'constants/jobOpportunitiesConstants';
import { ROLES } from 'constants/userConstants';

export const JOB_TYPE_FILTER_VALUES = [
    JOB_OPPORTUNITIES_TYPES.CONTRACT,
    JOB_OPPORTUNITIES_TYPES.FULL_TIME,
];

export const JOB_STATUS_FILTER_VALUES = [
    JOB_OPPORTUNITIES_STATUSES.OPEN,
    JOB_OPPORTUNITIES_STATUSES.CLOSED,
];

export const JOB_PRIVACY_FILTER_VALUES = [
    JOB_OPPORTUNITIES_PRIVACY.PUBLISHED.value,
    JOB_OPPORTUNITIES_PRIVACY.PRIVATE.value,
];

export const JOB_PRIORITY_FILTER_VALUES = [
    JOB_OPPORTUNITIES_PRIORITIES.HIGH,
    JOB_OPPORTUNITIES_PRIORITIES.MEDIUM,
    JOB_OPPORTUNITIES_PRIORITIES.LOW,
];

export const INITIAL_JOB_TYPE_FILTER = '';
export const INITIAL_JOB_STATUS_FILTER = '';
export const INITIAL_JOB_PRIVACY_FILTER = '';
export const INITIAL_JOB_PRIORITY_FILTER = '';
export const INITIAL_JOB_SEARCH_TERM_FILTER = '';
export const INITIAL_JOB_TECHNOLOGY_SEARCH_TERM_FILTER = '';

export const INITIAL_JOBS_DASHBOARD_FILTERS = {
    [ROLES.CLIENT]: {
        jobType: INITIAL_JOB_TYPE_FILTER,
        status: INITIAL_JOB_STATUS_FILTER
    },
    [ROLES.ADMIN]: {
        jobType: INITIAL_JOB_TYPE_FILTER,
        status: INITIAL_JOB_STATUS_FILTER,
        privacy: INITIAL_JOB_PRIVACY_FILTER,
        priority: INITIAL_JOB_PRIORITY_FILTER,
        searchTerm: INITIAL_JOB_SEARCH_TERM_FILTER,
        technologySearchTerm: INITIAL_JOB_TECHNOLOGY_SEARCH_TERM_FILTER,
    },
    [ROLES.SUPPLIER_ADMIN]: {
        jobType: INITIAL_JOB_TYPE_FILTER,
    },
};

export const JOBS_DASHBOARD_SELECT_FILTER_LIST_ITEMS = {
    [ROLES.CLIENT]: JOB_STATUS_FILTER_VALUES,
    [ROLES.ADMIN]: {
        status: { name: 'Status', values: JOB_STATUS_FILTER_VALUES },
        privacy: { name: 'Privacy', values: JOB_PRIVACY_FILTER_VALUES },
        priority: { name: 'Priority', values: JOB_PRIORITY_FILTER_VALUES },
    },
    [ROLES.SUPPLIER_ADMIN]: [],
};

export const DEFAULT_LIMIT = 25;

export const INITIAL_QUERY_STRING_JOBS_DASHBOARD = `?status=${encodeURIComponent(JOB_OPPORTUNITIES_STATUSES.OPEN)}`;
