import { getStateAgencyName } from './helpers';
import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { ROLES } from 'constants/userConstants';
import {
    APPLIED_CANDIDATE_STATUSES,
    DEFAULT_FILTERS, FILTER_TABS,
    DEFAULT_SORT, SORT_VALUES
} from 'components/JobPage/AppliedCandidates/AppliedCandidatesConstants';
import { BULGARIAN_LABEL } from 'constants/languages';

export const getPathToCandidateApplication = (jobId, candidateId, applicationId, displayMode) =>{
    if (displayMode === ROLES.ADMIN) {
        return `/${getStateAgencyName()}/candidates/${candidateId}/jobs/${jobId}/applications/${applicationId}`;
    } else {
        return `/${getStateAgencyName()}/jobs/${jobId}/candidates/${candidateId}/applications/${applicationId}`;
    }
}

export const candidateStatusMapper = (data) => {
    switch (data) {
        case APPLICATION_STATUSES.APPROVED:
            return APPLIED_CANDIDATE_STATUSES.HIRED;
        case APPLICATION_STATUSES.INVITED_FOR_INTERVIEW:
        case APPLICATION_STATUSES.ACCEPTED_INTERVIEW:
        case APPLICATION_STATUSES.PENDING:
            return APPLIED_CANDIDATE_STATUSES.INTERVIEW_STAGE;
        case APPLICATION_STATUSES.NEW_APPLICATION:
            return APPLIED_CANDIDATE_STATUSES.PENDING_REVIEW;
        case APPLICATION_STATUSES.PENDING_ADMIN_REVIEW:
            return APPLICATION_STATUSES.PENDING_ADMIN_REVIEW
        case APPLICATION_STATUSES.NON_VETTED_CANDIDATE:
            return APPLIED_CANDIDATE_STATUSES.NOT_VETTED
        case APPLICATION_STATUSES.REJECTED:
        case APPLICATION_STATUSES.ACCEPTED_ANOTHER_OFFER:
        case APPLICATION_STATUSES.REJECTED_BY_ADMIN:
            return APPLIED_CANDIDATE_STATUSES.REJECTED;
        default:
            return APPLIED_CANDIDATE_STATUSES.PENDING_REVIEW;
    }
};

export const candidateStatusToTooltipText = (status, selectedLanguage) => {
    switch (status) {
        case APPLICATION_STATUSES.APPROVED:
            return selectedLanguage === BULGARIAN_LABEL ? 'Този кандидат е нает' : 'This candidate has been hired';
        case APPLICATION_STATUSES.INVITED_FOR_INTERVIEW:
        case APPLICATION_STATUSES.ACCEPTED_INTERVIEW:
            return selectedLanguage === BULGARIAN_LABEL ? 'Този кандидат е на фаза интервю' : 'This candidate is in an interview stage';
        case APPLICATION_STATUSES.NEW_APPLICATION:
            return selectedLanguage === BULGARIAN_LABEL ? 'Това е нов кандидат, който чака преглед' : 'This is a new candidate application which is pending review';
        case APPLICATION_STATUSES.PENDING_ADMIN_REVIEW:
            return selectedLanguage === BULGARIAN_LABEL ? 'Това е кандидатура, която чака да бъде препратена на клиента' : 'This is an application that awaits to be forwarded to the client'
        case APPLICATION_STATUSES.NON_VETTED_CANDIDATE:
            //QUESTION/TODO: Maybe we need a different text.
            return '';
        case APPLICATION_STATUSES.REJECTED:
        case APPLICATION_STATUSES.REJECTED_BY_ADMIN:
        case APPLICATION_STATUSES.ACCEPTED_ANOTHER_OFFER:
            return selectedLanguage === BULGARIAN_LABEL ? 'Този кандидат е бил отхвърлен' : 'This candidate has been rejected';
        default:
            return selectedLanguage === BULGARIAN_LABEL ? 'Това е кандидатура, която чака преглед' : 'This is a new candidate application which is pending review';
    }
};

export const mapQueryToFiltersAppliedCandidates = (queryObj, displayMode) => {
    const initFilters = {
        status: DEFAULT_FILTERS.status,
        sortBy: DEFAULT_SORT,
        searchTerm: DEFAULT_FILTERS.searchTerm,
    };

    const newFilters = Object.keys(queryObj).reduce((acc, x) => {
        let foundItem;

        switch (x) {
            case 'status':
                foundItem = FILTER_TABS[displayMode].find(y => y.name === queryObj[x]);
                acc.status = foundItem ? foundItem.name : acc.status;
                break;
            case 'sortBy':
                foundItem = SORT_VALUES.find(y => y === queryObj[x]);
                acc.sortBy = foundItem ? foundItem : acc.sortBy;
                break;
            case 'searchTerm':
                foundItem = queryObj[x];
                acc.searchTerm = foundItem ? foundItem : acc.searchTerm;
                break;
            default:
                break;
        }
        return acc;
    }, initFilters);

    return newFilters;
};

