import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { downloadContractFiles } from 'actions/contractActions';
import { getAllContracts, getClientMenuInformation } from 'reducers';

import { InputAdornment, InputBase } from '@material-ui/core';
import Pagination from 'components/Shared/Pagination';

import { DATE_FORMAT_WITH_SLASH_SHORT_YEAR } from 'constants/commonConstants';

import { ReactComponent as SearchIcon } from 'assets/icons-search.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons-download.svg';

import './ClientContractsTable.scss';

const ITEMS_PER_PAGE = 6;

const ClientContractsTable = ({
    agencyId,
    userId,
    fetchContracts,
    downloadContractFiles,
    contracts,
    companyName
}) => {
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(Math.ceil(contracts.length / ITEMS_PER_PAGE) === 0 ? 1 : Math.ceil(contracts.length / ITEMS_PER_PAGE));

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
        setPage(1);
        setTotalPages(Math.ceil(filterSearchedContracts(contracts).length / ITEMS_PER_PAGE) === 0 ? 1 : Math.ceil(filterSearchedContracts(contracts).length / ITEMS_PER_PAGE));
    };

    const concatName = str => str.replace(/\s/g, '').trim();

    const handleContractDownloadClick = (contract) => {
        if (contract) {
            const fileName = `${concatName(contract.title)}-${concatName(contract.type)}`;

            return downloadContractFiles(agencyId, userId, contract._id, fileName.toLocaleLowerCase());
        }
    };

    const getPagedContracts = (contracts) => {
        const startIndex = (page - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return contracts.slice(startIndex, endIndex);
    };

    const filterSearchedContracts = (contracts) => {
        return contracts.filter(x => x.title.toLowerCase().includes(searchText.toLowerCase()));
    };

    useEffect(() => {
        fetchContracts(agencyId, userId, null, true);
    }, []);

    return (
        <div className="client-contracts-table-wrapper">
            <div className="client-contracts-table-text-items">
                <p className="heading-s color-dark-grey">Contracts</p>
                <p className="subheading-l color-grey">{companyName}</p>
            </div>
            <hr className="custom-divider" />
            <div className="client-contracts-table-header">
                <div className="client-contracts-table-history">
                    <p className="heading-xs color-dark-grey">Contracts History</p>
                    <InputBase
                        className={"search-term-field"}
                        autoComplete="off"
                        name="searchTerm"
                        value={searchText}
                        onChange={handleSearchTextChange}
                        startAdornment={(
                            <InputAdornment position="start">
                                <SearchIcon className="search-icon icon" />
                            </InputAdornment>
                        )}
                    />
                </div>
            </div>
            <table className="client-contracts-table-content">
                <thead>
                    <tr>
                        <th className="subheading-xxs color-grey table-column-name">Name</th>
                        <th className="subheading-xxs color-grey table-column-date">Upload date</th>
                        <th className="subheading-xxs color-grey table-column-action">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {getPagedContracts(filterSearchedContracts(contracts)).map((data, index) => (
                        <tr key={index}>
                            <td className="table-column-name">{data.title}</td>
                            <td className="table-column-date">{moment(data.uploadDate).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR)}</td>
                            <td className="table-column-action">
                                <DownloadIcon onClick={() => handleContractDownloadClick(data)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </div>
    )
};

const mapStateToProps = (state) => ({
    contracts: getAllContracts(state, 0),
    companyName: getClientMenuInformation(state).name,
});

const mapDispatchToProps = {
    downloadContractFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientContractsTable);
