import React from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';

import { candidateStatusMapper } from 'utils/applicationUtils';
import { getStateAgencyName } from 'utils/helpers';

import { DATE_FORMAT_WITH_SLASH } from 'constants/commonConstants';

import { ReactComponent as LocationIcon } from 'assets/icons-location.svg';
import { ReactComponent as ClockIcon } from 'assets/icons-clock.svg';
import { ReactComponent as CalendarIcon } from 'assets/calender-icon.svg';

const MyApplicationCard = ({ application }) => {
    const applicationLink = `/${getStateAgencyName()}/my-applications/${application._id}`;

    return (
        <Link
            className="my-application-card-link"
            to={applicationLink}
        >
            <div className="my-application-card-wrapper">
                <div className="my-application-card-heading">
                    <div className="company-info">
                        <img src={application.companyLogo} />
                        <div>
                            <p className="subheading-xs color-grey">Company</p>
                            <p className="heading-xs color-dark-grey">{application.company}</p>
                        </div>
                    </div>
                    <div className={`application-status subheading-xs-thicker ${candidateStatusMapper(application.status)?.replace(" ", "-").toLowerCase()}`}>{candidateStatusMapper(application.status)}</div>
                </div>
                <Divider className="section-divider" />
                <div className="position-info">
                    <p className="subheading-xs color-grey">Position needed:</p>
                    <p className="heading-xs color-dark-grey">{application.position}</p>
                </div>
                <div className="additional-details-wrapper">
                    <div className="additional-details-content">
                        <div className="detail">
                            <LocationIcon />
                            <p className="subheading-xxl color-grey">Remote ({application.locations.join(', ')})</p>
                        </div>
                        <div className="detail">
                            <ClockIcon />
                            <p className="subheading-xxl color-grey">{application.jobType}</p>
                        </div>
                        <div className="detail">
                            <CalendarIcon />
                            <p className="subheading-xxl color-grey">{moment(application.applicationDate).format(DATE_FORMAT_WITH_SLASH)}</p>
                        </div>
                    </div>
                    <Link className="link" to={applicationLink}>See details {`>`}</Link>
                </div>
            </div>
        </Link>
    );
};

export default MyApplicationCard;
