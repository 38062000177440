import React from 'react';
import Header from 'components/Common/Header';
import Navigation from 'components/Common/Navigation';
import JourneyNavigation from 'components/Common/JourneyNavigation';
import MobileHeader from 'components/Common/MobileHeader';

import { connect } from 'react-redux';

import { getToggleNavigation, getIsInitialJourneyCompleted, getDisplayMode } from 'reducers';

import { ReactComponent as CoderyTeamBackground } from 'assets/codery-team-background.svg';

import { ROLES } from 'constants/userConstants';

import './Main.scss';

const MainLayout = ({
    children,
    toggleNavigationValue,
    isInitialJourneyCompleted,
    displayMode,
    hideHeader,
    isClientJobDashboard,
    backgroundColor,
    isWithSubmenu,
}) => {
    return (
        <>
            <MobileHeader />

            <div className="main-layout-wrapper">
                {!isInitialJourneyCompleted && displayMode === ROLES.CANDIDATE
                    ? <JourneyNavigation />
                    : <Navigation toggleNavigationValue={toggleNavigationValue} />
                }


                <main className={`main-layout-body-wrapper ${(toggleNavigationValue ? "navigation-close " : "")} ${isClientJobDashboard ? "client-jobs-dashboard-wrapper" : ""} ${backgroundColor && backgroundColor}`}>
                    <div id="scroller" className={"main-layout-content " + (hideHeader ? "hidden-header" : "")}>
                        {!hideHeader && displayMode && <Header isWithSubmenu={isWithSubmenu} />}
                        {children}
                    </div>
                    <CoderyTeamBackground className="codery-team-background" />
                </main>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    toggleNavigationValue: getToggleNavigation(state),
    isInitialJourneyCompleted: getIsInitialJourneyCompleted(state),
    displayMode: getDisplayMode(state),
});

export default connect(mapStateToProps)(MainLayout);
