import React, { useState } from 'react';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ReactComponent as DateIcon } from 'assets/icon-date.svg';
import { ReactComponent as ClockIcon } from 'assets/icon-clock.svg';

import UpcomingInterviewDetailsDialog from './UpcomingInterviewDetailsDialog';

import { CONTINENTAL_TIME_FORMAT, DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import './UpcomingInterviewsCard.scss';
import { useIntl } from 'react-intl';

const UpcomingInterviewsCard = ({
    interview,
}) => {
    const intl = useIntl();

    const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
    const openDetailsDialogHandler = () => setIsDetailsDialogOpen(true);
    const closeDetailsDialogHandler = () => setIsDetailsDialogOpen(false);

    return (
        <Paper className="upcoming-interviews-card-wrapper">
            <div className="upcoming-interview-content">
                <div className="upcoming-interview-main-title">
                    {intl.formatMessage({ id: "your-upcoming-interview" })}
                </div>
                <div className="upcoming-interview-content-wrapper">
                    <div className="upcoming-interview-position-container">
                        <div className="upcoming-interview-title position">
                            {intl.formatMessage({ id: "position" })}:
                        </div>
                        <div className="upcoming-interview-text position">
                            {interview.position}
                        </div>
                    </div>
                    <div className="upcoming-interview-company-container">
                        <div className="upcoming-interview-title company">
                            {intl.formatMessage({ id: "company" })}:
                        </div>
                        <div className="upcoming-interview-text company">
                            {interview.company}
                        </div>
                    </div>
                </div>
                <div className="upcoming-interview-location-container">
                    <div className="upcoming-interview-title location">
                        {intl.formatMessage({ id: "location" })}:
                    </div>
                </div>
            </div>
            <div className="upcoming-interview-date-wrapper">
                <div className="upcoming-interview-date-container">
                    <div className="upcoming-interview-content-container date">
                        <DateIcon className="upcoming-interview-icon date" />
                        <div className="upcoming-interview-date">
                            {moment(interview.interviewDate.date).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}
                        </div>
                    </div>
                    <div className="upcoming-interview-content-container hour">
                        <ClockIcon className="upcoming-interview-icon hour" />
                        <div className="upcoming-interview-hour">
                            {`${moment(interview.interviewDate.time).format(CONTINENTAL_TIME_FORMAT)} - ${moment(interview.interviewDate.time).add(1, 'hours').format(CONTINENTAL_TIME_FORMAT)}`}
                        </div>
                    </div>
                </div>
            </div>
            <div className="details-button-container">
                <Button
                    className="details-button"
                    onClick={openDetailsDialogHandler}
                    color="primary"
                    variant="outlined"
                >
                    {intl.formatMessage({ id: "view-details" })}
                </Button>
            </div>

            <UpcomingInterviewDetailsDialog
                isOpen={isDetailsDialogOpen}
                position={interview.position}
                company={interview.company}
                interviewDate={interview.interviewDate.date}
                interviewTime={interview.interviewDate.time}
                mandatorySkills={interview.mandatorySkills}
                niceToHaveSkills={interview.niceToHaveSkills}
                jobDescription={interview.jobDescription}
                handleCloseDialog={closeDetailsDialogHandler}
                aboutTheClient={interview.aboutTheClient}
                aboutCandidate={interview.aboutCandidate}
                requirements={interview.requirements}
            />
        </Paper>
    )
};

export default UpcomingInterviewsCard;
