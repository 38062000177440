import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { getCountries } from 'reducers';
import { fetchAllCountries } from 'actions/staticDataActions';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import JobDialogFormView from './JobDialogFormView';

import { JOB_OPPORTUNITIES_STATUSES } from 'constants/jobOpportunitiesConstants';

import { validateRequiredFields } from 'utils/formValidations';

const JobDialogFormik = ({
    handleSubmit,
    isJobDialogOpen,
    handleCloseJobDialog,
    handlePreviousButtonClick,
    initialValues,
    countries,
    fetchAllCountries,
}) => {
    useEffect(() => {
        fetchAllCountries();
    }, []);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues || {
                jobImgUrl: null,
                jobType: null,
                position: null,
                countries: [],
                aboutTheCompany: "",
                aboutTheJob: "",
            }}
            validate={values => {
                return {...validateRequiredFields(values, ['jobType', 'position', 'countries', 'aboutTheCompany', 'aboutTheJob'])}
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                handleSubmit({...values, status: JOB_OPPORTUNITIES_STATUSES.OPEN});
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    size={"md"}
                    className="job-dialog"
                    open={isJobDialogOpen}
                    onClose={handleCloseJobDialog}
                    dialogTitle="Tell us more what they will work on?"
                    saveBtnText="Find Talent"
                    discardBtnText="Previous"
                    onDiscard={handlePreviousButtonClick}
                    onSave={props.handleSubmit}
                >
                    <JobDialogFormView
                        {...props}
                        countries={countries}
                    />
                </CommonFormikDialog>
            }
        </Formik>
    )
};

const mapStateToProps = (state) => ({
    countries: getCountries(state),
});

const mapDispatchToProps = {
    fetchAllCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDialogFormik);
