import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { getStateAgencyName, useWindowDimensions } from 'utils/helpers';

import { downloadEmployeeCv } from 'actions/employeeActions';
import { fetchAgencyCalendlyInfo } from 'actions/agencyActions';
import {
    fetchCandidateProfilePage,
    exportCandidateCV,
    updateCandidateJourneyCvPage,
    updateCandidateCriteria,
    bookFaceToFaceInterview,
    updateCandidateJourneyIsCompleted,
    markHasReceivedHiredNotification,
} from 'actions/candidateActions';

import {
    isCandidateMode,
    isAdminMode,
    getVideoInterviewAnswers,
    getEmployeeCvInformation,
    getCandidatePreferences,
    getPersonalInfo,
    getCompanyLogoUrl,
    getEmployeeUploadedCv,
    hasUserBookedInterview,
    getUserId,
    getFaceToFaceInterviewInformation,
    getCandidateProfilePageDataFetchState,
    getCandidateOverviewPageDataFetchState,
    getCandidateJourneyInfo,
    getAgencyCalendlyInfo,
    getProfileDetails,
    getCvPageUpdateState,
    getIsHired,
    getHasReceivedHiredNotification,
    getIsRejected,
    getHasCompletedSelfAssesment,
} from 'reducers';

import Confetti from 'react-confetti'

import CandidateProfileInfo from './CandidateProfileInfo';
import InterviewRecordingsSection from './InterviewRecordingsSection';
import HiringChecklist from './HiringChecklist';
import CandidateVideoInterviewFeedback from './CandidateVideoInterviewFeedback';
import CandidateJourneyCv from 'components/CandidateProfileJourney/Steps/UploadResumeWrapper/CandidateJourneyCv';
import CvSection from '../Shared/CvSection/CvSection';
import CommonDialog from 'components/Shared/CommonDialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import { checkForFilledCvSections } from 'utils/cvUtils';

import { ReactComponent as WelcomeImage } from 'assets/welcome-on-board-image.svg';
import { ReactComponent as CongratsImage } from 'assets/congrats-image.svg';
import { ReactComponent as RejectedImage } from 'assets/rejected-image.svg';

import selfAssessmentReminderGif from 'assets/self-assessment-reminder.gif';

import './CandidateProfilePage.scss';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

const CandidateProfilePage = ({
    userId,
    agencyId,
    fetchCandidateProfilePage,
    exportCandidateCV,
    updateCandidateJourneyCvPage,
    downloadEmployeeCv,
    updateCandidateCriteria,
    isCandidateMode,
    isAdminMode,
    videoInterviewAnswers,
    cvInformation,
    candidatePreferences,
    personalInfo,
    companyLogoUrl,
    uploadedCv,
    hasBookedInterview,
    calendlyInterviewInfo,
    videoInterviewFeedback,
    assignee,
    candidateProfilePageDataFetchState,
    candidateOverviewPageDataFetchState,
    candidateJourneyInfo,
    agencyCalendlyInfo,
    fetchAgencyCalendlyInfo,
    bookFaceToFaceInterview,
    updateCandidateJourneyIsCompleted,
    profileDetails,
    dataUpdateState,
    isRejected,
    isHired,
    hasReceivedHiredNotification,
    hasCompletedSelfAssessment,
    markHasReceivedHiredNotification,
}) => {
    const history = useHistory();

    const { id: candidateId } = useParams();
    const id = isCandidateMode ? userId : candidateId;
    const isFromCandidateJourney = history.location.state?.isFromCandidateJourney;
    const { width, height } = useWindowDimensions();

    const [isEditMode, setEditMode] = useState(false);
    const [uploads, setUploads] = useState({});
    const [isWelcomeDialogOpen, setIsWelcomeDialogOpen] = useState(isFromCandidateJourney);
    const [isHiredDialogOpen, setIsHiredDialogOpen] = useState(true);

    const scrollToRef = useRef(null);

    const hasFilledCvSection = checkForFilledCvSections(cvInformation);
    const showUploadCv = isAdminMode && !hasFilledCvSection && !isEditMode;

    const interviewRecordingsSectionTitle = isCandidateMode
        ? 'Here you can watch your video recording.'
        : 'Watch the candidate video introduction.';

    useEffect(() => {
        if (isCandidateMode) {
            fetchCandidateProfilePage(agencyId, id);
        }

        fetchAgencyCalendlyInfo(agencyId);
    }, []);

    useEffect(() => {
        if (uploadedCv.originalFileName) {
            setUploads({ type: 'file', filesAttached: [{ name: uploadedCv.originalFileName }] });
        } else {
            setUploads({});
        }
    }, [uploadedCv.originalFileName]);

    const enterEditMode = () => {
        setEditMode(true);
    }

    const exitEditMode = () => {
        setEditMode(false);
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView();
        }
    };

    const handleDownloadCV = () => {
        const fileName = uploadedCv?.originalFileName.split('.').slice(0, -1).join('.')
        downloadEmployeeCv(agencyId, candidateId, fileName);
    };

    const handleEventScheduling = (event) => {
        const eventUri = event.data.payload.event.uri;
        const inviteeUri = event.data.payload.invitee.uri;
        bookFaceToFaceInterview(agencyId, id, { eventUri, inviteeUri }, true, {}, true);
        updateCandidateJourneyIsCompleted(agencyId, id, true);
    };

    const handleCloseWelcomeDialog = () => setIsWelcomeDialogOpen(false);

    const handleGoToAssesmentPage = () => {
        history.push(`/${getStateAgencyName()}/profile/self-assignment`);
    };

    const handleCloseHiredDialog = () => {
        markHasReceivedHiredNotification(agencyId, userId);
        setIsHiredDialogOpen(false);
    };

    return (
        <>
            {(isCandidateMode ? candidateProfilePageDataFetchState.isDataFetching : candidateOverviewPageDataFetchState.isDataFetching)
                ? <CircularProgress thickness={5} size={50} className="page-loader" disableShrink />
                : <div className="candidate-profile-page">
                    {!hasCompletedSelfAssessment && !isAdminMode &&
                        <div className="candidate-profile-page-assessment-reminder">
                            <img src={selfAssessmentReminderGif} alt="self-assessment-reminder-gif" />
                            <div className="reminder-text">
                                <p className="heading-xs color-dark-grey">Reminder: Complete Your Technical Self-Assessment</p>
                                <p className="subheading-m color-grey">Please complete your technical self-assessment to move forward in the hiring process. Be as honest as possible, as the skills and knowledge listed will be further evaluated during interviews.</p>
                            </div>
                            <PrimaryButton handleClick={handleGoToAssesmentPage} className="dark" text="Complete Now" />
                        </div>
                    }
                    {(!isEditMode && !dataUpdateState?.isDataUpdating) &&
                        <>

                            {isAdminMode && <HiringChecklist />}
                            <CandidateProfileInfo
                                personalInfo={personalInfo}
                                profileInfo={candidatePreferences}
                                agencyId={agencyId}
                                userId={userId}
                                candidateId={id}
                                setEditMode={setEditMode}
                                isCandidateMode={isCandidateMode}
                                isAdminMode={isAdminMode}
                            />
                        </>
                    }
                    {(!isEditMode && !dataUpdateState?.isDataUpdating) &&
                        <InterviewRecordingsSection
                            title={interviewRecordingsSectionTitle}
                            videos={videoInterviewAnswers}
                            hasBookedInterview={hasBookedInterview}
                            calendlyInterviewInfo={calendlyInterviewInfo}
                            isCandidateMode={isCandidateMode}
                            isAdminMode={isAdminMode}
                            candidateJourneyInfo={candidateJourneyInfo}
                            calendlyInfo={agencyCalendlyInfo}
                            handleEventScheduling={handleEventScheduling}
                            isDeleted={profileDetails.isDeleted}
                        />
                    }
                    {showUploadCv
                        ? <CandidateJourneyCv
                            agencyId={agencyId}
                            userId={id}
                            uploads={uploads}
                            setUploads={setUploads}
                            enterEditMode={enterEditMode}
                        />
                        : <CvSection
                            userId={id}
                            agencyId={agencyId}
                            isCandidateProfilePage={true}
                            cvInformation={cvInformation}
                            enterEditMode={enterEditMode}
                            exitEditMode={exitEditMode}
                            isEditMode={isEditMode}
                            exportCandidateCV={exportCandidateCV}
                            companyLogoUrl={companyLogoUrl}
                            updateCandidateJourneyCvPage={updateCandidateJourneyCvPage}
                            isCandidateMode={isCandidateMode}
                            isAdminMode={isAdminMode}
                            handleDownloadCV={handleDownloadCV}
                            uploadedCv={uploadedCv}
                            scrollToRef={scrollToRef}
                            dataUpdateState={dataUpdateState}
                        />
                    }
                    {((!isEditMode && isAdminMode) && !dataUpdateState?.isDataUpdating) &&
                        <CandidateVideoInterviewFeedback
                            userId={userId}
                            candidateId={id}
                            agencyId={agencyId}
                            videoInterviewFeedback={videoInterviewFeedback}
                            assignee={assignee}
                            isAdminMode={isAdminMode}
                        />
                    }
                    {isFromCandidateJourney &&
                        <CommonDialog
                            openDialog={isWelcomeDialogOpen}
                            secondaryButtonText="I'll do it later"
                            primaryButtonText="Start Now"
                            dialogTitle="Complete Your Technical Self-Assessment"
                            dialogSubtitle="Please complete your technical self-assessment to move forward in the hiring process. Be as honest as possible, as the skills and knowledge listed will be further evaluated during interviews."
                            handleCloseDialog={handleCloseWelcomeDialog}
                            handleProceedDialog={handleGoToAssesmentPage}
                            className="welcome-dialog"
                            maxWidth={"sm"}
                        >
                            <WelcomeImage className="welcome-image" />
                        </CommonDialog>
                    }
                    {(isHired || isRejected) && !hasReceivedHiredNotification &&
                        <Fragment>
                            {isHired &&
                                <Confetti
                                    width={width}
                                    height={height}
                                    opacity={isHiredDialogOpen ? 1 : 0}
                                />
                            }
                            <CommonDialog
                                openDialog={isHiredDialogOpen}
                                secondaryButtonText="Close"
                                primaryButtonText="Got it!"
                                dialogTitle={isHired ? "Congrats! You are hired!" : "New opportunities are just around the corner!"}
                                dialogSubtitle={isHired ? "You did a great job! We wish you goоd luck in your new job! If you need us for anything don’t hesitate to contact us!" : "If you need us don’t hesitate to contact us!"}
                                handleCloseDialog={handleCloseHiredDialog}
                                handleProceedDialog={handleCloseHiredDialog}
                                className="welcome-dialog"
                            >
                                {isHired ? <CongratsImage className="welcome-image" /> : <RejectedImage className="welcome-image" />}
                            </CommonDialog>
                        </Fragment>
                    }
                </div >
            }
        </>
    )
}

const mapStateToProps = state => ({
    isCandidateMode: isCandidateMode(state),
    isAdminMode: isAdminMode(state),
    videoInterviewAnswers: getVideoInterviewAnswers(state),
    cvInformation: getEmployeeCvInformation(state),
    candidatePreferences: getCandidatePreferences(state),
    personalInfo: getPersonalInfo(state),
    companyLogoUrl: getCompanyLogoUrl(state),
    uploadedCv: getEmployeeUploadedCv(state),
    hasBookedInterview: hasUserBookedInterview(state),
    calendlyInterviewInfo: getFaceToFaceInterviewInformation(state),
    userId: getUserId(state),
    candidateProfilePageDataFetchState: getCandidateProfilePageDataFetchState(state),
    candidateOverviewPageDataFetchState: getCandidateOverviewPageDataFetchState(state),
    candidateJourneyInfo: getCandidateJourneyInfo(state),
    agencyCalendlyInfo: getAgencyCalendlyInfo(state),
    profileDetails: getProfileDetails(state),
    dataUpdateState: getCvPageUpdateState(state),
    isHired: getIsHired(state),
    isRejected: getIsRejected(state),
    hasCompletedSelfAssessment: getHasCompletedSelfAssesment(state),
    hasReceivedHiredNotification: getHasReceivedHiredNotification(state),
});

const mapDispatchToProps = {
    exportCandidateCV,
    updateCandidateJourneyCvPage,
    fetchCandidateProfilePage,
    downloadEmployeeCv,
    updateCandidateCriteria,
    fetchAgencyCalendlyInfo,
    bookFaceToFaceInterview,
    updateCandidateJourneyIsCompleted,
    markHasReceivedHiredNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfilePage);
