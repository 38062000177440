import React from 'react';
import { useIntl } from 'react-intl';

import CvSkillsSection from './CvSkillsSection';

import { ROLES } from 'constants/userConstants';

import './CvSkills.scss';
import { PREFERRED_STACK_KEY, SELF_ASSESSMENT_MENU_ITEMS } from 'components/TechnicalSelfAssessment/TechnicalSelfAssessmentConstants';

const CvSkills = ({
    skills,
    preferredStack,
    enterEditMode,
    enableEdit,
    displayMode
}) => {
    const intl = useIntl();

    const shouldDisplayMultipleSkillSections = preferredStack && preferredStack.length > 0;

    const filterSkills = (skills, key) => skills.filter(x => x.category === key);

    const mapPreferredStackToSkills = (stack) => stack.map(x => ({ _id: x._id, name: x.title, category: x.category, rating: x.rating }));

    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="cv-skills-wrapper">
                { shouldDisplayMultipleSkillSections ? 
                    <div className="cv-skills-container">
                        {SELF_ASSESSMENT_MENU_ITEMS.map(item => (
                            <CvSkillsSection
                                sectionTitle={item.title}
                                skills={item.key === PREFERRED_STACK_KEY ? mapPreferredStackToSkills(preferredStack) : filterSkills(skills, item.key)}
                                enableEdit={false}
                                isClient={isClient}
                                image={item.gifSource}
                            />

                        ))}
                    </div>
                : <CvSkillsSection 
                    sectionTitle={intl.formatMessage({ id: "skills-text" })}
                    skills={skills}
                    enterEditMode={enterEditMode}
                    enableEdit={enableEdit}
                    isClient={isClient}
                /> }
            </div>
        </>
    )
};

export default CvSkills;
