import React from 'react';

import InterviewDetails from 'components/Shared/InterviewDetails';

import './JobSearchUpcomingInterviews.scss';

const JobSearchUpcomingInterviews = ({
    position,
    interviews,
    matchingCandidates,
    openInviteDialogHandler,
    jobSearchId,
}) => {
    return (
        <div className="job-search-upcoming-interviews-wrapper">
            {interviews.reverse().map(interview => (
                <InterviewDetails
                    interview={interview}
                    jobSearchId={jobSearchId}
                    position={position}
                    matchingCandidates={matchingCandidates}
                    openInviteDialogHandler={openInviteDialogHandler}
                />
            ))}
        </div>
    )
};

export default JobSearchUpcomingInterviews;
