import { persistCombineReducers } from 'redux-persist';

import moment from 'moment';

import { createSelector, createStructuredSelector } from 'reselect';

import personalProjects, * as fromPersonalProjects from './personalProjectsReducer';
import employeeProjects, * as fromEmployeeProjects from './employeeProjectsReducer';
import employeeHolidaysCount, * as fromEmployeeHolidaysCount from './employeeHolidaysCountReducer';

import {
    similarTalentSingleMatch,
    getSimilarTalentMatchSpecs,
    orderSimilarTalentsByMaxPoints,
    similarTalentSingleFromArrayMatch,
    similarTalentMultipleFromArrayMatch
} from './helpers/similarTalentsHelpers';

import { PERSIST_EMPLOYEE_CONFIG } from 'utils/persistUtils';
import { escapeRegExp } from 'utils/regexUtils';
import { employeeHolidaysComparer } from 'components/Person/Holidays/HolidaysUtils';

import { DATE_FORMAT_WITH_DASH, MONTH_YEAR_DATE_FORMAT } from 'constants/commonConstants';
import { defaultOwnerValue, defaultTypeValue } from 'components/Tasks/TasksConstants';

import {
    EDUCATION_ENTRY_ADD_SUCCESS,
    EDUCATION_ENTRY_EDIT_SUCCESS,
    EDUCATION_ENTRY_REMOVE_SUCCESS,
    REVIEW_CREATE_SUCCESS,
    REVIEW_EDIT_SUCCESS,
    REVIEW_DELETE_SUCCESS,
    REVIEW_GET_ALL_SUCCESS,
    PROFILE_SKILLS_UPDATE_SUCCESS,
    PROFILE_SKILL_DELETE_SUCCESS,
    PROFILE_SKILL_CREATE_SUCCESS,
    PROFILE_FETCH_SUCCESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    PERSONAL_INFO_FETCH_SUCCESS,
    CANDIDATE_JOURNEY_INFO_FETCH_SUCCESS,
    LANGUAGE_ADD_SUCCESS,
    LANGUAGE_EDIT_SUCCESS,
    LANGUAGE_REMOVE_SUCCESS,
    TRACKED_DAYS_FETCH_SUCCESS,
    DAYS_TRACK_SUCCESS,
    CERTIFICATE_ADD_SUCCESS,
    CERTIFICATE_EDIT_SUCCESS,
    CERTIFICATE_REMOVE_SUCCESS,
    CERTIFICATES_UPDATE_SUCCESS,
    GENERAL_INFO_EDIT_SUCCESS,
    EMPLOYEES_FETCH_SUCCESS,
    EMPLOYEES_FETCH_PENDING,
    TRANSFERRED_CANDIDATES_FETCH_SUCCESS,
    TRANSFERRED_CANDIDATES_FETCH_PENDING,
    CONTACT_INFO_UPDATE_SUCCESS,
    PERSONAL_INFO_UPDATE_SUCCESS,
    BASIC_INFO_UPDATE_SUCCESS,
    EMPLOYMENT_INFO_GET_SUCCESS,
    EMPLOYMENT_INFO_UPDATE_SUCCESS,
    EMPLOYMENT_ACTIVE_COMPENSETION_EDIT_SUCCESS,
    HOLIDAY_ALLOWANCE_EDIT_SUCCESS,
    EMPLOYMENT_INFO_GET_PENDING,
    EMPLOYMENT_BANK_DETAILS_EDIT_SUCCESS,
    EMPLOYEE_CONTRACT_INFO_EDIT_SUCCESS,
    EMPLOYEE_CONTRACT_INFO_FETCH_SUCCESS,
    TASK_ADD_SUCCESS,
    TASK_EDIT_SUCCESS,
    PERSONAL_AVATAR_EDIT_SUCCESS,
    PERSONAL_AVATAR_DELETE_SUCCESS,
    TASKS_FETCH_SUCCESS,
    EMPLOYEE_REGISTER_FETCH_SUCCESS,
    CANDIDATE_REGISTER_FETCH_SUCCESS,
    HOLIDAY_REQUEST_CREATE_SUCCESS,
    EMPLOYEE_HOLIDAYS_FETCH_SUCCESS,
    EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    EMPLOYEE_HOLIDAYS_ALLOWANCE_FETCH_SUCCESS,
    EMPLYOEE_HOLIDAYS_DELETE_REQUESTS_SUCCESS,
    AGENCY_EMPLOYEE_HOME_INFORMATION_FETCH_SUCCESS,
    HOME_INFORMATION_INITIAL_STATE_SET,
    PERSONAL_INFO_INITIAL_STATE_SET,
    EMPLOYEE_ROLE_UPDATE_SUCCESS,
    AGENCY_IMPORTANT_DOCUMENTS_FETCH_SUCCESS,
    USERS_DISABLE_SUCCESS,
    BENEFIT_REQUEST_CREATE_SUCCESS,
    BENEFITS_FETCH_SUCCESS,
    BENEFIT_TEMPLATES_FETCH_SUCCESS,
    BENEFITS_DELETE_SUCCESS,
    BENEFITS_UPDATE_SUCCESS,
    BENEFIT_TEMPLATE_REQUEST_CREATE_SUCCESS,
    SKILL_SUGGESTIONS_FETCH_SUCCESS,
    SKILL_SUGGESTIONS_FETCH_PENDING,
    SKILL_SUGGESTIONS_INITIAL_STATE_SET,
    KNOW_YOUR_TEAM_SAMPLE_REFRESH_FETCH_SUCCESS,
    KNOW_YOUR_TEAM_SAMPLE_FETCH_SUCCESS,
    EMPLOYEE_RELATED_OPPORTUNITIES_FETCH_SUCCESS,
    EMPLOYEE_RELATED_OPPORTUNITIES_UPDATE_SUCCESS,
    CANDIDATE_PREFERENCES_FETCH_SUCCESS,
    CANDIDATE_PREFERENCES_UPDATE_SUCCESS,
    CANDIDATE_ASSIGNEE_UPDATE_SUCCESS,
    CANDIDATE_CRITERIA_FETCH_SUCCESS,
    CANDIDATE_CRITERIA_UPDATE_SUCCESS,
    RAW_CV_GENERATED_UPDATE_SUCCESS,
    ASSIGNEE_INFO_FETCH_SUCCESS,
    CANDIDATE_BOOK_INTERVIEW_SUCCESS,
    USER_TRANSFER_SUCCESS,
    OCCUPATION_STATUS_UPDATE_SUCCESS,
    RECRUITER_UPDATE_SUCCESS,
    EMPLOYEE_EXPERIENCE_SINCE_UPDATE_SUCCESS,
    USERS_RESTORE_SUCCESS,
    AGENCY_TASK_DELETE_SUCCESS,
    CANDIDATE_CONTRACTOR_FILTERS,
    EDUCATION_ENTRIES_UPDATE_SUCCESS,
    EMPLOYEE_TEAM_FETCH_SUCCESS,
    EMPLOYEE_UPLOADED_CV_FETCH_SUCCESS,
    CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    CANDIDATE_VIEWED_JOB_FETCH_SUCCESS,
    CANDIDATE_VIEWED_JOB_UPDATE_SUCCESS,
    CANDIDATE_PROFILE_STEPS_FETCH_SUCCESS,
    CANDIDATE_PROFILE_STEPS_UPDATE_SUCCESS,
    CHECKLISTED_CANDIDATES_FETCH_SUCCESS,
    CANDIDATE_STATUS_HISTORY_FETCH_SUCCESS,
    CANDIDATE_CALENDLY_INTERVIEW_DETAILS_FETCH_SUCCESS,
    CANDIDATE_APPLICATION_PROCESS_INFORMAITON_FETCH_SUCCESS,
    CANDIDATE_APPLICATION_PROCESS_INFORMAITON_UPDATE_SUCCESS,
    EMPLOYEE_GENERAL_INFO_FETCH_SUCCESS,
    EMPLOYEES_TOTAL_COUNT_FETCH_SUCCESS,
    EMPLOYEES_TOTAL_COUNT_FETCH_PENDING,
    EMPLOYMENT_START_DATE_UPDATE_SUCCESS,
    USER_TERMINATION_DATE_UPDATE_SUCCESS,
    QUERIED_TEAM_RESET_FETCH_SUCCESS,
    TEAM_PAGE_DATA_FETCH_SUCCESS,
    CANDIDATES_FETCH_SUCCESS,
    DEPARTMENT_MEMBERS_FETCH_SUCCESS,
    DEPARTMENT_MEMBERS_REPLACE_SUCCESS,
    EMPLOYEE_OVERVIEW_INFORMATION_FETCH_SUCCESS,
    PROFILE_CREATION_STEPS_FETCH_SUCCESS,
    EMPLOYEE_HOME_OFFICE_ALLOWANCE_FETCH_SUCCESS,
    EMPLOYEE_HOME_OFFICE_ALLOWANCE_UPDATE_SUCCESS,
    EMPLOYEE_HOME_OFFICE_REMAINING_REQUESTS_UPDATE_SUCCESS,
    EMPLOYEE_HOME_OFFICE_REMAINING_REQUESTS_FETCH_SUCCESS,
} from 'actions/actionTypes';

import {
    CANDIDATE_STATUSES,
} from 'constants/candidateConstants';

import {
    DEFAULT_CANDIDATE_STATUS_SELECT,
    DEFAULT_CANDIDATE_ASSIGNEE_SELECT,
    DEFAULT_CANDIDATE_SPECIALITY_SELECT,
} from 'components/Candidates/CandidatesTable/CandidatesTableConstants';

import { ROLES } from 'constants/userConstants';
import { HOLIDAY_APPROVAL_STATUSES } from 'constants/holidayConstants';
import { CONTRACT_SIGNATURE_STATUSES } from 'constants/contractsConstants';

const educationEntry = (state, action) => {
    if (state?._id && action?.payload?._id !== state._id) {
        return state;
    }

    switch (action.type) {
        case EDUCATION_ENTRY_ADD_SUCCESS:
        case EDUCATION_ENTRY_EDIT_SUCCESS:
            return { ...action.payload }
        default:
            return state;
    }
};

const educationList = (state = [], action) => {
    switch (action.type) {
        case PROFILE_FETCH_SUCCESS:
            return action.payload.education || [];
        case EDUCATION_ENTRIES_UPDATE_SUCCESS:
            return action.payload || [];
        case EDUCATION_ENTRY_ADD_SUCCESS:
            return [educationEntry(undefined, action), ...state];
        case EDUCATION_ENTRY_EDIT_SUCCESS:
            return state.map(x => educationEntry(x, action));
        case EDUCATION_ENTRY_REMOVE_SUCCESS:
            return state.filter(x => x._id !== action.payload._id);
        case HOME_INFORMATION_INITIAL_STATE_SET:
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return [];
        default:
            return state;
    }
};

const profileSkills = (state = [], action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return [];
        case PROFILE_SKILL_CREATE_SUCCESS:
            state = [...state, action.payload]
            return state;
        case PROFILE_SKILL_DELETE_SUCCESS:
            state = state.filter(x => x._id !== action.payload);
            return state;
        case PROFILE_SKILLS_UPDATE_SUCCESS:
            return action.payload || [];
        case PROFILE_FETCH_SUCCESS:
            return action.payload.profileSkills || [];
        default:
            return state;
    }
};

//Benefits

const benefits = (state = { list: [], templates: [] }, action) => {
    switch (action.type) {
        case BENEFIT_TEMPLATE_REQUEST_CREATE_SUCCESS:
            return { ...state, templates: action.payload }
        case BENEFIT_REQUEST_CREATE_SUCCESS:
            return { ...state, list: action.payload }
        case BENEFITS_FETCH_SUCCESS:
            return { ...state, list: action.payload }
        case BENEFIT_TEMPLATES_FETCH_SUCCESS:
            return { ...state, templates: action.payload }
        case BENEFITS_DELETE_SUCCESS:
            return { ...state, list: state.list.filter(x => x._id !== action.payload) }
        case BENEFITS_UPDATE_SUCCESS:
            return { ...state, list: action.payload }
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return { list: [], templates: [] }
        default:
            return state;
    }
};

//Performance reviews reducers:

const initialPerformanceState = {
    reviews: [],
    overallPerformance: 0,
    categoriesPerformance: []
};

const performanceReviews = (state = initialPerformanceState, action) => {
    switch (action.type) {
        case REVIEW_GET_ALL_SUCCESS:
            return { ...initialPerformanceState, ...action.payload };
        case REVIEW_CREATE_SUCCESS:
        case REVIEW_EDIT_SUCCESS:
            return { ...state, ...action.payload };
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return initialPerformanceState;
        case REVIEW_DELETE_SUCCESS:
            return {
                ...state,
                reviews: state.reviews.filter(x => x._id !== action.payload._id)
            }
        default:
            return state;
    }
};

// Languages reducers
const language = (state, action) => {
    if (state?._id && action?.payload?._id !== state._id) {
        return state;
    }

    switch (action.type) {
        case LANGUAGE_ADD_SUCCESS:
            return { ...action.payload, isNew: false }
        case LANGUAGE_EDIT_SUCCESS:
            return { ...action.payload }
        default:
            return state;
    }
};

const languages = (state = [], action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return []
        case PROFILE_FETCH_SUCCESS:
            return action.payload.languages || [];
        case LANGUAGE_ADD_SUCCESS:
            return [language(undefined, action), ...state];
        case LANGUAGE_EDIT_SUCCESS:
            return state.map(x => language(x, action));
        case LANGUAGE_REMOVE_SUCCESS:
            return state.filter(x => x._id !== action.payload._id);
        default:
            return state;
    }
};

const acceptedInterviews = (state = [], action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return [];
        case PROFILE_FETCH_SUCCESS:
            return action.payload.acceptedInterviews || [];
        default:
            return state;
    }
};

//Certificates reducers
const certificate = (state, action) => {
    if (state?._id && action?.payload?._id !== state._id) {
        return state;
    }

    switch (action.type) {
        case CERTIFICATE_ADD_SUCCESS:
            return { ...action.payload }
        case CERTIFICATE_EDIT_SUCCESS:
            return { ...action.payload }
        default:
            return state;
    }
};

const certificates = (state = [], action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return [];
        case PROFILE_FETCH_SUCCESS:
            return action.payload.certificates || [];
        case CERTIFICATES_UPDATE_SUCCESS:
            return action.payload || [];
        case CERTIFICATE_ADD_SUCCESS:
            return [certificate(undefined, action), ...state];
        case CERTIFICATE_EDIT_SUCCESS:
            return state.map(x => certificate(x, action));
        case CERTIFICATE_REMOVE_SUCCESS:
            return state.filter(x => x._id !== action.payload._id);
        default:
            return state;
    }
};

const employmentInformationInitialState = {
    generalInfo: {
        isAvailable: false,
        isVetted: false,
        availableFrom: null,
        privateNote: '',
        personality: '',
        startDate: null,
    },
    activeCompensation: {
        currency: 0,
        period: 0,
        amount: 0
    },
    compensationHistory: [],
    bankDetails: {
        firstName: '',
        lastName: '',
        iban: '',
        swift: '',
        currency: ''
    },
    contractInformation: {
        legalName: '',
        position: '',
        evrotrustEmail: '',
        employmentType: '',
        pin: '',
    },
    holidayAllowance: '',
    remainingMonthlyHomeOfficeRequests: 0,
    homeOfficeAllowance: 0,
    progressData: {},
    isRawCvPdfGenerated: null,
    employeeEvrotrustStatus: {},
    occupationStatus: null,
    projectEndDate: null,
}

const employmentInformation = (state = employmentInformationInitialState, action) => {
    switch (action.type) {
        case PROFILE_FETCH_SUCCESS:
            return action.payload.employmentInformation
                ? { ...state, ...action.payload.employmentInformation }
                : { ...state }
        case GENERAL_INFO_EDIT_SUCCESS:
            return { ...state, generalInfo: action.payload }
        case EMPLOYMENT_INFO_UPDATE_SUCCESS:
            return { ...state, ...action.payload }
        case EMPLOYMENT_INFO_GET_SUCCESS:
            return { ...state, ...action.payload }
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return employmentInformationInitialState;
        case AGENCY_EMPLOYEE_HOME_INFORMATION_FETCH_SUCCESS:
            return { ...state, generalInfo: { ...state.generalInfo, position: action.payload.position } }
        case EMPLOYMENT_ACTIVE_COMPENSETION_EDIT_SUCCESS:
            return { ...state, ...action.payload };
        case HOLIDAY_ALLOWANCE_EDIT_SUCCESS:
        case EMPLOYEE_HOLIDAYS_ALLOWANCE_FETCH_SUCCESS:
            return { ...state, holidayAllowance: action.payload }
        case EMPLOYEE_HOME_OFFICE_ALLOWANCE_FETCH_SUCCESS:
        case EMPLOYEE_HOME_OFFICE_ALLOWANCE_UPDATE_SUCCESS:
            return { ...state, homeOfficeAllowance: action.payload };
        case EMPLOYEE_HOME_OFFICE_REMAINING_REQUESTS_FETCH_SUCCESS:
            return { ...state, remainingMonthlyHomeOfficeRequests: action.payload };
        case EMPLOYEE_HOME_OFFICE_REMAINING_REQUESTS_UPDATE_SUCCESS:
            return { ...state, remainingMonthlyHomeOfficeRequests: state.remainingMonthlyHomeOfficeRequests - action.payload };
        case EMPLOYMENT_BANK_DETAILS_EDIT_SUCCESS:
            return { ...state, ...action.payload }
        case EMPLOYEE_CONTRACT_INFO_EDIT_SUCCESS:
        case EMPLOYEE_CONTRACT_INFO_FETCH_SUCCESS:
        case OCCUPATION_STATUS_UPDATE_SUCCESS:
            return { ...state, ...action.payload }
        case EMPLOYMENT_INFO_GET_PENDING:
            return { ...state, ...action.payload }
        case RAW_CV_GENERATED_UPDATE_SUCCESS:
            return { ...state, ...action.payload }
        case EMPLOYEE_EXPERIENCE_SINCE_UPDATE_SUCCESS:
            return { ...state, generalInfo: { ...state.generalInfo, experienceSince: action.payload } }
        case CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return employmentInformationInitialState;
        case EMPLOYEE_GENERAL_INFO_FETCH_SUCCESS:
            return { ...state, generalInfo: action.payload };
        case EMPLOYMENT_START_DATE_UPDATE_SUCCESS:
            return {
                ...state,
                generalInfo: {
                    ...state.generalInfo,
                    startDate: action.payload
                }
            }
        default:
            return state;
    }
};

const employeeInitialState = {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    supplierId: '',
    employmentInformation: {
        generalInfo: {
            position: '',
            profileSummary: '',
            experienceSince: '',
            privateNote: '',
            personality: '',
            isAvailable: false,
            isVetted: false
        },
        contractInformation: {
            legalName: '',
            position: '',
            evrotrustEmail: '',
            employmentType: '',
        }
    },
    hourlyWage: '',
    status: '',
    country: '',
    skills: [],
    projectNames: [],
    occupationStatus: 0,
    projectEndDate: '',
    pendingHolidaysCount: 0,
    registrationType: '',
    statusHistory: {},
    clientProjects: [],
};

const employee = (state = employeeInitialState, action) => {
    switch (action.type) {
        case EMPLOYEES_FETCH_SUCCESS:
        case CANDIDATES_FETCH_SUCCESS:
            return { ...employeeInitialState, ...state };
        case RECRUITER_UPDATE_SUCCESS:
            return { ...state, recruiterId: action.payload.recruiterId, recruiterName: action.payload.recruiterName }
        case OCCUPATION_STATUS_UPDATE_SUCCESS:
            return { ...state, occupationStatus: action.payload.occupationStatus, projectEndDate: action.payload.projectEndDate }
        case SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS:
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return employeeInitialState;
        default:
            return state;
    }
};

const employees = (state = [], action) => {
    switch (action.type) {
        case BASIC_INFO_UPDATE_SUCCESS:
            const newState = state.map(x => {
                if (x._id === action.payload.employeeId) {
                    x = { ...x, ...action.payload }
                }
                return x;
            });

            return newState;
        case DEPARTMENT_MEMBERS_FETCH_SUCCESS:
            return [...state, ...action.payload.map(x => employee(x, action))];
        case DEPARTMENT_MEMBERS_REPLACE_SUCCESS:
        case EMPLOYEES_FETCH_SUCCESS:
            return action.payload.map(x => employee(x, action));
        case CANDIDATES_FETCH_SUCCESS:
            return [...state, ...action.payload.map(x => employee(x, action))];
        case EMPLOYEES_FETCH_PENDING:
            return []
        case USERS_DISABLE_SUCCESS:
        case USERS_RESTORE_SUCCESS:
            return state.filter(x => !action.payload.includes(x._id));
        case CANDIDATE_REGISTER_FETCH_SUCCESS:
        case EMPLOYEE_REGISTER_FETCH_SUCCESS:
            return [...[action.payload], ...state];
        case EMPLOYEE_ROLE_UPDATE_SUCCESS:
            return state.map(employee => {
                return action.payload.ids.includes(employee._id)
                    ? { ...employee, ...action.payload.update }
                    : employee;
            });
        case RECRUITER_UPDATE_SUCCESS:
        case OCCUPATION_STATUS_UPDATE_SUCCESS:
            return state.map(x => x._id === action.payload._id ? employee(x, action) : x);
        case SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS:
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
        case CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS:
        case QUERIED_TEAM_RESET_FETCH_SUCCESS:
            return [];
        case TEAM_PAGE_DATA_FETCH_SUCCESS:
            return [...state, ...action.payload]
        default:
            return state;
    }
};

const employeesTotalCount = (state = 0, action) => {
    switch (action.type) {
        case EMPLOYEES_TOTAL_COUNT_FETCH_SUCCESS:
            return action.payload;
        case EMPLOYEES_TOTAL_COUNT_FETCH_PENDING:
            return 0;
        case USERS_DISABLE_SUCCESS:
            return Math.max(0, state - action.payload.length);
        default:
            return state;
    }
};

const transferredCandidatesInitialState = {
    transferredCandidates: []
};

const transferredCandidates = (state = transferredCandidatesInitialState, action) => {
    switch (action.type) {
        case TRANSFERRED_CANDIDATES_FETCH_SUCCESS:
            return [...action.payload];
        case TRANSFERRED_CANDIDATES_FETCH_PENDING:
            return []
        default:
            return state;
    }
};

const checklistedCandidates = (state = [], action) => {
    switch (action.type) {
        case CHECKLISTED_CANDIDATES_FETCH_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
};

const lastViewedJobInitialState = null;

const lastViewedJob = (state = lastViewedJobInitialState, action) => {
    switch (action.type) {
        case CANDIDATE_VIEWED_JOB_FETCH_SUCCESS:
        case CANDIDATE_VIEWED_JOB_UPDATE_SUCCESS:
            return action.payload;
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return lastViewedJobInitialState
        default:
            return state;
    }
};


const knowYourTeamEmployeeInitialState = {
    _id: '',
    firstName: '',
    lastName: '',
    position: '',
    profilePictureImgUrl: '',
    personalInfo: {
        hobbies: '',
        funFact: ''
    },
};

const knowYourTeamEmployee = (state = knowYourTeamEmployeeInitialState, action) => {
    switch (action.type) {
        case KNOW_YOUR_TEAM_SAMPLE_FETCH_SUCCESS:
        case KNOW_YOUR_TEAM_SAMPLE_REFRESH_FETCH_SUCCESS:
            return { ...knowYourTeamEmployeeInitialState, ...state };
        default:
            return state;
    }
};

const knowYourTeamSample = (state = [], action) => {
    switch (action.type) {
        case KNOW_YOUR_TEAM_SAMPLE_FETCH_SUCCESS:
            return state.length === 0 ? action.payload.map(x => knowYourTeamEmployee(x, action)) : state;
        case KNOW_YOUR_TEAM_SAMPLE_REFRESH_FETCH_SUCCESS:
            return action.payload.map(x => knowYourTeamEmployee(x, action));
        case EMPLOYEES_FETCH_PENDING:
            return []
        default:
            return state;
    }
};

const personalInformationInitialState = {
    email: '',
    firstName: '',
    lastName: '',
    registrationDate: '',
    phone: '',
    country: '',
    currentLocation: '',
    address: '',
    funFact: '',
    birthdate: '',
    linkedIn: '',
    gitHub: '',
    hobbies: '',
    profilePictureImgUrl: '',
    profilePictureImgName: '',
    profilePictureImgDetails: {
        width: 0,
        height: 0,
        size: 0,
        sizeBits: 'B',
        extension: ''
    },
    hasBookedInterview: false,
    calendlyInterviewInfo: {
        startDateTime: '',
        endDateTime: '',
        status: '',
        recruiter: '',
    },
    status: '',
    statusHistory: [],
    uploadedCv: {
        originalFileName: '',
    },
    referrer: '',
}

const personalInformation = (state = personalInformationInitialState, action) => {
    switch (action.type) {
        case PERSONAL_INFO_INITIAL_STATE_SET:
            return personalInformationInitialState;
        case PERSONAL_INFO_FETCH_SUCCESS:
            return { ...personalInformationInitialState, ...action.payload };
        case CANDIDATE_BOOK_INTERVIEW_SUCCESS:
            return { ...state, hasBookedInterview: action.payload.hasBookedInterview };
        case EMPLOYEE_UPLOADED_CV_FETCH_SUCCESS:
        case CONTACT_INFO_UPDATE_SUCCESS:
        case PERSONAL_INFO_UPDATE_SUCCESS:
        case BASIC_INFO_UPDATE_SUCCESS:
        case CANDIDATE_ASSIGNEE_UPDATE_SUCCESS:
        case ASSIGNEE_INFO_FETCH_SUCCESS:
        case PERSONAL_AVATAR_EDIT_SUCCESS:
        case CANDIDATE_STATUS_HISTORY_FETCH_SUCCESS:
            return { ...state, ...action.payload };
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return personalInformationInitialState;
        case AGENCY_EMPLOYEE_HOME_INFORMATION_FETCH_SUCCESS:
            return { ...state, profilePictureImgUrl: action.payload.profilePictureImgUrl }
        case PERSONAL_AVATAR_DELETE_SUCCESS:
            return {
                ...state, ...{
                    profilePictureImgName: '',
                    profilePictureImgUrl: '',
                    profilePictureImgDetails: {
                        width: 0,
                        height: 0,
                        size: 0,
                        sizeBits: 'B',
                        extension: ''
                    }
                }
            };
        case PROFILE_FETCH_SUCCESS:
            return action.payload.personalInformation
                ? { ...personalInformationInitialState, ...action.payload.personalInformation }
                : { ...state }
        case USER_TRANSFER_SUCCESS:
            return { ...state, role: action.payload };
        case CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return { ...personalInformationInitialState }
        case CANDIDATE_CALENDLY_INTERVIEW_DETAILS_FETCH_SUCCESS:
            return { ...state, calendlyInterviewInfo: action.payload };
        default:
            return state;
    }
};

const trackedDays = (state = [], action) => {
    switch (action.type) {
        case TRACKED_DAYS_FETCH_SUCCESS:
        case DAYS_TRACK_SUCCESS:
            return [...action.payload];
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return [];
        default:
            return state;
    }
};

const taskInitialState = {
    _id: '',
    name: '',
    relatedTo: {},
    owner: {},
    description: '',
    reminderDateTime: null,
    reminder: false,
    status: 'incompleted',
};

const task = (state = taskInitialState, action) => {
    switch (action.type) {
        case TASK_ADD_SUCCESS:
            return { ...action.payload }
        case TASK_EDIT_SUCCESS:
            return { ...state, ...action.payload }
        case TASKS_FETCH_SUCCESS:
            return { ...taskInitialState, ...state }
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return tasksInitialState;
        default:
            return state;
    }
};

const tasksInitialState = [];

const tasks = (state = tasksInitialState, action) => {
    switch (action.type) {
        case TASKS_FETCH_SUCCESS:
            return action.payload.map(x => task(x, action));
        case TASK_ADD_SUCCESS:
            return [...state, task(undefined, action)];
        case TASK_EDIT_SUCCESS:
            const updatedTask = task(undefined, action);
            return state.map(x => x._id === updatedTask._id ? updatedTask : x);
        case AGENCY_TASK_DELETE_SUCCESS:
            return state.filter(x => x._id !== action.payload)
        default:
            return state;
    }
};

const holiday = (state = {}, action) => {
    if (state && state._id !== action.payload._id) {
        return state;
    }

    switch (action.type) {
        case EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS:
            return { ...state, status: HOLIDAY_APPROVAL_STATUSES.REJECTED, signatureStatus: action.payload.signatureStatus || 'sign pending' };
        case EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS:
            return { ...state, status: HOLIDAY_APPROVAL_STATUSES.APPROVED, signatureStatus: action.payload.signatureStatus || CONTRACT_SIGNATURE_STATUSES.NOT_APPLICABLE };
        default:
            return state;
    }
};

const holidays = (state = [], action) => {
    switch (action.type) {
        case HOLIDAY_REQUEST_CREATE_SUCCESS:
            return [...state, ...action.payload];
        case EMPLOYEE_HOLIDAYS_FETCH_SUCCESS:
            return action.payload ? [...action.payload] : [];
        case EMPLYOEE_HOLIDAYS_DELETE_REQUESTS_SUCCESS:
            return state.filter(x => !action.payload.includes(x._id));
        case EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS:
        case EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS:
            return action.payload.reduce((state, holidayRequest) => {
                return state.map(x => holiday(x, { ...action, payload: holidayRequest }));
            }, state.slice());
        default:
            return state;
    }
};

const notifications = (state = [], action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const importantDocuments = (state = [], action) => {
    switch (action.type) {
        case AGENCY_IMPORTANT_DOCUMENTS_FETCH_SUCCESS:
            return [...action.payload];
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return [];
        default:
            return state;
    }
};

const initialSkillSuggestions = {
    list: [],
    isFetching: false,
};

const skillSuggestions = (state = initialSkillSuggestions, action) => {
    switch (action.type) {
        case SKILL_SUGGESTIONS_FETCH_PENDING:
            return { list: [], isFetching: true };
        case SKILL_SUGGESTIONS_INITIAL_STATE_SET:
            return initialSkillSuggestions;
        case SKILL_SUGGESTIONS_FETCH_SUCCESS:
            return { list: [...action.payload], isFetching: false };
        default:
            return state;
    }
};

const employeeRelatedOpportunitiesInitialState = {
    onDemand: [],
    dedicated: [],
};

const employeeRelatedOpportunities = (state = employeeRelatedOpportunitiesInitialState, action) => {
    switch (action.type) {
        case EMPLOYEE_RELATED_OPPORTUNITIES_UPDATE_SUCCESS:
            return {
                ...state,
                onDemand: [...state.onDemand, ...action.payload],
            };
        case EMPLOYEE_RELATED_OPPORTUNITIES_FETCH_SUCCESS:
            return {
                onDemand: action.payload.filter(x => x.opportunityType === 'onDemand'),
                dedicated: action.payload.filter(x => x.opportunityType === 'dedicated')
            };
        default:
            return state;
    }
};

const candidatePreferencesInitialState = {
    jobType: '',
    technologies: [],
    linkedIn: '',
    salaryValue: '',
    salaryCurrency: '',
    netSalaryValue: '',
    timezone: '',
    earliestDate: null,
    seniority: '',
    experienceSince: '',
    isWithTechStack: true,
}

const candidatePreferences = (state = candidatePreferencesInitialState, action) => {
    switch (action.type) {
        case CANDIDATE_PREFERENCES_FETCH_SUCCESS:
            return { ...candidatePreferencesInitialState, ...action.payload };
        case CANDIDATE_PREFERENCES_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        case CANDIDATE_CONTRACTOR_FILTERS:
            return { ...state, ...action.payload }
        case HOME_INFORMATION_INITIAL_STATE_SET:
        case CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return candidatePreferencesInitialState;
        default:
            return state;
    }
};

const candidateJourneyInfo = (state = {}, action) => {
    switch (action.type) {
        case CANDIDATE_JOURNEY_INFO_FETCH_SUCCESS:
            return { ...action.payload };
        default:
            return state;
    }
};

const candidateCriteriaInitialState = {
    personality: 0,
    softSkills: 0,
    technicalSkills: 0,
    languageSkills: 0,
    summary: '',
    status: '',
    dateOfRate: null,
    statusLastEdit: null,
    reviewerName: '',
}

const candidateCriteria = (state = candidateCriteriaInitialState, action) => {
    switch (action.type) {
        case CANDIDATE_CRITERIA_FETCH_SUCCESS:
            return { ...candidateCriteriaInitialState, ...action.payload };
        case CANDIDATE_CRITERIA_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        case CANDIDATE_PROFILE_STEPS_UPDATE_SUCCESS:
            return {
                ...state,
                personality: action.payload.softSkillsStep?.personalityRate || state.personality,
                softSkills: action.payload.softSkillsStep?.communicationRate || state.softSkills,
                languageSkills: action.payload.softSkillsStep?.languagesRate || state.languageSkills,
                technicalSkills: action.payload.hardSkillsStep?.hardSkillsAssessment || state.technicalSkills,
            }
        default:
            return state;
    }
};

const employeeTeam = (state = [], action) => {
    switch (action.type) {
        case EMPLOYEE_TEAM_FETCH_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
};

const profileDetailsInitialState = {
    isDeleted: false,
    dateDeleted: null,
    terminationDate: null,
}

const profileDetails = (state = profileDetailsInitialState, action) => {
    switch (action.type) {
        case PROFILE_DETAILS_FETCH_SUCCESS:
            return { ...profileDetailsInitialState, isDeleted: action.payload.isDeleted, dateDeleted: action.payload.dateDeleted, terminationDate: action.payload.terminationDate }
        case USER_TERMINATION_DATE_UPDATE_SUCCESS:
            return { ...state, terminationDate: action.payload.terminationDate, isDeleted: action.payload.isDeleted };
        case USERS_RESTORE_SUCCESS:
            return { ...state, dateDeleted: null, isDeleted: false, terminationDate: null };
        default:
            return state;
    }
};

const candidateProfileStepsInitialState = {
    softSkillsStep: {
        languagesRate: 0,
        personalityRate: 0,
        communicationRate: 0,
        dateOfRate: null,
        description: '',
        ratedBy: '',
        candidateMissing: false,
        isCompleted: false,
    },
    hardSkillsStep: {
        hardSkillsAssessment: 0,
        dateOfRate: null,
        description: '',
        ratedBy: '',
        candidateMissing: false,
        isCompleted: false
    },
    sendFeedbackToCandidateStep: {
        softSkillsAverageScore: 0,
        status: '',
        rejectionReason: '',
        internalFeedback: '',
        summary: '',
        dateOfRate: null,
        isCompleted: false
    },
    sentProfileToClientStep: { dateOfRate: null, isCompleted: false },
    updateApplicationStatusStep: { isCompleted: false }
}

const candidateProfileSteps = (state = candidateProfileStepsInitialState, action) => {
    switch (action.type) {
        case CANDIDATE_PROFILE_STEPS_FETCH_SUCCESS:
        case CANDIDATE_PROFILE_STEPS_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        case CANDIDATE_CRITERIA_UPDATE_SUCCESS:
            return {
                ...state,
                sendFeedbackToCandidateStep: {
                    ...state.sendFeedbackToCandidateStep,
                    internalFeedback: action.payload.internalFeedback,
                    summary: action.payload.summary,
                    dateOfRate: action.payload.dateOfRate,
                    status: action.payload.status,
                    rejectionReason: action.payload.rejectionReason,
                    isCompleted: true,
                }
            }
        default:
            return state;
    }
};
const applicationProcessInformationInitialState = {
    isProfileVettedStepFinished: false,
    isSearchForJobStepFinished: false,
    isSendJobApplicationStepFinished: false,
    isInterviewStepFinished: false,
    isProjectOnboardingStepFinished: false
};

const candidateApplicationProcessInformation = (state = applicationProcessInformationInitialState, action) => {
    switch (action.type) {
        case CANDIDATE_APPLICATION_PROCESS_INFORMAITON_FETCH_SUCCESS:
        case CANDIDATE_APPLICATION_PROCESS_INFORMAITON_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const overviewInformationInitialState = {
    contractsCount: 0,
    historicalNotesCount: 0,
    hasCVInformation: false,
    missingHoursInMonths: [],
};

const overviewInformation = (state = overviewInformationInitialState, action) => {
    switch (action.type) {
        case EMPLOYEE_OVERVIEW_INFORMATION_FETCH_SUCCESS:
            return { ...state, ...action.payload }
        default:
            return state;
    }
};

const profileCreationStepsInitialState = []

const profileCreationSteps = (state = profileCreationStepsInitialState, action) => {
    switch (action.type) {
        case PROFILE_CREATION_STEPS_FETCH_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

const employeeReducer = persistCombineReducers(PERSIST_EMPLOYEE_CONFIG, {
    educationList,
    skillSuggestions,
    performanceReviews,
    profileSkills,
    languages,
    personalProjects,
    benefits,
    trackedDays,
    certificates,
    personalInformation: personalInformation,
    employmentInformation,
    employees,
    employeesTotalCount,
    transferredCandidates,
    checklistedCandidates,
    lastViewedJob,
    holidays,
    tasks,
    notifications,
    projects: employeeProjects,
    importantDocuments,
    knowYourTeamSample,
    employeeTeam,
    pendingHolidaysCount: employeeHolidaysCount,
    profileDetails,
    relatedOpportunities: employeeRelatedOpportunities,
    candidatePreferences: candidatePreferences,
    candidateCriteria,
    candidateProfileSteps,
    candidateApplicationProcessInformation,
    candidateJourneyInfo: candidateJourneyInfo,
    overviewInformation,
    profileCreationSteps,
    acceptedInterviews,
});

export default employeeReducer;

// Selectors 
export const getEmployeePersonalProjectsList = state => fromPersonalProjects.getEmployeePersonalProjectsList(state.personalProjects);
export const getEmployeeCurrentPersonalProject = state => fromPersonalProjects.getEmployeeCurrentPersonalProject(state.personalProjects);
export const getPersonalProjectTemplatesList = state => fromPersonalProjects.getPersonalProjectTemplatesList(state.personalProjects);

export const getEmployeeBenefitsList = state => state.benefits.list;
export const getEmployeeBenefitTemplates = state => state.benefits.templates;

export const getEmployeeEducationList = state => state.educationList;
export const getPerformanceReviews = state => state.performanceReviews.reviews;
export const getOverallPerformance = state => ({
    overallPerformance: state.performanceReviews.overallPerformance,
    categoriesPerformance: state.performanceReviews.categoriesPerformance,
});

export const getProfileSkills = state => state.profileSkills;
export const getEmployeeLanguages = state => state.languages;
export const getCertificates = state => state.certificates;
export const getEmploymentInformation = state => state.employmentInformation;
export const getGeneralInfo = state => getEmploymentInformation(state).generalInfo;
export const getEmploymentActiveCompensation = state => state.employmentInformation.activeCompensation;
export const getEmploymentCompensationHistory = state => state.employmentInformation.compensationHistory;
export const getEmploymentHolidayAllowance = state => state.employmentInformation.holidayAllowance;
export const getEmploymentHomeOfficeAllowance = state => state.employmentInformation.homeOfficeAllowance;
export const getEmploymentHomeOfficeRemainingRequests = state => state.employmentInformation.remainingMonthlyHomeOfficeRequests;
export const getSkillSuggestions = state => state.skillSuggestions.list;
export const getIsSkillSuggestionFetching = state => state.skillSuggestions.isFetching;
export const getContactInfo = state => ({
    phone: state.personalInformation.phone,
    currentLocation: state.personalInformation.currentLocation,
    address: state.personalInformation.address,
    linkedIn: state.personalInformation.linkedIn,
    gitHub: state.personalInformation.gitHub
});
export const getPersonalInfo = state => state.personalInformation;
export const getEmployeeBasicInfo = state => ({
    email: state.personalInformation.email,
    firstName: state.personalInformation.firstName,
    lastName: state.personalInformation.lastName,
    firstNameBg: state.personalInformation.firstNameBg,
    lastNameBg: state.personalInformation.lastNameBg,
    profilePictureImgUrl: state.personalInformation.profilePictureImgUrl,
    profilePictureImgDetails: state.personalInformation.profilePictureImgDetails,
    role: state.personalInformation.role,
    registrationDate: state.personalInformation.registrationDate,
    registrationType: state.personalInformation.registrationType,
    supplierId: state.personalInformation.supplierId,
    referrer: state.personalInformation.referrer,
});
export const getEmployeeProfileCVInfo = state => state.profileCV;

export const getEmployees = (state, page, count, filters) => {
    const from = page * count;
    const to = page * count + count;

    const result = state.employees.filter(employee => {
        let isSearchMatch = true;

        if (filters?.searchTerm) {
            const regexTest = new RegExp(escapeRegExp(filters.searchTerm), 'gi');
            isSearchMatch = regexTest.test(`${employee?.firstName} ${employee?.lastName}`);
        }

        return isSearchMatch;
    });

    return result.slice(from, to || result.length);
}

export const getClientConsultants = (state) => {
    return state.employees.map(employee => ({
        _id: employee._id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        occupationStatus: employee.occupationStatus,
        hourRate: employee?.employmentInformation?.generalInfo?.hourRate,
    }))
};

export const getInternalEmployees = (state) => state.employees

export const getTransferredCandidates = (state) => {
    return state.transferredCandidates;
}

export const getLastViewedJob = (state) => state.lastViewedJob;

export const getComparedEmployees = (state, page, count, comparer, filters) => {
    const from = page * count;
    const to = page * count + count;

    let employees = state.employees.filter(employee => {
        const containsSearchTerm = employee.firstName?.toLowerCase().includes(filters?.searchTerm?.toLowerCase())
            || employee.lastName?.toLowerCase()?.includes(filters?.searchTerm?.toLowerCase())
            || `${employee.firstName?.toLowerCase()} ${employee.lastName?.toLowerCase()}`?.includes(filters?.searchTerm?.toLowerCase());
        return containsSearchTerm
    })

    employees = comparer
        ? employees.slice()
            .map(x => ({ ...x, fullName: `${x.firstName} ${x.lastName}` }))
            .sort(comparer).slice(from, to || state.employees.length)
        : employees.slice()
            .map(x => ({ ...x, fullName: `${x.firstName} ${x.lastName}` }))
            .slice(from, to || state.employees.length);

    const employeesCount = state.employees.length;

    return { result: employees, count: employeesCount }
};

const filterCandidates = (candidate, filters) => {
    let hasStatus = true;
    let hasAssignee = true;
    let hasSpecialities = true;

    if (filters) {
        hasStatus = filters.status.value === DEFAULT_CANDIDATE_STATUS_SELECT.value || filters.status.name === candidate.status;
        hasAssignee = filters.assignee.value === DEFAULT_CANDIDATE_ASSIGNEE_SELECT.value || filters.assignee.name === candidate.assignee.name;
        hasSpecialities = filters.speciality.value === DEFAULT_CANDIDATE_SPECIALITY_SELECT.value ||
            (candidate.preferences?.technologies
                && candidate.preferences.technologies
                    .find(spec => spec.title === filters.speciality.name)) || filters.speciality.name === candidate.preferences?.jobType;
    }

    return {
        hasStatus,
        hasAssignee,
        hasSpecialities
    }
}

export const getAppliedCandidates = (state, page, count, comparer, filters) => {
    const appliedCandidates = state.employees
        .filter(employee => {
            const isApplicantPipeline =
                employee.status !== CANDIDATE_STATUSES.REJECTED &&
                employee.status !== CANDIDATE_STATUSES.VETTED &&
                employee.status !== CANDIDATE_STATUSES.INCOMPLETE_PROFILE;

            const candidateFiltersResult = filterCandidates(employee, filters);

            return isApplicantPipeline && candidateFiltersResult.hasStatus && candidateFiltersResult.hasAssignee && candidateFiltersResult.hasSpecialities;
        })
        .sort(comparer);
    return {
        count: appliedCandidates.length,
        result: appliedCandidates
            .slice(0, count || state.employees.length)
    };
}

export const getResolvedCandidates = (state, page, count, comparer, filters) => {
    const resolvedCandidates = state.employees
        .filter(employee => {
            const candidateFiltersResult = filterCandidates(employee, filters);
            return candidateFiltersResult.hasStatus && candidateFiltersResult.hasAssignee && candidateFiltersResult.hasSpecialities;
        })
        .sort(comparer);
    return {
        count: resolvedCandidates.length,
        result: resolvedCandidates
            .slice(0, count || state.employees.length)
    }
}

export const getTalent = (state, userId) => {
    const talent = state.find(x => x._id === userId);
    return talent && { ...talent, name: `${talent.firstName} ${talent.lastName}` }
};

export const getTalents = (state, vettedFilter, availabilityFilter, tags, languageFilter, profileTypeFilter, experienceFilter, currentLocationFilter, supplierFilter) => {
    const filteredByVetted = getFilteredByVetted(state.employees, vettedFilter);
    const filteredByAvailability = getFilteredByAvailability(filteredByVetted, availabilityFilter);
    const filteredByTag = getFilteredBySkills(filteredByAvailability, tags);
    const filteredByLanguage = getFilteredByLanguage(filteredByTag, languageFilter);
    const filteredByProfileType = getFilteredByProfileType(filteredByLanguage, profileTypeFilter)
    const filteredByExperience = getFilteredByExperience(filteredByProfileType, experienceFilter);
    const filteredByLocation = getFilteredByCurrentLocation(filteredByExperience, currentLocationFilter);
    return getFilteredBySupplier(filteredByLocation, supplierFilter);
};

const getFilteredBySkills = (employees, skillsFilter) => {
    if (skillsFilter && skillsFilter.length > 0) {
        const matchedEmployees = employees.map(x => ({ id: x._id, isMatched: false }));
        for (const employee of employees) {
            const employeeMatch = matchedEmployees.find(x => x.id === employee._id);
            let matchedSkillsCount = 0;
            if (employee.profileSkills) {
                for (const profileSkill of employee.profileSkills) {
                    if (profileSkill.skills) {
                        for (const skill of profileSkill.skills) {
                            for (const filterSkill of skillsFilter) {
                                if (filterSkill.name.toLowerCase() === skill.name.toLowerCase()) {
                                    matchedSkillsCount++;
                                    if (matchedSkillsCount === skillsFilter.length) {
                                        employeeMatch.isMatched = true;
                                        employeeMatch.employee = employee;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return matchedEmployees
            .map(x => x.isMatched ? x.employee : undefined)
            .filter(x => x);
    }

    return employees;
}

const getFilteredByVetted = (employees, vettedFilter) => {
    if (vettedFilter) {
        const filteredEmployees = employees.filter(employee => {
            const role = employee.role;
            if (role === ROLES.CANDIDATE) {
                return employee.status === CANDIDATE_STATUSES.VETTED
            }
            return employee.employmentInformation.generalInfo.isVetted === vettedFilter;
        });
        return filteredEmployees;
    }
    return employees;
}

const getFilteredByAvailability = (employees, availabilityFilter) => availabilityFilter
    ? employees.filter(x => x.employmentInformation.generalInfo.isAvailable === availabilityFilter)
    : employees;

const getFilteredByExperience = (employees, experienceFilter) => {
    const filteredByExperience = employees.filter(employee => {
        if (!experienceFilter) {
            return true;
        }

        if (!employee.employmentInformation || !employee.employmentInformation.generalInfo) {
            return false;
        }

        const experienceSince = employee.employmentInformation.generalInfo.experienceSince;

        const experienceSinceInYears = moment.utc(Date.now()).diff(moment.utc(experienceSince), 'years');

        return experienceFilter.minValue <= experienceSinceInYears && experienceSinceInYears < experienceFilter.maxValue;
    });

    return filteredByExperience;
};

const getFilteredByLanguage = (employees, languageFilter) => {
    const filteredByLanguage = employees.filter(employee => {
        if (!languageFilter || languageFilter.length === 0) {
            return true;
        }

        if (!employee.languages || employee.languages.length === 0) {
            return false;
        }

        return languageFilter.every(language => employee.languages.some(x => x.name === language));
    });

    return filteredByLanguage;
};

const getFilteredByProfileType = (employees, profileTypeFilter) => {
    const filteredByProfileType = employees.filter(employee => {
        const employeeProfileType = employee.employmentInformation?.generalInfo?.profileType ? employee.employmentInformation?.generalInfo?.profileType : employee.profileType;

        if (!profileTypeFilter || profileTypeFilter === "") {
            return true;
        }

        if (!employeeProfileType || employeeProfileType.length === 0) {
            return false;
        }

        return employeeProfileType === profileTypeFilter;
    });

    return filteredByProfileType;
};

const getFilteredBySupplier = (employees, supplier) => {
    if (supplier) {
        const filteredBySupplier = employees.filter(x => x.supplierId === supplier);
        return filteredBySupplier;
    }

    return employees;
}

const getFilteredByCurrentLocation = (employees, currentLocationFilter) => (
    currentLocationFilter
        ? employees.filter(employee => employee?.contact?.currentLocation === currentLocationFilter)
        : employees
);

export const getSelectedEmployee = (state, _id) => {
    const { firstName, lastName } = getEmployees(state).find(e => e._id === _id) || getEmployeeBasicInfo(state) || { firstName: '', lastName: '' };
    return {
        _id,
        name: `${firstName} ${lastName}`
    }
};
export const getEmploymentBankDetails = state => state.employmentInformation.bankDetails;

export const getEmployeeContractInformation = state => state.employmentInformation.contractInformation;

export const getTrackedDays = state => state.trackedDays.map(trackedDay => ({
    start: moment(trackedDay.date, DATE_FORMAT_WITH_DASH),
    end: moment(trackedDay.date, DATE_FORMAT_WITH_DASH),
    project: trackedDay.project,
    hours: trackedDay.hours,
    tasksDesc: trackedDay.tasksDesc,
    fileInfo: trackedDay?.fileInfo
}));

export const getCurrentEmployeeHolidaysHistory = (state, page, count, filters = {}, date, sortBy, sortByDir) => {
    const from = page * count;
    const to = page * count + count;

    let holidays = state.holidays.filter(holiday => {
        const hasSignatureStatus = filters?.signatureStatus
            ? filters?.signatureStatus.includes(holiday.signatureStatus)
            : true;

        const hasStatus = (filters?.status ? filters?.status.includes(holiday.status) : true)
            && holiday.status !== HOLIDAY_APPROVAL_STATUSES.PENDING;

        const isInMonth = date
            ? (moment.utc(holiday.endDate).format(MONTH_YEAR_DATE_FORMAT) === date
                || moment.utc(holiday.startDate).format(MONTH_YEAR_DATE_FORMAT) === date
                || (moment.utc(holiday.endDate).isAfter(moment.utc(date, MONTH_YEAR_DATE_FORMAT))
                    && moment.utc(holiday.startDate).isBefore(moment.utc(date, MONTH_YEAR_DATE_FORMAT))))
            : true;

        return hasSignatureStatus && hasStatus && isInMonth;
    });

    if (sortBy && sortByDir) {
        holidays = holidays.sort((a, b) => employeeHolidaysComparer(a, b, sortBy, sortByDir))
    }

    return {
        holidays: holidays.slice(from || 0, to || holidays.length),
        totalCount: holidays.length
    }
};

export const getApprovedHolidays = state => state.holidays.filter(x => x.status !== HOLIDAY_APPROVAL_STATUSES.PENDING);

export const getPendingHistoryList = (state, page, count, sortBy, sortByDir) => {
    const from = page * count;
    const to = page * count + count;

    let pendingHolidays = state.holidays.filter(x => x.status === HOLIDAY_APPROVAL_STATUSES.PENDING);

    if (sortBy && sortByDir) {
        pendingHolidays = pendingHolidays.sort((a, b) => employeeHolidaysComparer(a, b, sortBy, sortByDir));
    }

    return pendingHolidays.slice(from, to || pendingHolidays.length);
};
export const getBookedHolidaysCount = state => {
    const currentYear = moment.utc().format('YYYY');
    const paidHolidayArticle = '155';
    return state.holidays
        .filter(x => x.status === 'approved' && x.type === 'Paid' && (x.byArticle === paidHolidayArticle || !x.byArticle))
        .reduce((totalBookedCount, request) => {
            return Number(moment.utc(request.startDate).format('YYYY')) >= Number(currentYear)
                ? totalBookedCount + request.numberOfDays
                : totalBookedCount;
        }, 0);
};
export const getBookedUnpaidHolidaysCount = state => {
    const currentYear = moment.utc().format('YYYY');
    return state.holidays
        .filter(x => x.status === 'approved' && x.type === 'Unpaid')
        .reduce((totalBookedUnpaidCount, request) => {
            return Number(moment.utc(request.startDate).format('YYYY')) >= Number(currentYear)
                ? totalBookedUnpaidCount + request.numberOfDays
                : totalBookedUnpaidCount;
        }, 0);
};

const tasksInputSelector = (state) => state.tasks;
const filtersInputSelector = (_, filters) => filters;
export const getEmployeeTasks = createSelector([tasksInputSelector, filtersInputSelector], (state, filters) => {
    let tasks = [];
    filters
        ? (tasks = state.filter((task) => {
            const hasOwner =
                filters.owner.value === defaultOwnerValue[0].value || filters.owner.name === task.owner.name;
            const hasType = filters.type.value === defaultTypeValue[0].value || filters.type.name === task.status;

            return hasOwner && hasType;
        }))
        : (tasks = state);

    return tasks;
});

export const getAllTags = state => {
    const allTags = [];

    state.employees.forEach(employee => {
        if (employee.profileSkills) {
            employee.profileSkills.forEach(category => {
                category.skills.forEach(skill => {
                    if (!allTags.some(x => x.name === skill.name)) {
                        allTags.push(skill);
                    }
                });
            });
        }
    });

    return allTags;
};

export const getAllLanguages = state => {
    const allLanguages = [];

    state.employees.forEach(employee => {
        if (employee.languages) {
            employee.languages.forEach(language => {
                if (!allLanguages.some(x => x.name === language.name)) {
                    allLanguages.push(language);
                }
            });
        }
    });

    return allLanguages;
};

export const getAllEmployeesCurrentLocations = state => {
    const allCurrentLocations = [];

    state.employees.forEach(employee => {
        if (employee.contact && employee.contact.currentLocation && !allCurrentLocations.includes(employee.contact.currentLocation)) {
            allCurrentLocations.push(employee.contact.currentLocation);
        }
    })

    return allCurrentLocations;
};

export const getNotifications = state => state.notifications;
export const getEmployeeProjects = (state, onlyCurrent) => fromEmployeeProjects.getEmployeeProjects(state.projects, onlyCurrent);
export const getEmployeeProject = (state, projectId) => fromEmployeeProjects.getEmployeeProject(state.projects, projectId);

export const getEmployeeProjectDocuments = (state, projectId, page, count) => {
    const project = state.projects.find((x) => x._id === projectId);
    const projectDocuments = project?.documents;

    if (!projectDocuments) {
        return {
            result: [],
            count: 0
        };
    }

    const allProjectDocuments = [...(projectDocuments.files || []), ...(projectDocuments.links || [])];

    const from = page * count;
    const to = page * count + count;

    return {
        result: allProjectDocuments.slice(from, to || allProjectDocuments.length),
        count: allProjectDocuments.length,
    }
};

export const getEmployeeImportantDocuments = state => state.importantDocuments;
export const getEmployeeProjectsWithClientInfo = (state, onlyCurrent) => fromEmployeeProjects.getEmployeeProjectsWithClientInfo(state.projects, onlyCurrent);
export const getEmployeePendingHolidaysCount = state => fromEmployeeHolidaysCount.getEmployeePendingHolidaysCount(state.pendingHolidaysCount);

export const getSimilarTalents = (state, filters, returnCount) => {
    const similarTalentsSpecs = getSimilarTalentMatchSpecs(filters);

    const results = [];
    for (const employee of state.employees) {
        for (const match of similarTalentsSpecs) {
            if (match.isArray && match.matchMultiple) {
                similarTalentMultipleFromArrayMatch(match, employee, filters, results);
            } else if (match.isArray && !match.isMultiple) {
                similarTalentSingleFromArrayMatch(match, employee, filters, results);
            } else {
                similarTalentSingleMatch(match, employee, filters, results);
            }
        }
    }

    const ordered = orderSimilarTalentsByMaxPoints(results);
    const result = ordered.slice(0, returnCount);

    return result;
}

export const getKnowYourTeamSample = (state) => state.knowYourTeamSample;
export const getEmployeeTeam = (state) => state.employeeTeam;
export const getRelatedOpportunities = (state) => state.relatedOpportunities;
export const getCandidatePreferences = (state) => state.candidatePreferences;
export const getCandidateJobType = (state) => state.candidatePreferences.jobType;
export const getCandidateAssignee = (state) => state.personalInformation.assignee;
export const getCandidateStatus = (state) => state.personalInformation.status;
export const getCandidateStatusHistory = (state) => state.personalInformation.statusHistory;
export const getCandidateCriteria = (state) => state.candidateCriteria;
export const getCandidateJourneyInfo = (state) => state.candidateJourneyInfo;
export const getCandidateProgressData = (state) => state.employmentInformation.progressData;

export const getCandidateInformation = (state) => {
    const { profilePictureImgUrl, firstName, lastName } = state.personalInformation;
    const { profileSummary } = state.employmentInformation.generalInfo;
    const { salaryValue, salaryCurrency, netSalaryValue, earliestDate, jobType, technologies } = state.candidatePreferences;

    return { profilePictureImgUrl, firstName, lastName, jobType, technologies, profileSummary, salaryValue, netSalaryValue, salaryCurrency, earliestDate };
};

export const getIsRawCvPdfGenerated = (state) => state.employmentInformation.isRawCvPdfGenerated;
export const hasUserBookedInterview = state => state.personalInformation.hasBookedInterview;
export const getFaceToFaceInterviewInformation = (state) => state.personalInformation.calendlyInterviewInfo;
export const getDeletedEmployees = (state) => state.employees.filter(x => x.isDeleted);
export const getHottestTalents = (state) => state.employees;

export const getEmployeeUploadedCv = (state) => state.personalInformation.uploadedCv;

export const getEmploymentType = (state) => state.employmentInformation.contractInformation.employmentType;

const profileInformationSelector = (state) => ({
    firstName: state.personalInformation.firstName,
    lastName: state.personalInformation.lastName,
    position: state.employmentInformation.generalInfo?.position,
    profileType: state.employmentInformation.generalInfo?.profileType,
    experienceSince: state.employmentInformation.generalInfo?.experienceSince,
    profilePictureImgUrl: state.personalInformation.profilePictureImgUrl,
    expectedSalaryValue: state.personalInformation.salaryValue,
    expectedSalaryCurrency: state.personalInformation.salaryCurrency,
    availability: state.personalInformation.availability,
});

const profileSummarySelector = (state) => state.employmentInformation.generalInfo?.profileSummary;;
const skillsSelector = (state) => state.profileSkills;
const preferredStackSelector = (state) => state.candidatePreferences?.technologies;
const educationSelector = (state) => state.educationList;
const certificatesSelector = (state) => state.certificates;
const projectsSelector = (state) => state.personalProjects.list;
const languagesSelector = (state) => state.languages;
const acceptedInterviewsSelector = (state) => state.acceptedInterviews;

export const getEmployeeCvInformation = createStructuredSelector({
    profileInformation: profileInformationSelector,
    profileSummary: profileSummarySelector,
    skills: skillsSelector,
    education: educationSelector,
    projects: projectsSelector,
    certificates: certificatesSelector,
    languages: languagesSelector,
    acceptedInterviews: acceptedInterviewsSelector,
    preferredStack: preferredStackSelector,
})

export const getProfileDetails = (state) => state.profileDetails;

export const getEmployeeEmail = (state) => state.personalInformation.email;

export const getEmployeeName = (state) => `${state.personalInformation.firstName} ${state.personalInformation.lastName}`;

export const getCandidateProfileSteps = (state) => state.candidateProfileSteps;

export const getChecklistedCandidates = (state, page, count, filters) => {
    const from = page * count;
    const to = page * count + count;

    const filteredChecklistedCandidates = state.checklistedCandidates.filter(candidate => {
        let isSearchMatch = true;

        if (filters?.searchTerm) {
            isSearchMatch = candidate.firstName?.toLowerCase().includes(filters.searchTerm.toLowerCase())
                || candidate.lastName?.toLowerCase().includes(filters.searchTerm.toLowerCase())
                || `${candidate.firstName?.toLowerCase()} ${candidate.lastName?.toLowerCase()}`.includes(filters.searchTerm.toLowerCase());
        }

        return isSearchMatch;
    });

    return {
        slicedFilteredChecklistedCandidates: filteredChecklistedCandidates.slice(from, to || filteredChecklistedCandidates.length),
        allFilteredChecklistedCandidates: filteredChecklistedCandidates,
        totalChecklistedCandidatesCount: filteredChecklistedCandidates.length,
    };
};

export const getCandidateApplicationProcessInformation = (state) => state.candidateApplicationProcessInformation;

export const getEmployeesTotalCount = (state) => state.employeesTotalCount;

export const getEmployeeOverviewInformation = (state) => state.overviewInformation;

export const getProfileCreationStepsCount = (state) => state.profileCreationSteps.length;
