export const CURRENCIES = [
    { VALUE: 1, LABEL: 'GBP' },
    { VALUE: 2, LABEL: 'USD' },
    { VALUE: 3, LABEL: 'EUR' },
    { VALUE: 4, LABEL: 'BGN' },
];

export const CANDIDATE_SALARY_CURRENCIES = [
    { VALUE: 1, LABEL: 'GBP' },
    { VALUE: 2, LABEL: 'USD' },
    { VALUE: 3, LABEL: 'EUR' },
    { VALUE: 4, LABEL: 'BGN' },
    { VALUE: 5, LABEL: 'TRY' },
    { VALUE: 6, LABEL: 'RON' },
    { VALUE: 7, LABEL: 'UAH' }
];

export const CANDIDATE_AVAILABILITIES = [
    { VALUE: 1, LABEL: 'Immediately' },
    { VALUE: 2, LABEL: '1 week' },
    { VALUE: 3, LABEL: '2 weeks' },
    { VALUE: 4, LABEL: '1 month' },
    { VALUE: 5, LABEL: '2 months' },
    { VALUE: 6, LABEL: '3+ months' }
];

export const defaultCurrencyEUR = 3;

export const CURRENCIES_SIGN = [
    { VALUE: 'GBP', LABEL: '£' },
    { VALUE: 'USD', LABEL: '$' },
    { VALUE: 'EUR', LABEL: '€' },
    { VALUE: 'BGN', LABEL: 'lv' },
];

export const CURRENCY_NAMES = {
    GBP: 'GBP',
    USD: 'USD',
    EUR: 'EUR',
    BGN: 'BGN',
};
