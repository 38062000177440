import { INTERVIEW_INVITATION_STATUSES } from 'constants/interviewInvitationConstants';


export const STEPS = {
    PENDING_ADMIN_REVIEW: 'PENDING_ADMIN_REVIEW',
    PENDING_CLIENT_REVIEW: 'PENDING_CLIENT_REVIEW',
    INVITED_TO_INTERVIEW: 'INVITED_TO_INTERVIEW',
    FINAL_DECISION: 'FINAL_DECISION',
};

export const APPLICATION_STATUS_TO_STEP_MAP = {
    [INTERVIEW_INVITATION_STATUSES.APPROVED]: (isInterviewPending, isInterviewConfirmed) =>  
        isInterviewPending && !isInterviewConfirmed ? STEPS.INVITED_TO_INTERVIEW : STEPS.FINAL_DECISION,
    [INTERVIEW_INVITATION_STATUSES.PENDING]: () => STEPS.INVITED_TO_INTERVIEW,
    [INTERVIEW_INVITATION_STATUSES.REJECTED]: () => STEPS.FINAL_DECISION,
};

export const STEP_TO_TITLE_MAP = {
    [STEPS.PENDING_ADMIN_REVIEW]: 'Your application is being reviewed',
    [STEPS.PENDING_CLIENT_REVIEW]: 'Your application is being reviewed by client',
    [STEPS.INVITED_TO_INTERVIEW]: 'Waiting for the interview',
    [STEPS.FINAL_DECISION]: "Waiting for client's response",
};
