import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorMessage } from 'formik';
import { MenuItem, TextField } from '@material-ui/core';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { CANDIDATE_AVAILABILITIES } from 'constants/currenciesConstant';

import './AvailableFromDialog.scss';

const AvailableFromDialog = ({
    onSubmit,
    handleChange,
    values,
    onClose,
    handleSubmit,
    open
}) => {
    const intl = useIntl();

    const onFormSubmit = () => {
        handleSubmit();
    }

    return (
        <form>
            <CommonFormikDialog
                open={open}
                onClose={onClose}
                className={"availability-dialog"}
                dialogTitle={intl.formatMessage({ id: "edit-availability" })}
                saveBtnText="save"
                discardBtnText="discard"
                onSave={onFormSubmit}
            >
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2 candidate-earliestDate">
                            <TextField
                                select
                                label="Select"
                                InputLabelProps={{ shrink: true }}
                                name="earliestDate"
                                id="earliestDate"
                                variant="outlined"
                                value={values.earliestDate}
                                onChange={handleChange}
                                fullWidth
                            >
                                {CANDIDATE_AVAILABILITIES.map((availability) => {
                                    return (
                                        <MenuItem key={availability.VALUE} value={availability.LABEL}>
                                            {availability.LABEL}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                            <ErrorMessage name="earliestDate" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                </div>
            </CommonFormikDialog>
        </form>
    );
}

export default AvailableFromDialog;
