import React from 'react';
import { useHistory } from 'react-router-dom';

import { Avatar } from '@material-ui/core';

import { getStateAgencyName } from 'utils/helpers';

import { ReactComponent as RightArrow } from 'assets/arrow-right.svg';
import defaultAvatar from 'assets/default-avatar.svg';

import './DashboardSavedCandidates.scss';

const DashboardSavedCandidates = ({
    candidatesInfo
}) => {
    const history = useHistory();

    return (
        <div className="dashboard-saved-candidates-wrapper">
            <div className="dashboard-saved-candidates-header">
                <p className="subheading-xxl color-dark-grey">Saved candidates: {candidatesInfo.length}</p>
                <p className="subheading-l color-deep-orange view-all-button" onClick={() => history.push(`/${getStateAgencyName()}/saved-talents`)}>View all <RightArrow /></p>
            </div>
            <div className="dashboard-saved-candidates-table-headers">
                <p className="subheading-xxs color-grey">Candidate:</p>
                <p className="subheading-xxs color-grey">Stack:</p>
                <p className="subheading-xxs color-grey">Score:</p>
                <p className="subheading-xxs color-grey"></p>
            </div>
            <div className="dashboard-saved-candidates-table-content">
                {candidatesInfo.map((candidateInfo) => (
                    <div className="dashboard-saved-candidates-table-content-row">
                        <div className="dashboard-save-candidates-table-content-candidate-details">
                            <Avatar src={candidateInfo.imgUrl || defaultAvatar} />
                            <div className="dashboard-save-candidates-table-content-candidate-details-content">
                                <p className="subheading-s color-dark-grey">{candidateInfo.firstName} {candidateInfo.lastName}</p>
                                <p className="subheading-xxs color-grey">{candidateInfo.position}</p>
                            </div>
                        </div>
                        <div className="dashboard-save-candidates-table-content-stack">
                            {candidateInfo.preferredTechStack?.map(x => (
                                <p className="stack-info-box subheading-s color-dark-grey">{x.title}</p>
                            ))}
                        </div>
                        <p className="subheading-xxl color-dark-grey">{candidateInfo.matchingScore}%</p>
                        <RightArrow className="three-dots-action" onClick={() => history.push(`/${getStateAgencyName()}/saved-talents`)} />
                    </div>
                ))}
            </div>
        </div>
    )
};

export default DashboardSavedCandidates;
