import React from 'react';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { ReactComponent as CoderyLogo } from 'assets/codery-logo-dark.svg';

import { ROLES } from 'constants/userConstants';

const DesktopPageWithoutJob = ({
    handleStartCandidateJourney,
    role
}) => (
    <div className={`start-candidate-journey-desktop-page ${role === ROLES.CLIENT ? "client-view" : ""}`}>
        <CoderyLogo />
        <div className="welcome-section">
            <p className="heading-l color-dark-grey">Welcome to the Codery family!</p>
            <p className="subheading-xl color-grey">Codery is Eastern Europe's developer-focused job platform. Create a profile and let companies apply to you.</p>
        </div>
        <div className="matching-section">
            {role === ROLES.CLIENT ?
                <p className="heading-s color color-dark-grey">Get matched with 20K+ vetted software developers!</p>
                :
                <p className="heading-s color color-dark-grey">Get matched with 100+ companies looking to hire in <span className="heading-s color color-white">5 minutes.</span></p>
            }
        </div>
        <div className="steps-section">
            <div className="step-card">
                {role === ROLES.CANDIDATE &&
                    <div className="card-heading">
                        <p className="heading-l color-dark-grey">01</p>
                        <div className="time-wrapper">1 min</div>
                    </div>
                }
                <div className="card-title heading-m color-dark-grey">{role === ROLES.CLIENT ? "Create searches for position needed" : "Complete your profile"}</div>
                <div className="card-content subheading-xl color-gray">It's your time to tell us what you want in your next role.</div>
            </div>
            <div className="step-card">
                {role === ROLES.CANDIDATE &&
                    <div className="card-heading">
                        <p className="heading-l color-dark-grey">02</p>
                        <div className="time-wrapper">1 min</div>
                    </div>
                }
                <p className="card-title heading-m color-dark-grey">{role === ROLES.CLIENT ? "Explore 5,000+ candidates" : "Setup your tech stack"}</p>
                <p className="card-content subheading-xl color-gray">Tell us what is your stack and what you want to code next.</p>
            </div>
            <div className="step-card">
                {role === ROLES.CANDIDATE &&
                    <div className="card-heading">
                        <p className="heading-l color-dark-grey">03</p>
                        <div className="time-wrapper">1 min</div>
                    </div>
                }
                <p className="card-title heading-m color-dark-grey">{role === ROLES.CLIENT ? "Schedule interviews" : "Upload your CV online"}</p>
                <p className="card-content subheading-xl color-gray">Upload your CV and book our screening with us.</p>
            </div>
            <div className="step-card">
                {role === ROLES.CANDIDATE &&
                    <div className="card-heading">
                        <p className="heading-l color-dark-grey">04</p>
                        <div className="time-wrapper">2 min</div>
                    </div>
                }
                <p className={`card-title heading-m ${role === ROLES.CANDIDATE ? "color-deep-green" : "color-dark-grey"}`}>{role === ROLES.CLIENT ? "Hire top matching talent!" : "GET HIRED!"}</p>
                <p className="card-content subheading-xl color-gray">Interview with companies that reach out for interviews!</p>
            </div>
        </div>
        <div className="buttons-wrapper">
            <PrimaryButton text="Next" className="dark" handleClick={handleStartCandidateJourney} />
        </div>
    </div >
);

export default DesktopPageWithoutJob;
