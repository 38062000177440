import React from 'react';

import './RoundedButton.scss';

const RoundedButton = ({
    icon,
    handleClick,
    className
}) => (
    <div className={`button-wrapper ${className ? className : ''}`} onClick={handleClick}>
        {icon}
    </div>
);

export default RoundedButton;
