import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux'
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import { showNotification } from 'actions/notificationActions';

import InterviewInviteFormView from './InterviewInviteFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { setTimeFromTimezone } from 'utils/formatTimes';
import {
    validateRequiredFields,
    validateArrayRequiredFields,
    validateTimeSlotsWithTimezone,
    validateArrayDateFields,
    validateArrayDiversDateFields
} from 'utils/formValidations';
import { convertStringToLanguageKey } from 'utils/languageUtils';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { CLIENT_MESSAGES } from 'constants/messageConstants';
import { MIN_INTERIVEW_TIME_SLOTS, INITIAL_INTERVIEW_TIME_SLOTS } from './InterviewInvitePopupConstants';

import './InterviewInvitePopup.scss';

const DEFAULT_TIMEZONE = moment.tz.names().find(tz => tz === 'Europe/Sofia');

const InterviewInvitePopup = ({
    isDialogOpen,
    dialogTitle,
    isWithNote,
    handleCloseDialog,
    showNotification,
    isLocationDisabled,
    handelInterviewInvitationSubmit,
    interviewInvitation,
    displayMode,
    handleCloseConfirmDialog,
}) => {
    const intl = useIntl();

    const handleAddInterviewTimeSlot = (values, setValues) => {
        const interviewTimeSlots = [...values.interviewTimeSlots, { date: null, time: null }];
        setValues({ ...values, interviewTimeSlots });
    };

    const requiredFields = isWithNote ? ['timezone', 'clientNote'] : ['timezone'];

    const initialValues = interviewInvitation && Object.keys(interviewInvitation).length > 0
        ? {...interviewInvitation, clientNote: interviewInvitation.note}
        : {
            clientNote: '',
            timezone: DEFAULT_TIMEZONE,
            interviewTimeSlots: INITIAL_INTERVIEW_TIME_SLOTS,
        }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const validations = {
                        ...validateRequiredFields(values, requiredFields),
                        ...validateTimeSlotsWithTimezone(values, 'timezone', 'interviewTimeSlots'),
                        ...validateArrayDateFields(values, 'interviewTimeSlots', ['date', 'time']),
                        ...validateArrayRequiredFields(values, 'interviewTimeSlots', ['date', 'time']),
                        ...validateArrayDiversDateFields(values, 'interviewTimeSlots', 'date', 'time'),
                    };

                    return validations;
                }}
                onSubmit={async (values) => {
                    const newInterviewTimeSlots = values.interviewTimeSlots.map(x => ({ ...x, time: setTimeFromTimezone(x.time, values.timezone) }));

                    if (values.interviewTimeSlots.length >= MIN_INTERIVEW_TIME_SLOTS) {
                        const formData = { ...values, interviewTimeSlots: newInterviewTimeSlots };
                        await handelInterviewInvitationSubmit(formData);
                        handleCloseDialog();
                        if (handleCloseConfirmDialog) {
                            handleCloseConfirmDialog();
                        }
                    } else {
                        showNotification(CLIENT_MESSAGES.INSUFFICIENT_TIME_SLOTS, NOTIFICATION_TYPES.WARNING);
                    }
                }}
                enableReinitialize={true}
            >
                {(props) => (
                    <CommonFormikDialog
                        className="interview-invite-dialog"
                        onSave={props.handleSubmit}
                        dialogTitle={intl.formatMessage({ id: convertStringToLanguageKey(dialogTitle) })}
                        open={isDialogOpen}
                        onClose={handleCloseDialog}
                        saveBtnText="save"
                        discardBtnText="discard"
                        size="md"
                    >
                        <InterviewInviteFormView
                            {...props}
                            displayMode={displayMode}
                            isLocationDisabled={isLocationDisabled}
                            isWithNote={isWithNote}
                            handleAddInterviewTimeSlot={handleAddInterviewTimeSlot}
                        />
                    </CommonFormikDialog>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    )
};

const mapDispatchToProps = {
    showNotification,
};

export default connect(null, mapDispatchToProps)(InterviewInvitePopup);
