import React from 'react';

import { FormControl, InputAdornment, InputBase, MenuItem, TextField } from '@material-ui/core';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { INVOICE_STATUSES_STRINGS } from 'components/Billing/BillingTable/BillingTableConstants';

import { ReactComponent as SearchIcon } from 'assets/icons-search.svg';
import { ReactComponent as FilterIcon } from 'assets/icons-filter.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons-download.svg';

const TableHeader = ({
    agencyId,
    userId,
    searchText,
    handleSearchTextChange,
    selectedOption,
    setSelectedOption,
    downloadInvoicesHandler,
}) => (
    <div className="billing-table-header">
        <InputBase
            className={"search-term-field"}
            autoComplete="off"
            name="searchTerm"
            value={searchText}
            onChange={handleSearchTextChange}
            startAdornment={(
                <InputAdornment position="start">
                    <SearchIcon className="search-icon icon" />
                </InputAdornment>
            )}
        />
        <div className="billing-table-actions">
            <div className="billing-table-filter-wrapper">
                <FormControl fullWidth>
                    <TextField
                        select
                        name="status"
                        variant="outlined"
                        value={selectedOption}
                        onChange={(event) => setSelectedOption(event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FilterIcon />
                                </InputAdornment>
                            )
                        }}
                    >
                        {Object.values(INVOICE_STATUSES_STRINGS).map(option => (
                            <MenuItem value={option}>{option}</MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </div>
            <PrimaryButton
                className="dark"
                text="Download all"
                icon={<DownloadIcon />}
                handleClick={() => downloadInvoicesHandler(agencyId, userId, "invoices")}
            />
        </div>
    </div>
);

export default TableHeader;
