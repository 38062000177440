import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getAgencyId, getUserId } from 'reducers';

import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import { Checkbox } from '@material-ui/core';

import { ReactComponent as Av1 } from 'assets/avatars/Av1.svg';
import { ReactComponent as Av2 } from 'assets/avatars/Av2.svg';
import { ReactComponent as Av3 } from 'assets/avatars/Av3.svg';
import { ReactComponent as Av4 } from 'assets/avatars/Av4.svg';
import { ReactComponent as Av5 } from 'assets/avatars/Av5.svg';
import { ReactComponent as Av6 } from 'assets/avatars/Av6.svg';
import { ReactComponent as Av7 } from 'assets/avatars/Av7.svg';
import { ReactComponent as Av8 } from 'assets/avatars/Av8.svg';
import { ReactComponent as Av9 } from 'assets/avatars/Av9.svg';
import { ReactComponent as Av10 } from 'assets/avatars/Av10.svg';
import { ReactComponent as Av11 } from 'assets/avatars/Av11.svg';
import { ReactComponent as Av12 } from 'assets/avatars/Av12.svg';
import { ReactComponent as Av13 } from 'assets/avatars/Av13.svg';
import { ReactComponent as Av14 } from 'assets/avatars/Av14.svg';
import { ReactComponent as Av15 } from 'assets/avatars/Av15.svg';
import { ReactComponent as Av16 } from 'assets/avatars/Av16.svg';
import { ReactComponent as Av17 } from 'assets/avatars/Av17.svg';
import { ReactComponent as Av18 } from 'assets/avatars/Av18.svg';
import { ReactComponent as Av19 } from 'assets/avatars/Av19.svg';
import { ReactComponent as Av20 } from 'assets/avatars/Av20.svg';

import { ReactComponent as UncheckedIcon } from 'assets/codery-checkbox-unchecked.svg'
import { ReactComponent as CheckedIcon } from 'assets/codery-checkbox-checked.svg'

import './ChooseAvatarSection.scss';

const avatars = [
    { Component: Av1, name: 'Av1.svg' },
    { Component: Av2, name: 'Av2.svg' },
    { Component: Av3, name: 'Av3.svg' },
    { Component: Av4, name: 'Av4.svg' },
    { Component: Av5, name: 'Av5.svg' },
    { Component: Av6, name: 'Av6.svg' },
    { Component: Av7, name: 'Av7.svg' },
    { Component: Av8, name: 'Av8.svg' },
    { Component: Av9, name: 'Av9.svg' },
    { Component: Av10, name: 'Av10.svg' },
    { Component: Av11, name: 'Av11.svg' },
    { Component: Av12, name: 'Av12.svg' },
    { Component: Av13, name: 'Av13.svg' },
    { Component: Av14, name: 'Av14.svg' },
    { Component: Av15, name: 'Av15.svg' },
    { Component: Av16, name: 'Av16.svg' },
    { Component: Av17, name: 'Av17.svg' },
    { Component: Av18, name: 'Av18.svg' },
    { Component: Av19, name: 'Av19.svg' },
    { Component: Av20, name: 'Av20.svg' },
];

const ChooseAvatarSection = ({
    agencyId,
    candidateId,
    handleContinueJourney,
    updateCandidateAvatar,
}) => {
    const [selectedAvatar, setSelectedAvatar] = useState(null);

    const handleNextButtonClick = () => {
        const action = updateCandidateAvatar.bind(null, agencyId, candidateId, avatars[selectedAvatar].name)
        handleContinueJourney(null, true, action);
    };

    return (
        <div className="choose-avatar-section-wrapper">
            <PaperElement classes={["choose-avatar-section-container", "sm"]}>
                <p className="heading-m color-drak-grey">Choose your profile avatar</p>
                <div className="choose-avatar-section-avatars-wrapper">
                    {
                        avatars.map((Avatar, index) => (
                            <div className="avatar-container" onClick={() => setSelectedAvatar(index)} key={index}>
                                <div className={`avatar-icon-container ${selectedAvatar === index ? "selected" : ""}`}>
                                    <Avatar.Component className="avatar" />
                                </div>
                                <Checkbox checked={selectedAvatar === index} onChange={() => setSelectedAvatar(index)} className="checkbox" icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
                            </div>
                        ))
                    }
                </div>
                <PrimaryButton className={`choose-avatar-btn purple ${selectedAvatar === null ? "disabled" : ""}`} text="Next" disabled={selectedAvatar === null} onClick={handleNextButtonClick} />
            </PaperElement>
        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    candidateId: getUserId(state),
})

export default connect(mapStateToProps)(ChooseAvatarSection);
