import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Avatar, InputAdornment, TextField } from '@material-ui/core';

import { ReactComponent as RightArrow } from 'assets/arrow-right.svg';
import { ReactComponent as CalenderIcon } from 'assets/calender-icon.svg';

import { getEarliestDatesForEachInterview, getInterviewsOnDate, sortInterviewsByDates } from './DashboardUpcomingInterviewsUtils';

import { getStateAgencyName } from 'utils/helpers';

import { INTERVIEW_INVITATION_STATUSES } from 'constants/interviewInvitationConstants';
import { CONTINENTAL_TIME_FORMAT } from 'constants/commonConstants';

import defaultAvatar from 'assets/default-avatar.svg';

import './DashboardUpcomingInterviews.scss';

const DashBoardUpcomingInterviews = ({
    interviews
}) => {
    const history = useHistory();
    const sortedInterviews = sortInterviewsByDates(interviews);
    const dateOptions = getEarliestDatesForEachInterview(sortedInterviews);

    const [selectedOption, setSelectedOption] = useState(dateOptions[0]);

    return (
        <div className="dashboard-upcoming-interviews-wrapper">
            <div className="dashboard-upcoming-interviews-header">
                <p className="subheading-xxl color-dark-grey">Interviews: {sortedInterviews.length}</p>
                <p className="dashboard-upcoming-interviews-view-all-btn subheading-l color-deep-orange" onClick={() => history.push(`/${getStateAgencyName()}/interviews`)}>View all <RightArrow /></p>
            </div>
            <div className="dashboard-upcoming-interviews-filter-wrapper">
                <FormControl fullWidth>
                    <TextField
                        select
                        name="date"
                        variant="outlined"
                        margin="normal"
                        value={selectedOption}
                        onChange={(event) => setSelectedOption(event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalenderIcon />
                                </InputAdornment>
                            )
                        }}
                    >
                        {dateOptions.map(option => (
                            <MenuItem value={option}>{option}</MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </div>
            <div className="dashboard-upcoming-interviews-container">
                {getInterviewsOnDate(sortedInterviews, selectedOption).map(interview => (
                    <div className="dashboard-upcoming-interviews-interview-container">
                        <div className="dashboard-upcoming-interviews-interview-candidate-info">
                            <Avatar src={interview.candidateInfo.imgUrl || defaultAvatar} />
                            <div className="dashboard-upcoming-interviews-candidate-details">
                                <p className="subheading-s color-dark-grey">{interview.candidateInfo.firstName} {interview.candidateInfo.lastName}</p>
                                <p className="subheading-xxs color-grey">{interview.candidateInfo.position}</p>
                            </div>
                        </div>
                        <div className="dashboard-upcoming-interviews-interview-dates-info" onClick={() => history.push(`/${getStateAgencyName()}/interviews`)}>
                            <p className="subheading-l color-grey">{interview.status !== INTERVIEW_INVITATION_STATUSES.APPROVED ? "Pending" : `${moment(interview.interviewDate.time).format(CONTINENTAL_TIME_FORMAT)}-${moment(interview.interviewDate.time).add(1, 'hour').format(CONTINENTAL_TIME_FORMAT)}`}</p>
                            <RightArrow />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default DashBoardUpcomingInterviews;
