import moment from 'moment';

import { INTERVIEW_INVITATION_STATUSES } from "constants/interviewInvitationConstants";

export const getInterviewStatus = (interview) => {
    if (interview.status === INTERVIEW_INVITATION_STATUSES.APPROVED) {
        return moment(interview.interviewDate.date).isBefore(moment()) ? "Completed" : "Upcoming";
    }

    return interview.interviewTimeSlots.every(x => moment(x.date).isBefore(moment())) ? "Completed" : "Upcoming"
};

export const getShouldBlurCVInformation = (isAdmin, jobSearchId, acceptedInterviews) => {
    const interviewsForCurrentJobSearch = acceptedInterviews.filter(x => x.jobSearchId === jobSearchId);

    // No blur for admins
    if (isAdmin) {
        return false;
    }

    // Always blur if no interviews are accepted
    if (interviewsForCurrentJobSearch.length === 0) {
        return true;
    }

    if (interviewsForCurrentJobSearch.length >= 1) {
        const lastInterview = interviewsForCurrentJobSearch[interviewsForCurrentJobSearch.length - 1];

        const threeMonthsAfterInterview = moment(lastInterview.interviewDate.date).add(3, 'months');
        const today = moment();

        // If three months are passed blur the information
        if (today.isAfter(threeMonthsAfterInterview)) {
            return true;
        }

        // Otherwise, show the info
        return false;
    }

    return true;
};
