import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import Paper from '@material-ui/core/Paper';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import AgencyMatchingSettingsFormik from './AgencyMatchingSettingsFormik';

import { validateRequiredFields } from 'utils/formValidations';

import './AgencyMatchingSettings.scss';

const AgencyMatchingSettings = ({
    agencyMatchingSettings,
    updateAction,
}) => {
    const intl = useIntl();
    const formRef = useRef();

    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const handleShowHeaderButtons = () => setShowHeaderButtons(true);

    const discardHandler = () => {
        setShowHeaderButtons(false);
        formRef.current.resetForm();
    };

    return (
        <div className="agency-matching-settings-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "ai-matching-settings" })}
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "discard" })}
                            onClick={discardHandler}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "save" })}
                            onClick={() => {
                                formRef.current.handleSubmit();
                                setShowHeaderButtons(false);
                            }}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>
            <Paper className="agency-matching-settings-content">
                <Formik
                    initialValues={{
                        useAiMatching: agencyMatchingSettings?.useAiMatching || false,
                        Skills: agencyMatchingSettings?.Skills || 0,
                        JobTitles: agencyMatchingSettings?.JobTitles || 0,
                        Industries: agencyMatchingSettings?.Industries || 0,
                        ManagementLevel: agencyMatchingSettings?.ManagementLevel || 0,
                        Education: agencyMatchingSettings?.Education || 0,
                        Languages: agencyMatchingSettings?.Languages || 0,
                        Certifications: agencyMatchingSettings?.Certifications || 0,
                        ExecutiveType: agencyMatchingSettings?.ExecutiveType || 0,
                    }}
                    innerRef={formRef}
                    validate={values => {
                        if (values.useAiMatching) {
                            return {
                                ...validateRequiredFields(values, ['Skills', 'JobTitles', 'Industries', 'ManagementLevel', 'Education', 'Languages', 'Certifications', 'ExecutiveType']),
                            };
                        }

                        return {};
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);

                        const { useAiMatching, ...restValues } = values;

                        const mappedValues = Object.fromEntries(Object.entries(restValues).map(([key, value]) => [key, Number(value)]));

                        updateAction({useAiMatching, aiMatchingSettings: {...mappedValues}}, resetForm);
                    }}
                    enableReinitialize={true}
                >
                    {(props) =>
                        <AgencyMatchingSettingsFormik
                            handleShowHeaderButtons={handleShowHeaderButtons}
                            {...props}
                        />
                    }
                </Formik>
            </Paper>
        </div>
    )
};

export default AgencyMatchingSettings;
