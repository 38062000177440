import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { ROLES } from 'constants/userConstants';

export const APPLIED_CANDIDATES_TITLE = {
    [ROLES.ADMIN]: 'Applied Candidates',
    [ROLES.SUPPLIER_ADMIN]: 'Applied Candidates',
    [ROLES.CLIENT]: 'Applicant Pipeline',
};

export const FILTER_TABS = {
    [ROLES.ADMIN]: [
        { name: 'All', value: 'all' },
        { name: 'Not Vetted', value: 'notVetted' },
        { name: 'Pending Review', value: 'pendingReview' },
        { name: 'Interview Stage', value: 'interviewStage' },
        { name: 'Hired', value: 'hired' },
        { name: 'Rejected', value: 'rejected' },
    ],
    [ROLES.SUPPLIER_ADMIN]: [
        { name: 'All', value: 'all' },
        { name: 'Not Vetted', value: 'notVetted' },
        { name: 'Pending Review', value: 'pendingReview' },
        { name: 'Interview Stage', value: 'interviewStage' },
        { name: 'Hired', value: 'hired' },
        { name: 'Rejected', value: 'rejected' },
    ],
    [ROLES.CLIENT]: [
        { name: 'All', value: 'all' },
        { name: 'Pending Review', value: 'pendingReview' },
        { name: 'Interview Stage', value: 'interviewStage' },
        { name: 'Hired', value: 'hired' },
        { name: 'Rejected', value: 'rejected' },
    ],
};

export const APPLIED_CANDIDATE_STATUSES = {
    ALL: 'All',
    NOT_VETTED: 'Not Vetted',
    PENDING_REVIEW: 'Pending Review',
    PENDING_ADMIN_REVIEW: 'Pending Admin Review',
    INTERVIEW_STAGE: 'Invited',
    HIRED: 'Hired',
    REJECTED: 'Rejected',
}

export const SORT_DATE = 'Date';
export const SORT_SCORE = 'Score';
export const SORT_VALUES = [SORT_DATE, SORT_SCORE]
export const DEFAULT_SORT = SORT_DATE;

export const FILTER_TAB_ALL = "All";
export const FILTER_TABS_PENDING_REVIEW = "Pending Review";
export const FILTER_TABS_INTERVIEW_STAGE = "Interview Stage";
export const FILTER_TABS_HIRED = "Hired";
export const FILTER_TABS_REJECTED = "Rejected";

export const DEFAULT_FILTERS = {
    status: FILTER_TAB_ALL,
    sortBy: SORT_DATE,
    searchTerm: '',
}

export const CANDIDATE_STATUS_MAPPER = {
    [APPLICATION_STATUSES.ACCEPTED_ANOTHER_OFFER]: APPLIED_CANDIDATE_STATUSES.REJECTED,
    [APPLICATION_STATUSES.NEW_APPLICATION]: APPLIED_CANDIDATE_STATUSES.PENDING_REVIEW,
    [APPLICATION_STATUSES.PENDING_ADMIN_REVIEW]: APPLIED_CANDIDATE_STATUSES.PENDING_ADMIN_REVIEW,
    [APPLICATION_STATUSES.NON_VETTED_CANDIDATE]: APPLIED_CANDIDATE_STATUSES.NOT_VETTED,
    [APPLICATION_STATUSES.INVITED_FOR_INTERVIEW]: APPLIED_CANDIDATE_STATUSES.INTERVIEW_STAGE,
    [APPLICATION_STATUSES.REJECTED]: APPLIED_CANDIDATE_STATUSES.REJECTED,
    [APPLICATION_STATUSES.APPROVED]: APPLIED_CANDIDATE_STATUSES.HIRED,
    [APPLICATION_STATUSES.ACCEPTED_INTERVIEW]: APPLIED_CANDIDATE_STATUSES.INTERVIEW_STAGE,
    [APPLICATION_STATUSES.AWAITING_INTERVIEW_FEEDBACK]: APPLIED_CANDIDATE_STATUSES.INTERVIEW_STAGE,
}
