import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchSupplierEmployeesProjects } from 'actions/supplierActions';
import { getClientProjects } from 'reducers';

import Section from 'components/Shared/Section';

import './ProjectsOverviewSupplier.scss';

const ProjectsOverviewSupplier = ({
    agencyId,
    userId,
    fetchSupplierEmployeesProjects,
    projects,
}) => {
    useEffect(() => {
        fetchSupplierEmployeesProjects(agencyId, userId);
    }, []);

    return (
        <div className="projects-overview-container">
            <Section title="Engagement">
                <div className="project-boxes-wrapper">
                </div>
            </Section>
        </div>
    )
}

const mapStateToProps = state => ({
    projects: getClientProjects(state),
});

const mapDispatchToProps = {
    fetchSupplierEmployeesProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsOverviewSupplier);
