import React from 'react';
import { connect } from 'react-redux';

import { getClientMenuInformation } from 'reducers';

import { Paper } from '@material-ui/core';

import { ReactComponent as AccountManagerIcon } from 'assets/icon-account-manager.svg';
import { ReactComponent as ContactNumberIcon } from 'assets/icon-contact-number.svg';
import { ReactComponent as ContactEmailIcon } from 'assets/icon-contact-email.svg';

import './ClientYourAccountManagerInformation.scss';

const ClientYourAccountManagerInformation = ({
    accountManager,
    contactNumber,
    contactEmail,
    companyName,
}) => {
    return (
        <div className="client-your-account-manager-information-wrapper">
            <div className="client-your-account-manager-information-items">
                <p className="heading-s color-dark-grey">Account Manager</p>
                <p className="subheading-l color-grey">{companyName}</p>
            </div>
            <hr className="custom-divider" />
            <Paper className="client-your-account-manager-content">
                <div className="information-section">
                    <p className="subheading-xxs color-grey">Account Manager</p>
                    <div className="icon-text-wrapper">
                        <AccountManagerIcon className="icon" />
                        <span className="subheading-s color-dark-grey">{accountManager}</span>
                    </div>
                </div>
                <div className="information-section">
                    <p className="subheading-xxs color-grey">Contact Number</p>
                    <div className="icon-text-wrapper">
                        <ContactNumberIcon className="icon" />
                        <span className="subheading-s color-dark-grey">{contactNumber ? `+${contactNumber}` : 'TBC'}</span>
                    </div>
                </div>
                <div className="information-section">
                    <p className="subheading-xxs color-grey">Contact Email</p>
                    <div className="icon-text-wrapper">
                        <ContactEmailIcon className="icon" />
                        <span className="subheading-s color-dark-grey">{contactEmail || 'TBC'}</span>
                    </div>
                </div>
            </Paper>
        </div>
    );
};

const mapStateToProps = (state) => ({
    companyName: getClientMenuInformation(state).name
});

export default connect(mapStateToProps, null)(ClientYourAccountManagerInformation);
