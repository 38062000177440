import React from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';

import {
    validateCountryOfTaxResidence,
    validateRequiredFields,
    validateInputForPositiveIntegerType,
    validateConditionalField,
} from 'utils/formValidations';
import { SUPPORTED_COUNTRIES_LIST, COUNTRY_OF_TAX_OTHER } from 'constants/candidateConstants';
import { CANDIDATE_AVAILABILITIES } from 'constants/currenciesConstant';

const CandidateJourneyPreferencesFormik = ({
    candidatePreferences,
    updatePreferences,
    handleGoToNextStep,
    children,
}) => {
    return (
        <Formik
            initialValues={{
                netSalaryValue: candidatePreferences?.netSalaryValue || '',
                salaryCurrency: candidatePreferences?.salaryCurrency || '',
                countryOfTax: candidatePreferences?.countryOfTax && !SUPPORTED_COUNTRIES_LIST.includes(candidatePreferences?.countryOfTax)
                    ? COUNTRY_OF_TAX_OTHER
                    : candidatePreferences?.countryOfTax || '',
                otherCountryOfTax: candidatePreferences?.countryOfTax && !SUPPORTED_COUNTRIES_LIST.includes(candidatePreferences?.countryOfTax)
                    ? candidatePreferences?.countryOfTax
                    : '',
                earliestDate: candidatePreferences?.earliestDate ? candidatePreferences.earliestDate : CANDIDATE_AVAILABILITIES[0].LABEL,
                isEarliestDateUnknown: candidatePreferences?.isEarliestDateUnknown || false,
            }}
            validate={(values) => {
                const requiredFields = values.isEarliestDateUnknown
                    ? ['netSalaryValue', 'salaryCurrency', 'countryOfTax']
                    : ['netSalaryValue', 'salaryCurrency', 'countryOfTax', 'earliestDate'];
                return {
                    ...validateCountryOfTaxResidence(values, 'countryOfTax'),
                    ...validateRequiredFields(values, requiredFields),
                    ...validateInputForPositiveIntegerType(values, ['netSalaryValue']),
                    ...validateConditionalField(values, 'countryOfTax', COUNTRY_OF_TAX_OTHER, 'otherCountryOfTax')
                };
            }}
            onSubmit={(values, { resetForm }) => {
                const preferencesData = {
                    ...candidatePreferences,
                    netSalaryValue: values.netSalaryValue,
                    salaryCurrency: values.salaryCurrency,
                    countryOfTax: values.countryOfTax === COUNTRY_OF_TAX_OTHER ? values.otherCountryOfTax : values.countryOfTax,
                    earliestDate: values.earliestDate,
                };

                const isDirty = !isEqual(candidatePreferences, preferencesData);
                const action = updatePreferences.bind(null, preferencesData, resetForm);
                //here for testing purposes we pass null as a first argument 
                //there is a full description in the CandidateProfileJourney.js file 
                handleGoToNextStep(null, isDirty, action);
            }}
            enableReinitialize={true}
        >
            {children}
        </Formik>
    );
};

export default CandidateJourneyPreferencesFormik;
