import React from 'react';

import './DashboardNoItemsCard.scss';

const DashboardNoItemsCard = ({
    title,
    icon,
    contentText
}) => (
    <div className="no-items-card-wrapper">
        <p className="subheading-xxl color-dark-grey">{title}: 0</p>
        <div className="no-items-content">
            {icon}
            <p className="subheading-l color-grey">{contentText}</p>
        </div>
    </div>
);

export default DashboardNoItemsCard;
