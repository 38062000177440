import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchJobSearchesLikedCandidates } from 'actions/jobSearchActions';

import { getClientId, getClientMenuInformation, getJobSearchesLikedCandidates } from 'reducers';

import { CircularProgress, InputAdornment, InputBase } from '@material-ui/core';

import JobSearchSavedCandidates from 'components/JobSearchDetails/JobSearchSavedCandidates';

import { ReactComponent as SearchIcon } from 'assets/icons-search.svg';

import './SavedTalents.scss';

const SavedTalents = ({
    agencyId,
    clientId,
    companyName,
    likedCandidates,
    fetchJobSearchesLikedCandidates,
}) => {
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const handleSearchTextChange = (event) => setSearchText(event.target.value); 

    const filteredLikedCandidates = (likedCandidates) => {
        return likedCandidates.filter(x => x.jobSearchPosition.toLowerCase().includes(searchText.toLowerCase()));
    };

    useEffect(() => {
        setIsPageLoading(true);
        fetchJobSearchesLikedCandidates(agencyId, clientId).then(() => setIsPageLoading(false));
    }, []);

    return (
        <div className="saved-talents-wrapper">
            <div className="saved-talents-heading">
                <div className="saved-talents-header">
                    <p className="heading-s color-grey-dark">Saved Talents</p>
                    <p className="subheading-l color-grey">{companyName}</p>
                </div>
                <div className="search-field">
                    <InputBase
                        className={"search-term-field"}
                        autoComplete="off"
                        name="searchTerm"
                        placeholder="Search by job position"
                        value={searchText}
                        onChange={handleSearchTextChange}
                        startAdornment={(
                            <InputAdornment position="start">
                                <SearchIcon className="search-icon icon" />
                            </InputAdornment>
                        )}
                    />
                </div>
            </div>
            <hr className="custom-divider" />
            {isPageLoading ? <CircularProgress size={50} className="page-loader deep-orange" /> : <JobSearchSavedCandidates likedCandidates={filteredLikedCandidates(likedCandidates)} isSavedTalentsPage />}
        </div>
    )
};

const mapStateToProps = (state) => ({
    companyName: getClientMenuInformation(state).name,
    clientId: getClientId(state),
    likedCandidates: getJobSearchesLikedCandidates(state),
});

const mapDispatchToProps = {
    fetchJobSearchesLikedCandidates,
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedTalents);
