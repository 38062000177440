import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAgencyId, getClientId, getClientMenuInformation, getDisplayMode, getJobSearches } from 'reducers';

import { createJobSearch, fetchJobSearches, searchMatchingCandidates } from 'actions/jobSearchActions';

import { InputAdornment, InputBase } from '@material-ui/core';

import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import IdealCandidateFormik from 'components/Dashboard/IdealCandidateFormik';
import JobDialogFormik from 'components/Dashboard/JobDialogFormilk/JobDialogFormik';
import SelectFilterList from 'components/Shared/SelectFilterList';
import Pagination from 'components/Shared/Pagination';
import JobSearch from './JobSearch';

import { ReactComponent as SearchIcon } from 'assets/icons-search.svg';
import { ReactComponent as PlusIcon } from 'assets/plus-icon.svg';

import { JOB_OPPORTUNITIES_STATUSES } from 'constants/jobOpportunitiesConstants';
import { ROLES } from 'constants/userConstants';
import { parseQueryString } from 'utils/helpers';

import './JobSearches.scss';

const ITEMS_PER_PAGE = 5;

const JobSearches = ({
    agencyId,
    clientId,
    isAdmin,
    clientName,
    jobSearches,
    fetchJobSearches,
    createJobSearch,
    searchMatchingCandidates,
}) => {
    const history = useHistory();

    const { jobSearchId } = parseQueryString(history.location.search);

    const [isLoadingCandidateSearch, setIsLoadingCandidateSearch] = useState(false);

    const [isIdealCandidateDialogOpen, setIsIdealCandidateDialogOpen] = useState(false);
    const [idealCandidateFormValues, setIdealCandidateFormValues] = useState(null);
    const [statusFilterValue, setStatusFilterValue] = useState(JOB_OPPORTUNITIES_STATUSES.OPEN);
    const [searchText, setSearchText] = useState("");

    const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(Math.ceil(jobSearches.filter(x => x.status === statusFilterValue).length / ITEMS_PER_PAGE) === 0 ? 1 : Math.ceil(jobSearches.filter(x => x.status === statusFilterValue).length / ITEMS_PER_PAGE));

    const handleOpenIdealDialog = () => setIsIdealCandidateDialogOpen(true);
    const handleCloseIdealDialog = () => setIsIdealCandidateDialogOpen(false);

    const handleOpenJobDialog = () => setIsJobDialogOpen(true);
    const handleCloseJobDialog = () => setIsJobDialogOpen(false);

    const handleFilterChange = (_, value) => {
        setStatusFilterValue(value);
        setPage(1);
        setTotalPages(Math.ceil(jobSearches.filter(x => x.status === value).length / ITEMS_PER_PAGE) === 0 ? 1 : Math.ceil(jobSearches.filter(x => x.status === value).length / ITEMS_PER_PAGE));
    };

    const getPagedJobs = (jobSearches) => {
        const startIndex = (page - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return jobSearches.slice(startIndex, endIndex);
    };

    const getFilterJobs = () => jobSearches.filter(x => x.status === statusFilterValue && x.clientInfo?.name?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));

    const handleSaveIdealCandidate = (values) => {
        setIdealCandidateFormValues(values);
        handleCloseIdealDialog();
        handleOpenJobDialog();
    };

    const handlePreviousButtonClick = () => {
        handleCloseJobDialog();
        handleOpenIdealDialog();
    };

    const handleSearchTextChange = (event) => setSearchText(event.target.value);

    const handleJobDialogFormSubmit = async (values) => {
        const jobSearchData = { ...values, ...idealCandidateFormValues, clientId, agencyId };

        const formData = new FormData();

        if (values.jobImgUrl) {
            formData.append('jobImgUrl', values.jobImgUrl);
        } else {
            formData.append('jobImgUrl', null);
        }

        formData.append('jobSearchData', JSON.stringify({ ...jobSearchData }));

        await createJobSearch(agencyId, clientId, formData);

        handleCloseJobDialog();
    };

    const jobStatusChangeCallback = () => {
        setPage(1);
    };

    useEffect(() => {
        setTotalPages(Math.ceil(jobSearches.filter(x => x.status === statusFilterValue).length / ITEMS_PER_PAGE) === 0 ? 1 : Math.ceil(jobSearches.filter(x => x.status === statusFilterValue).length / ITEMS_PER_PAGE));
    }, [page])

    useEffect(() => {
        if (isAdmin) {
            fetchJobSearches(agencyId, undefined);
        } else {
            fetchJobSearches(agencyId, clientId);
        }
    }, []);

    useEffect(() => {
        if (jobSearchId) {
            const jobSearchClientId = history.location.state?.clientId;
            setIsLoadingCandidateSearch(true);
            searchMatchingCandidates(agencyId, jobSearchClientId || clientId, jobSearchId).then(() => setIsLoadingCandidateSearch(false));
        }
    }, [history.location.search]);

    return (
        <div className="job-searches-wrapper">
            <div className="job-searches-header">
                <div className="job-searches-title-section">
                    <p className="heading-s color-dark-grey">Talent Searches</p>
                    {!isAdmin && <p className="subheading-l color-grey">{clientName}</p>}
                    {isAdmin &&
                        <div className="search-field">
                            <InputBase
                                className={"search-term-field"}
                                autoComplete="off"
                                name="searchTerm"
                                placeholder="Search by client name"
                                value={searchText}
                                onChange={handleSearchTextChange}
                                startAdornment={(
                                    <InputAdornment position="start">
                                        <SearchIcon className="search-icon icon" />
                                    </InputAdornment>
                                )}
                            />
                        </div>
                    }
                </div>
                <div className="job-searches-actions">
                    <SelectFilterList
                        name="status"
                        value={statusFilterValue}
                        title={statusFilterValue}
                        items={Object.values(JOB_OPPORTUNITIES_STATUSES)}
                        handleChange={handleFilterChange}
                        className="client-view"
                        closeMenuOnSelect
                    />
                    {!isAdmin && <RoundedButton icon={<PlusIcon />} handleClick={handleOpenIdealDialog} />}
                </div>
            </div>
            <hr className="custom-divider" />
            <div className="job-searches-container">
                {getPagedJobs(getFilterJobs(jobSearches)).map(jobSearch => (
                    <JobSearch
                        jobSearch={jobSearch}
                        isLoadingCandidateSearch={isLoadingCandidateSearch}
                        jobSearchId={jobSearchId}
                        jobStatusChangeCallback={jobStatusChangeCallback}
                        isAdmin={isAdmin}
                    />
                ))}
                <Pagination page={page} setPage={setPage} totalPages={totalPages} />
            </div>

            <IdealCandidateFormik
                handleSubmit={handleSaveIdealCandidate}
                isIdealCandidateDialogOpen={isIdealCandidateDialogOpen}
                handleCloseIdealDialog={handleCloseIdealDialog}
                initialValues={idealCandidateFormValues}
            />

            <JobDialogFormik
                handleSubmit={handleJobDialogFormSubmit}
                isJobDialogOpen={isJobDialogOpen}
                handleCloseJobDialog={handleCloseJobDialog}
                handlePreviousButtonClick={handlePreviousButtonClick}
            />
        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    clientId: getClientId(state),
    clientName: getClientMenuInformation(state).name,
    jobSearches: getJobSearches(state),
    isAdmin: getDisplayMode(state) === ROLES.ADMIN
});

const mapDispatchToProps = {
    fetchJobSearches,
    createJobSearch,
    searchMatchingCandidates,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobSearches);
