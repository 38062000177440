import React, { Fragment } from 'react';

import { ReactComponent as UpArrow } from 'assets/arrow-up-full.svg';
import { ReactComponent as DownArrow } from 'assets/arrow-down.svg';
import { ReactComponent as RightArrow } from 'assets/arrow-right.svg';

import './DashboardInfoCard.scss';

const DashboardInfoCard = ({
    styleClass,
    isEmpty,
    icon,
    percentage,
    isPercentageIncreased,
    contentText,
    footerText,
    headerText,
    contentStyle,
    footerActionBtn,
    handleViewAllClick,
}) => (
    <div className={`info-card-wrapper ${isEmpty ? 'empty-card' : styleClass}`}>
        <div className="info-card-header">
            {icon &&
                <div className="icon-wrapper">
                    {icon}
                </div>
            }
            {headerText && <p className="heading-s">{headerText}</p>}
            {percentage &&
                <div className="percentage-wrapper">
                    {isPercentageIncreased ? <UpArrow /> : <DownArrow />}
                    <p className="subheading-l">{percentage}%</p>
                </div>
            }
        </div>
        <div className="info-card-content">
            <p className={`heading-l ${contentStyle || ''}`}>{contentText}</p>
        </div>
        <div className="info-card-footer">
            <p className="subheading-l">{footerText}</p>
            {!isEmpty &&
                <Fragment>
                    {footerActionBtn ? footerActionBtn : <div className="subheading-l view-all-text" onClick={handleViewAllClick}>View all <RightArrow /></div>}
                </Fragment>
            }
        </div>
    </div>
);

export default DashboardInfoCard;
