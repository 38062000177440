import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchInvoices, downloadInvoiceFiles, addInvoice, downloadInvoicesFiles } from 'actions/invoiceActions';
import { getAgencyId, getClientId, getClientMenuInformation, getInvoices, getUserId } from 'reducers';

import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import Pagination from 'components/Shared/Pagination';
import AddInvoiceDialog from 'components/Billing/AddInvoiceDialog';
import TableHeader from './TableHeader';
import TableContent from './TableContent';

import { INVOICE_STATUSES_STRINGS } from 'components/Billing/BillingTable/BillingTableConstants';

import { ReactComponent as PlusIcon } from 'assets/plus-icon.svg';

import './ClientBilling.scss';

const ITEMS_PER_PAGE = 6;

const ClientBilling = ({
    userId,
    agencyId,
    clientId,
    companyName,
    downloadInvoiceFiles,
    downloadInvoicesFiles,
    fetchInvoices,
    addInvoice,
    invoices,
}) => {
    const [searchText, setSearchText] = useState("");
    const [selectedOption, setSelectedOption] = useState(INVOICE_STATUSES_STRINGS.ALL);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(Math.ceil(invoices.length / ITEMS_PER_PAGE) === 0 ? 1 : Math.ceil(invoices.length / ITEMS_PER_PAGE));
    const [isAddInvoiceDialogOpen, setIsAddInvoiceDialogOpen] = useState(false);

    const handleCloseAddInvoiceDialog = () => setIsAddInvoiceDialogOpen(false);

    const handleOpenAddInvoiceDialog = () => setIsAddInvoiceDialogOpen(true);

    const handleSearchTextChange = (event) => setSearchText(event.target.value);

    const getFilteredItems = () => {
        return invoices.filter(x => x.projectName.toLowerCase().includes(searchText.toLowerCase()) && (x.status === selectedOption || selectedOption === INVOICE_STATUSES_STRINGS.ALL));
    };

    const getFilteredItemsForPage = () => {
        const start = (currentPage - 1) * ITEMS_PER_PAGE;
        const end = start + ITEMS_PER_PAGE;
        
        const filteredItems = getFilteredItems();
        
        return filteredItems.slice(start, end);
    };

    useEffect(() => {
        fetchInvoices(agencyId, clientId, null, true);
    }, []);

    useEffect(() => {
        setTotalPages(Math.ceil(getFilteredItems().length / ITEMS_PER_PAGE) === 0 ? 1 : Math.ceil(getFilteredItems().length / ITEMS_PER_PAGE));
        setCurrentPage(1);
    }, [selectedOption, searchText]);

    return (
        <div className="billing-wrapper">
            <div className="billing-header">
                <div className="billing-text-items">
                    <p className="heading-s color-dark-grey">Billing</p>
                    <p className="subheading-l color-grey">{companyName}</p>
                </div>
                <RoundedButton icon={<PlusIcon />} handleClick={handleOpenAddInvoiceDialog} />
            </div>
            <hr className="custom-divider" />
            <div className="billing-content">
                <p className="heading-xs color-dark-grey">Invoices History</p>
                <div className="billing-table-wrapper">
                    <TableHeader
                        searchText={searchText}
                        handleSearchTextChange={handleSearchTextChange}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        agencyId={agencyId}
                        userId={clientId}
                        downloadInvoicesHandler={downloadInvoicesFiles}
                    />
                    <TableContent
                        invoices={getFilteredItemsForPage()}
                        downloadInvoiceHandler={downloadInvoiceFiles}
                        agencyId={agencyId}
                        userId={userId}
                    />
                </div>
            </div>
            <Pagination page={currentPage} setPage={setCurrentPage} totalPages={totalPages} />
            {isAddInvoiceDialogOpen &&
                <AddInvoiceDialog
                    userId={clientId}
                    agencyId={agencyId}
                    openDialog={isAddInvoiceDialogOpen}
                    handleCloseDialog={handleCloseAddInvoiceDialog}
                    addInvoice={(...props) => addInvoice(...props, true)}
                />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    companyName: getClientMenuInformation(state).name,
    clientId: getClientId(state),
    agencyId: getAgencyId(state),
    userId: getUserId(state),
    invoices: getInvoices(state),
})

const mapDispatchToProps = {
    fetchInvoices,
    downloadInvoiceFiles,
    addInvoice,
    downloadInvoicesFiles
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientBilling);
