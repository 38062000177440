import React from 'react';
import { Editor, RichUtils, convertToRaw } from 'draft-js';

import 'draft-js/dist/Draft.css'; // Ensure the Draft.js base styles are included
import './RichTextEditor.scss'; // Custom styles for your editor

const RichTextEditor = ({
    editorState,
    handleEditorChange,
    setFieldValue,
    fieldName,
    placeholderText,
}) => {

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            handleEditorChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const onBoldClick = (event) => {
        event.preventDefault();
        handleEditorChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
    };

    const onItalicClick = (event) => {
        event.preventDefault();
        handleEditorChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
    };

    const onHeaderClick = (event) => {
        event.preventDefault();
        handleEditorChange(RichUtils.toggleBlockType(editorState, 'header-three'));
    };

    const handleChange = (newEditorState) => {
        handleEditorChange(newEditorState);

        // Convert the editor state to raw content and update the form field using setFieldValue
        const rawContent = JSON.stringify(convertToRaw(newEditorState.getCurrentContent()));

        setFieldValue(fieldName, rawContent);
    };

    return (
        <div className="editor-container">

                <div className="toolbar">
                    <button type="button" onClick={onBoldClick}><strong>B</strong></button>
                    <button type="button" onClick={onItalicClick}><em>I</em></button>
                    <button type="button" onClick={onHeaderClick}>Heading</button>
                </div>
            <div className="editor">
                <Editor
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={handleChange}  // Use the new handleChange to update both local state and Formik field
                    placeholder={placeholderText}
                />
            </div>
        </div>
    );
};

export default RichTextEditor;
