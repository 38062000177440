import React, { createContext } from 'react';

import { updateCandidateAvatar } from 'actions/candidateActions';

import ChooseAvatarSection from './ChooseAvatarSection';
import { connect } from 'react-redux';

export const ChooseAvatarContext = createContext();
const { Provider } = ChooseAvatarContext;

const ChooseAvatarWrapper = ({
    children,
    continueJourney,
    updateCandidateAvatar,
}) => {
    const context = { continueJourney };

    children = React.Children.map(children, (child) => {
        return React.cloneElement(child, {
            handleContinueJourney: continueJourney,
            updateCandidateAvatar
        });
    });

    return (
        <Provider value={context}>
            {children}
        </Provider>
    )
};

ChooseAvatarWrapper.ChooseAvatarSection = (props) => {
    return <ChooseAvatarSection {...props} />;
}

const mapDispatchToProps = {
    updateCandidateAvatar,
}

export default connect(null, mapDispatchToProps)(ChooseAvatarWrapper);
