import api from './api';
import requester from './requester';

const jobSearchService = {
    fetchJobSearches: (agencyId, clientId) => requester(api.jobSearches(agencyId, clientId)).getMany(),
    fetchJobSearch: (agencyId, clientId, jobSearchId) => requester(api.jobSearch(agencyId, clientId, jobSearchId)).getMany(),
    deleteJobSearch: (agencyId, clientId, jobSearchId) => requester(api.jobSearch(agencyId, clientId, jobSearchId)).delete(),
    createJobSearch: (agencyId, clientId, jobSearchData) => requester(api.jobSearches(agencyId, clientId)).uploadFile(jobSearchData),
    searchMatchingCandidates: (agencyId, clientId, jobSearchId) => requester(api.jobSearchesMatchingCandidates(agencyId, clientId, jobSearchId)).getMany(),
    likeCandidate: (agencyId, clientId, jobSearchId, candidateId) => requester(api.jobSearchesLikeCandidate(agencyId, clientId, jobSearchId)).update({ candidateId }),
    unlikeCandidate: (agencyId, clientId, jobSearchId, candidateId) => requester(api.jobSearchesUnlikeCandidate(agencyId, clientId, jobSearchId)).update({ candidateId }),
    getJobSearchCandidateInterview: (agencyId, clientId, jobSearchId, candidateId) => requester(api.jobSearchesCandidate(agencyId, clientId, jobSearchId, candidateId)).getOne(),
    markViewedCandidate: (agencyId, clientId, jobSearchId, candidateId) => requester(api.jobSearchesCandidate(agencyId, clientId, jobSearchId)).update({ candidateId }),
    updateJobSearchStatus: (agencyId, clientId, jobSearchId, status) => requester(api.jobSearchesStatus(agencyId, clientId, jobSearchId)).update({ status }),
    fetchJobSearchesInterviews: (agencyId, clientId) => requester(api.jobSearchesInterviews(agencyId, clientId)).getMany(),
    fetchJobSearchesLikedCandidates: (agencyId, clientId) => requester(api.jobSearchesLikedCandidates(agencyId, clientId)).getMany(),
};

export default jobSearchService;
