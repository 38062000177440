/* eslint-disable no-unused-vars */
import React from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';
import { Form, FieldArray, ErrorMessage } from 'formik';
import 'moment-timezone';

import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import isHoliday from 'hoc/isHoliday';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, DIALOG_COMMENT_INPUT } from 'constants/commonConstants';
import { ROLES } from 'constants/userConstants';

import { ReactComponent as PlusIcon } from 'assets/plus-icon.svg';

import './InterviewInviteFormView.scss';

const timezones = moment.tz.names();

const InterviewInviteFormView = ({
    values,
    setFieldValue,
    setValues,
    errors,
    displayMode,
    isLocationDisabled,
    isWithNote,
    handleChange,
    handleAddInterviewTimeSlot,
}) => {
    const intl = useIntl();

    return (
        <Form className="interview-invite-form-view form">
            {isWithNote &&
                <div className="note-field">
                    <TextField
                        name="clientNote"
                        label={displayMode === ROLES.CLIENT || displayMode === ROLES.ADMIN ? intl.formatMessage({ id: "note-to-candidate" }) : intl.formatMessage({ id: "note-to-client" })}
                        placeholder={intl.formatMessage({ id: "comment" })}
                        fullWidth
                        multiline
                        variant="outlined"
                        minRows={DIALOG_COMMENT_INPUT.ROWS}
                        maxRows={DIALOG_COMMENT_INPUT.MAX_ROWS}
                        value={values.clientNote}
                        onChange={handleChange}
                    />

                    <ErrorMessage name="clientNote" component="div" className="invalid-field-message" />
                </div>
            }

            <div className="timezone-field">
                <Autocomplete
                    id="timezone"
                    name="timezone"
                    selectOnFocus
                    freeSolo={true}
                    options={timezones}
                    handleHomeEndKeys
                    disabled={isLocationDisabled}
                    value={values.timezone}
                    onChange={(e, newValue) => {
                        setFieldValue('timezone', newValue)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="timezone"
                            name="timezone"
                            label={intl.formatMessage({ id: "timezone" })}
                            fullWidth
                            variant="outlined"
                        />
                    )}
                />

                <ErrorMessage name="timezone" component="div" className="invalid-field-message" />
            </div>

            <div className="form-interview-time-slots-wrapper">
                <p className="heading-s color-dark-grey">
                    {intl.formatMessage({ id: "choose-at-least-two-options" })}
                </p>

                <p className="sub-title subheading-xl color-grey">{intl.formatMessage({ id: "duration-time-one-hour" })}</p>

                <div className="row-list">
                    <FieldArray name="interviewTimeSlots">
                        {() => (values.interviewTimeSlots.map((x, i) => {
                            const dateName = `interviewTimeSlots.${i}.date`;
                            const timeName = `interviewTimeSlots.${i}.time`;

                            return (
                                <div key={i} className="slot-item">
                                    <div className="slot-item-header">
                                        <p className="heading-s color-dark-grey">{intl.formatMessage({ id: "slot" })} {i + 1}</p>
                                        {i === 0 &&
                                            <RoundedButton
                                                icon={<PlusIcon />} 
                                                handleClick={e => handleAddInterviewTimeSlot(values, setValues)}
                                            />
                                        }
                                    </div>
                                    <div className="date-field">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            allowKeyboardControl={true}
                                            error={false}
                                            helperText={null}
                                            label={intl.formatMessage({ id: "select-date" })}
                                            name={dateName}
                                            minDate={new Date()}
                                            renderDay={isHoliday}
                                            format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                                            variant="inline"
                                            inputVariant="outlined"
                                            fullWidth
                                            disableToolbar
                                            value={x.date}
                                            onChange={value => {
                                                setFieldValue(dateName, moment(value));
                                            }}
                                        />
                                        <ErrorMessage name={dateName} component="div" className="invalid-field-message" />
                                    </div>

                                    <div className="time-field">
                                        <KeyboardTimePicker
                                            ampm={false}
                                            error={false}
                                            helperText={null}
                                            label={intl.formatMessage({ id: "select-time" })}
                                            name={timeName}
                                            variant="outlined"
                                            inputVariant="outlined"
                                            fullWidth
                                            keyboardIcon={<AccessTimeIcon />}
                                            value={x.time}
                                            onChange={value => {
                                                setFieldValue(timeName, moment(value).format());
                                            }}
                                        />
                                        <ErrorMessage name={timeName} component="div" className="invalid-field-message" />
                                    </div>

                                </div>
                            );
                        }))}
                    </FieldArray>

                    {errors.time && <p className="invalid-field-message"> {errors.time}</p>}
                </div>
            </div>
        </Form>
    );
};

export default InterviewInviteFormView;
