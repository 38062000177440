/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { getAgencyId, getDisplayMode, getSkillSuggestions } from 'reducers';

import { fetchSkillSuggestions, setSkillSuggestionsInitialState } from 'actions/employeeActions';

import { useDebounce } from 'utils/hooks';

import './SelectTechStack.scss';

const SelectTechStack = ({
    label,
    agencyId,
    handleInputChange,
    handleOnDeleteSkill,
    addNewSkill,
    error,
    technologies,
    skillSuggestions,
    fetchSkillSuggestions,
    setSkillSuggestionsInitialState,
    displayMode,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [reset, setReset] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [isSkillSuggestionsFetching, setIsSkillSuggestionsFetching] = useState(false);

    const debouncedInputValue = useDebounce(inputValue, 500);

    useEffect(() => {
        if (debouncedInputValue) {
            fetchSkillSuggestions(agencyId, debouncedInputValue)
                .then(() => setIsSkillSuggestionsFetching(false));
        }
    }, [debouncedInputValue]);

    const handleArrowClick = () => setOpenDropdown(prevState => !prevState);

    const handleOnAddNewSkill = (inputValue) => {
        addNewSkill(inputValue);
        resetInput();
    };

    const resetInput = () => {
        setInputValue('');
        setOpenDropdown(false);
        setSkillSuggestionsInitialState();
        // this value is used for the key prop of Autocomplete,
        // changing the value resets the Autocomplete's input
        setReset(prevState => !prevState);
    };

    return (
        <div className="select-tech-stack-wrapper">
            <Autocomplete
                className="select-tech-stack-autocomplete"
                key={reset}
                disablePortal
                clearOnBlur={false}
                open={!!(openDropdown && inputValue)}
                options={inputValue && skillSuggestions.length > 0 && !skillSuggestions.some(skill => skill._id === inputValue?.toLowerCase())
                    ? [{ _id: inputValue?.toLowerCase(), title: inputValue }, ...skillSuggestions]
                    : skillSuggestions
                }
                loading={isSkillSuggestionsFetching}
                loadingText={
                    <div className="search-skills-loader">
                        <CircularProgress thickness={5} size={20} />
                    </div>
                }
                noOptionsText={inputValue && skillSuggestions.length === 0
                    ? <div className="add-new-skill-option" onMouseDown={() => handleOnAddNewSkill(inputValue)}>{`Add skill - "${inputValue}"`}</div>
                    : 'No options'
                }
                popupIcon={
                    <Icon>
                        <KeyboardArrowDownIcon onClick={handleArrowClick} />
                    </Icon>
                }
                getOptionLabel={(option) => option.title || ''}
                getOptionSelected={(option, value) => option.title === value.title}
                renderInput={(params) => {
                    return (
                        <>
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                label={label}
                                error={!!error}
                                onChange={(event) => {
                                    setInputValue(event.target.value);
                                    setOpenDropdown(true);
                                    setIsSkillSuggestionsFetching(true);
                                }}
                            />
                            {error && <p className="invalid-field-message">{error}</p>}
                        </>
                    )
            }}
                renderOption={(props) => {
                    const title = props._id === inputValue?.toLowerCase() ? `Add skill - "${inputValue}"` : props.title;
                    const className = props._id === inputValue?.toLowerCase() ? 'add-skill-option' : '';
                    return <div className={className}>{title}</div>
                }}
                onChange={(e, value) => {
                    resetInput();
                    handleInputChange(value);
                }}
                onClose={(e, reason) => {
                    if (reason === 'select-option') {
                        setInputValue('');
                        setOpenDropdown(false);
                        setSkillSuggestionsInitialState();
                    } else if (reason === 'blur') {
                        setOpenDropdown(false);
                    }
                }}
                onFocus={() => setOpenDropdown(true)}
            />

            {technologies.length > 0 &&
                <div className="technologies-wrapper">
                    {technologies.map((technology, index) =>
                        <div className={`technology-container ${displayMode}`} key={index}>
                            <span className="subheading-m color-grey">{technology}</span>
                            <CloseIcon className="delete-technology-icon" onClick={handleOnDeleteSkill.bind(null, index)} />
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    skillSuggestions: getSkillSuggestions(state),
    displayMode: getDisplayMode(state),
});

const mapDispatchToProps = {
    fetchSkillSuggestions,
    setSkillSuggestionsInitialState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectTechStack);
