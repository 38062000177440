import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import RegisterAgencyCandidateOrClientFormView from './RegisterAgencyCandidateOrClientFormView';
import AuthCard from 'components/Shared/AuthCard';
import Grid from '@material-ui/core/Grid';

import { getActionButtonLoading, getSSOLoginLoading } from 'reducers';

import { registerCandidate, login, ssoLoginSuccess, registerClient } from 'actions/authActions';
import { createSSOLoggingRecord } from 'actions/loggingActions';
import { showActionButtonLoader, hideActionButtonLoader } from 'actions/sectionsLoadersActions';

import { signInAgencyCandidateOrClientWithPopup } from 'utils/firebase';
import {
    validateEmailAddress,
    validatePasswordRequirements,
    validateRequiredFields
} from 'utils/formValidations';
import { logEvent } from 'utils/amplitude';
import { getHomePath } from 'utils/navigationUtils';

import { ROLES, REGISTRATION_TYPES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { UI_VIEWS } from 'constants/authConstants';

import { ReactComponent as CoderyTitleImageCandidate } from 'assets/join-our-team-candidate.svg';
import { ReactComponent as CoderyTitleImageClient } from 'assets/join-our-team-client.svg';

import { ReactComponent as Arrows } from 'assets/arrows.svg';

import './RegisterAgencyCandidateOrClient.scss';

const RegisterAgencyCandidateOrClient = ({
    isActionButtonLoading,
    ssoLoginLoading,
    registerCandidate,
    registerClient,
    login,
    ssoLoginSuccess,
    createSSOLoggingRecord,
    showActionButtonLoader,
    hideActionButtonLoader
}) => {
    const history = useHistory();
    const { agencyData } = useParams();

    const agencyInfo = JSON.parse(atob(decodeURIComponent(agencyData)));

    const [selectedView, setSelectedView] = useState(history.location?.state?.selectedView || UI_VIEWS.CANDIDATE);

    useEffect(() => {
        if (!agencyInfo || !agencyInfo.agencyId) {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    const handleSignUp = async values => {
        try {
            showActionButtonLoader();
            const { confirmPassword, ...rest } = values;

            const userData = {
                ...rest,
                role: selectedView.ROLE,
                registrationType: REGISTRATION_TYPES.SELF_REGISTERED,
                agencyId: agencyInfo.agencyId,
                email: values.email.toLowerCase().trim(),
            };

            selectedView.ROLE === ROLES.CANDIDATE ? await registerCandidate(userData) : await registerClient({...userData, isFirst: true});
            await login(userData.email, values.password, false);
        } finally {
            hideActionButtonLoader();
        }
    };

    const handleSSOSignIn = async event => {
        event.preventDefault();
        /* 
        This will prevent redirecting from the isGuest component,
        so we can redirect manually after successful login.
        */
        history.replace({ state: { isFromCandidateOrClientRegistration: true } });
        try {
            ssoLoginSuccess(false);
            const user = await signInAgencyCandidateOrClientWithPopup(agencyInfo.agencyId, selectedView.ROLE);
            user.isNew && logEvent(AMPLITUDE_EVENT_TYPES.REGISTER, null, { role: user.role, type: 'SSO' });
            await login(user.email, null, false, false, true, user.credential);
            const homePath = getHomePath(history.location.state?.gotoUrl);
            history.push({ pathname: homePath.pathname, state: user.isNew ? { ...history.location.state, ...homePath.state } : {} });
        } catch (err) {
            createSSOLoggingRecord({ message: err.message });
        } finally {
            ssoLoginSuccess(true);
        }
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="register-agency-candidate-wrapper"
        >
            <AuthCard
                titleAsImage={true}
                titleImageComponent={selectedView.TITLE === UI_VIEWS.CLIENT.TITLE ? <CoderyTitleImageClient /> : <CoderyTitleImageCandidate />}
                mainSubtitle={"Codery is Eastern Europe's developer-focused job platform. Create a profile and let companies apply to you."}
                footerImageComponent={<Arrows className="footer-component" />}
                title="Sign up"
                className="register-agency-candidate-form-wrapper"
            >
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        password: '',
                        isTermsAndConditionsAccepted: false
                    }}
                    validate={values => {
                        return {
                            ...validateRequiredFields(values, ['name', 'email', 'password', 'isTermsAndConditionsAccepted']),
                            ...validateEmailAddress(values, 'email'),
                            ...validatePasswordRequirements(values, 'password'),
                        };
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(false);
                        await handleSignUp(values);
                    }}
                >
                    {(props) =>
                        <RegisterAgencyCandidateOrClientFormView
                            {...props}
                            selectedView={selectedView}
                            setSelectedView={setSelectedView}
                            isActionButtonLoading={isActionButtonLoading}
                            ssoLoginLoading={ssoLoginLoading}
                            handleSSOSignIn={handleSSOSignIn}
                        />
                    }
                </Formik>
            </AuthCard>
        </Grid>
    );
};

const mapStateToProps = state => ({
    isActionButtonLoading: getActionButtonLoading(state),
    ssoLoginLoading: getSSOLoginLoading(state),
});

const mapDispatchToProps = {
    registerCandidate,
    registerClient,
    login,
    ssoLoginSuccess,
    createSSOLoggingRecord,
    showActionButtonLoader,
    hideActionButtonLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAgencyCandidateOrClient);
