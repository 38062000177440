import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import { connect } from 'react-redux';
import { getAgencyRouteName, getLanguage } from 'reducers';

import { RouteWrapper } from './utils/router';
import { ViewPage } from 'utils/amplitude';
import { ModifyAppWidth, ScrollToTop } from './utils/helpers';

import isAuthenticated from './hoc/isAuthenticated';
import isDepartmentManager from "./hoc/isDepartmentManager";
import isGuest from './hoc/isGuest';
import withUserData from 'hoc/withUserData/withUserData';

import GuestLayout from 'layouts/Guest';
import ContentLayout from 'layouts/ContentLayout';
import SinglePageLayout from 'layouts/SinglePageLayout';
import CandidateJourneyLayout from 'layouts/CandidateJourneyLayout';
import CandidateLayout from 'layouts/CandidateLayout';
import ChooseUsageLayout from 'layouts/ChooseUsageLayout';
import ProfileCreationLayout from 'layouts/ProfileCreationLayout';
import Notification from 'components/Common/Notifications';
import SessionChangeListener from 'components/Common/SessionChangeListener';
import Holidays from 'components/Person/Holidays';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dashboard from 'components/Dashboard';
import RegisterAgencyCandidateOrClient from 'components/Register/RegisterAgencyCandidateOrClient';
import Login from 'components/Login';
import StartCandidateJourney from 'components/StartCandidateJourney';
import ThankYouPage from 'components/ThankYouPage';
import ExpiredLinkPage from 'components/ExpiredLinkPage';
import ForgottenPassword from 'components/ForgottenPassword';
import PasswordReset from 'components/PasswordReset';
import PasswordSet from 'components/PasswordSet';
import Employees from 'components/Employees';
import Person from 'components/Person';
import Candidate from 'components/Candidate';
import TimeManagement from 'components/TimeManagement';
import TeamHolidays from 'components/TeamHolidays';
import ExpenseReports from 'components/ExpenseReports';
import TeamDocuments from 'components/TeamDocuments';
import EmployeeProfile from 'components/Employee/EmployeeProfile';
import EmployeePersonalInfo from 'components/Employee/EmployeePersonalInfo';
import EmployeeContracts from 'components/Employee/Contracts';
import EmploymentInformation from 'components/Person/EmploymentInformation';
import HottestTalents from 'components/HottestTalents';
import ApplicationLoader from 'components/Common/ApplicationLoader';
import YourAccountManager from 'components/YourAccountManager';
import Contracts from 'components/Contracts';
import Billing from 'components/Billing';
import Suppliers from 'components/Suppliers';
import Supplier from 'components/Supplier';
import CurrentProjects from 'components/CurrentProjects';
import EmployeePerformanceReview from 'components/EmployeePerformanceReview';
import SearchResults from 'components/SearchResults/SearchResults';
import Onboarding from 'components/Onboarding';
import Benefits from 'components/Person/Benefits';
import ProjectDetails from 'components/Client/Projects/ProjectDetails';
import Recruiters from 'components/Recruiters';
import Recruiter from 'components/Recruiter';
import Tasks from 'components/Tasks';
import RecruiterProfiles from 'components/RecruiterProfiles';
import JobOpportunityDetails from 'components/JobOpportunities/JobOpportunityDetails';
import MyApplications from 'components/MyApplications';
import UpcomingInterviews from 'components/UpcomingInterviews';
import Candidates from 'components/Candidates';
import CandidatesChecklist from 'components/CandidatesChecklist';
import DepartmentMembers from 'components/DepartmentMembers';
import JobMatchingConsultants from 'components/JobMatchingConsultants';
import RedirectToHome from 'components/RedirectToHome';
import RedirectToVerification from 'components/RedirectToVerification';
import MaintenancePage from 'components/MaintenancePage';
import CustomerSupport from 'components/YourAccountManager/CustomerSupport';
import Client from 'components/Client';
import Trainings from 'components/Trainings';
import Training from 'components/Trainings/Training';
import IndividualTraining from 'components/Settings/Trainings/TrainingField/IndividualTraining';
import Clients from 'components/Clients';
import Settings from 'components/Settings';
import MeetYourTeam from 'components/MeetYourTeam';
import SupplierEmployee from 'components/SupplierEmployee';
import ExploreTalents from 'components/ExploreTalents';
import CandidateProfileJourney from 'components/CandidateProfileJourney';
import CandidateProfilePage from 'components/CandidateProfilePage';
import CandidateJourneyCompletionThankYouScreen from 'components/CandidateJourneyCompletionThanYouScreen'
import Jobs from 'components/JobOpportunities/Jobs';
import JobDetails from 'components/JobOpportunities/JobDetails';
import JobsDashboard from 'components/JobsDashboard';
import CandidateDetailsPage from 'components/CandidateDetailsPage';
import JobPage from 'components/JobPage';
import CoachingMentoring from 'components/CoachingMentoring';
import TimeTrackingPage from 'components/TimeTrackingPage';
import ProfileMatchingJobs from 'components/ProfileMatchingJobs';
import ReportsPage from 'components/ReportsPage';
import MyApplicationPage from 'components/MyApplicationPage';
import AutomaticallyTrackHours from 'components/AutomaticallyTrackHours';
import ChooseUsage from 'components/ChooseUsage';
import CreateAgencyProfile from 'components/CreateAgencyProfile';
import AcceptTermsAndConditions from 'components/AcceptTermsAndConditions';
import ClientProfileJourney from 'components/ClientProfileJourney';
import JobSearches from 'components/JobSearches';
import JobSearchDetails from 'components/JobSearchDetails';
import JobSearchResults from 'components/JobSearchResults';
import StartTrial from 'components/StartTrial';
import SorryPage from 'components/SorryPage';
import Interviews from 'components/Interviews';
import SavedTalents from 'components/SavedTalents';
import ClientTeam from 'components/ClientTeam';
import TechnicalSelfAssignment from 'components/TechnicalSelfAssessment';

import { ROLES } from 'constants/userConstants';
import { languages } from 'constants/languages';
import { ADMIN_USERS_PERMISSIONS } from 'constants/userPermissionsConstants';

import './App.scss';

const ExploreTalentsViewProfile = React.lazy(() => import('components/ExploreTalents/ExploreTalentsViewProfile'));
const TermsAndConditions = React.lazy(() => import('components/TermsAndConditions'));

const App = ({ agencyRouteName, locale }) => {
    return (
        <IntlProvider locale={locale} messages={languages[locale]}>
            <CssBaseline />
            <Notification />
            <ScrollToTop />
            <ViewPage />
            <ModifyAppWidth />
            <SessionChangeListener />
            <Suspense fallback={<ApplicationLoader />}>
                <Switch>
                    <RouteWrapper path={`/${agencyRouteName}/dashboard`} component={isAuthenticated(withUserData(Dashboard))} layout={ContentLayout} backgroundColor={{ [ROLES.CLIENT]: 'primary-light' }} />
                    <RouteWrapper path={`/${agencyRouteName}/reports`} component={isAuthenticated(withUserData(ReportsPage), [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.REPORTS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/meet-your-team`} exact component={isAuthenticated(withUserData(MeetYourTeam))} layout={ContentLayout} />
                    <RouteWrapper path='/register/:agencyData' component={isGuest(RegisterAgencyCandidateOrClient)} layout={ProfileCreationLayout} />
                    <RouteWrapper path='/select-a-role' component={isGuest(ChooseUsage)} layout={ChooseUsageLayout} />
                    <RouteWrapper path='/create-company-profile' exact component={isAuthenticated(CreateAgencyProfile, [ROLES.UNASSIGNED_ADMIN])} layout={ProfileCreationLayout} />
                    <RouteWrapper path='/create-company-profile/start-trial' component={isAuthenticated(StartTrial, [ROLES.ADMIN])} layout={ProfileCreationLayout} />
                    <RouteWrapper path='/login' component={isGuest(Login)} layout={ProfileCreationLayout} />
                    <RouteWrapper path='/thank-you/:role' component={isGuest(ThankYouPage)} layout={GuestLayout} />
                    <RouteWrapper path='/expired-link/:type' component={isGuest(ExpiredLinkPage)} layout={ProfileCreationLayout} />
                    <RouteWrapper path='/forgottenpassword' component={isGuest(ForgottenPassword)} layout={ProfileCreationLayout} />
                    <RouteWrapper path={`/terms-and-conditions`} exact component={TermsAndConditions} noDesktopLayout />
                    <RouteWrapper path={`/${agencyRouteName}/explore-talents/:id`} exact component={isAuthenticated(withUserData(ExploreTalentsViewProfile), [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN], ADMIN_USERS_PERMISSIONS.TALENTS_VIEW)} layout={SinglePageLayout} />
                    <RouteWrapper path='/account/passwordreset' component={isGuest(PasswordReset)} layout={ProfileCreationLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/explore-talents`} exact component={isAuthenticated(ExploreTalents, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.TALENTS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/hottest-talents`} exact component={isAuthenticated(HottestTalents, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.TALENTS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path='/account/passwordset' component={isGuest(PasswordSet)} layout={ProfileCreationLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/talent-searches`} exact component={isAuthenticated(withUserData(JobSearches), [ROLES.CLIENT, ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/talent-searches/:id`} exact component={isAuthenticated(withUserData(JobSearchDetails), [ROLES.CLIENT, ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/talent-searches/:id/search-results`} exact component={isAuthenticated(withUserData(JobSearchResults), [ROLES.CLIENT, ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/interviews`} exact component={isAuthenticated(withUserData(Interviews), [ROLES.CLIENT])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/client-team`} exact component={isAuthenticated(withUserData(ClientTeam), [ROLES.CLIENT])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/saved-talents`} exact component={isAuthenticated(withUserData(SavedTalents), [ROLES.CLIENT])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/people`} exact component={isAuthenticated(Employees, [ROLES.SUPPLIER_ADMIN, ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.TEAM_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/people/:id`} component={isAuthenticated(withUserData(Person), [ROLES.SUPPLIER_ADMIN, ROLES.ADMIN, ROLES.RECRUITER], ADMIN_USERS_PERMISSIONS.TEAM_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/time-management`} exact component={isAuthenticated(TimeManagement, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.TEAM_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/holidays`} component={isAuthenticated(withUserData(TeamHolidays), [ROLES.ADMIN, ROLES.SUPPLIER_ADMIN], ADMIN_USERS_PERMISSIONS.TEAM_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/expense-reports`} exact component={isAuthenticated(ExpenseReports, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.TEAM_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/documents`} exact component={isAuthenticated(TeamDocuments, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.TEAM_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/departments/holidays`} component={isDepartmentManager(withUserData(TeamHolidays))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/departments/documents`} component={isDepartmentManager(withUserData(TeamDocuments))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/settings/trainings/:trainingId`} component={isAuthenticated(IndividualTraining, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.SETTINGS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/settings`} component={isAuthenticated(Settings, [ROLES.ADMIN, ROLES.SUPPLIER_ADMIN, ROLES.CLIENT], ADMIN_USERS_PERMISSIONS.SETTINGS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/employment/cv`} component={isAuthenticated(withUserData(EmployeeProfile), [ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/employment/personal-info`} component={isAuthenticated(withUserData(EmployeePersonalInfo), [ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/employment/contracts`} component={isAuthenticated(withUserData(EmployeeContracts), [ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/time-tracking`} component={isAuthenticated(withUserData(TimeTrackingPage), [ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/holidays`} component={isAuthenticated(withUserData(Holidays))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/benefits`} component={isAuthenticated(withUserData(Benefits))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/performance-review`} exact component={isAuthenticated(withUserData(EmployeePerformanceReview))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/salary`} component={isAuthenticated(withUserData(EmploymentInformation))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/clients`} exact component={isAuthenticated(Clients, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.CLIENTS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/clients/:id`} component={isAuthenticated(withUserData(Client, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.CLIENTS_VIEW))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/your-account-manager`} exact component={isAuthenticated(withUserData(YourAccountManager))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/contracts`} component={isAuthenticated(withUserData(Contracts), [ROLES.CLIENT, ROLES.SUPPLIER_ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/billing`} component={isAuthenticated(withUserData(Billing), [ROLES.CLIENT, ROLES.SUPPLIER_ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/partners`} exact component={isAuthenticated(Suppliers, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.PARTNERS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/partners/:id/team/people/:employeeId`} component={isAuthenticated(withUserData(SupplierEmployee), [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.PARTNERS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/partners/:id`} component={isAuthenticated(Supplier, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.PARTNERS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/current-projects`} component={isAuthenticated(withUserData(CurrentProjects))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/projects/:projectId/clients/:id`} component={isAuthenticated(withUserData(ProjectDetails), [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN], ADMIN_USERS_PERMISSIONS.CLIENTS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/jobs/:jobId/matching-consultants`} component={isAuthenticated(withUserData(JobMatchingConsultants), [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.CLIENTS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/search-results`} component={isAuthenticated(SearchResults, [ROLES.ADMIN, ROLES.CLIENT])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/terms-and-conditions/accept`} exact component={isAuthenticated(withUserData(AcceptTermsAndConditions), [ROLES.CANDIDATE])} layout={ProfileCreationLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/terms-and-conditions`} component={isAuthenticated(withUserData(TermsAndConditions))} noDesktopLayout />
                    <RouteWrapper path={`/${agencyRouteName}/trainings`} exact component={isAuthenticated(withUserData(Trainings), [ROLES.EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/upcoming-interviews`} exact component={isAuthenticated(withUserData(UpcomingInterviews), [ROLES.EMPLOYEE, ROLES.ADMIN, ROLES.SUPPLIER_EMPLOYEE, ROLES.SUPPLIER_ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/trainings/:trainingId`} component={isAuthenticated(withUserData(Training), [ROLES.EMPLOYEE, ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.SETTINGS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/coaching-opportunities/:coachingOpportunityId`} component={isAuthenticated(withUserData(CoachingMentoring), [ROLES.EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/onboarding`} component={isAuthenticated(withUserData(Onboarding), [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN, ROLES.SUPPLIER_EMPLOYEE, ROLES.EMPLOYEE])} layout={CandidateJourneyLayout} noDesktopLayout={true} />
                    <RouteWrapper path={`/${agencyRouteName}/recruiters`} exact component={isAuthenticated(Recruiters, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.PARTNERS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/recruiters/:id`} component={isAuthenticated(Recruiter, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.PARTNERS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/recruiter-profiles`} component={isAuthenticated(withUserData(RecruiterProfiles), [ROLES.RECRUITER])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/tasks`} exact component={isAuthenticated(withUserData(Tasks, [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.TASKS_VIEW))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/job-opportunities/:id`} exact component={isAuthenticated(withUserData(JobOpportunityDetails), [ROLES.CANDIDATE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/candidates`} exact component={isAuthenticated(withUserData(Candidates), [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.CANDIDATES_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/candidates/checklist`} exact component={isAuthenticated(withUserData(CandidatesChecklist), [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.CANDIDATES_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/create-profile/jobs`} exact component={isAuthenticated(withUserData(Jobs), [ROLES.CANDIDATE])} layout={CandidateJourneyLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/create-profile/jobs/:id`} exact component={isAuthenticated(withUserData(JobDetails), [ROLES.CANDIDATE])} layout={CandidateJourneyLayout} />
                    <RouteWrapper path={[`/${agencyRouteName}/create-profile`, `/${agencyRouteName}/create-profile/:id`]} exact component={isAuthenticated(withUserData(CandidateProfileJourney), [ROLES.CANDIDATE])} layout={CandidateJourneyLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/create-client-profile`} exact component={isAuthenticated(withUserData(ClientProfileJourney), [ROLES.CLIENT])} layout={CandidateJourneyLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/candidates/:id`} component={isAuthenticated(withUserData(Candidate), [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.CANDIDATES_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/departments/:departmentId/members`} exact component={isDepartmentManager(DepartmentMembers)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/departments/:departmentId/members/:memberId`} component={isDepartmentManager(withUserData(Person))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/my-applications`} exact component={isAuthenticated(withUserData(MyApplications), [ROLES.CANDIDATE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/my-applications/:id`} exact component={isAuthenticated(withUserData(MyApplicationPage), [ROLES.CANDIDATE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/customer-support`} exact component={isAuthenticated(withUserData(CustomerSupport))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/maintenance`} component={MaintenancePage} layout={GuestLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/profile`} exact component={isAuthenticated(withUserData(CandidateProfilePage), [ROLES.CANDIDATE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/profile/self-assignment`} exact component={isAuthenticated(withUserData(TechnicalSelfAssignment), [ROLES.CANDIDATE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/jobs`} exact component={isAuthenticated(withUserData(Jobs), [ROLES.CANDIDATE])} layout={CandidateLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/job-page/:jobId`} exact component={isAuthenticated(withUserData(JobPage), [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN], ADMIN_USERS_PERMISSIONS.CLIENTS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/jobs/:id`} exact component={isAuthenticated(withUserData(JobDetails), [ROLES.CANDIDATE])} layout={CandidateLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/jobs-dashboard`} exact component={isAuthenticated(withUserData(JobsDashboard), [ROLES.CLIENT, ROLES.ADMIN, ROLES.SUPPLIER_ADMIN], ADMIN_USERS_PERMISSIONS.CLIENTS_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={[`/${agencyRouteName}/start-journey`, `/${agencyRouteName}/start-journey-signup-success`]} component={isAuthenticated(withUserData(StartCandidateJourney), [ROLES.CANDIDATE, ROLES.CLIENT])} layout={CandidateJourneyLayout} noDesktopLayout={true} />
                    <RouteWrapper path={`/${agencyRouteName}/jobs/:jobId/candidates/:candidateId/applications/:applicationId`} exact component={isAuthenticated(withUserData(CandidateDetailsPage), [ROLES.ADMIN, ROLES.CLIENT])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/profile/:userId/matching-jobs`} component={isAuthenticated(withUserData(ProfileMatchingJobs), [ROLES.ADMIN], ADMIN_USERS_PERMISSIONS.CANDIDATES_VIEW)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/automatic-track-hours`} component={withUserData(AutomaticallyTrackHours)} layout={ContentLayout} />
                    <Route path={`/${agencyRouteName}/journey-end`} exact component={isAuthenticated(withUserData(CandidateJourneyCompletionThankYouScreen), [ROLES.CANDIDATE])} />
                    <Route path='/account' exact component={RedirectToVerification} />
                    <Route path='/sorry' exact component={SorryPage} />
                    <Route path='*' component={isAuthenticated(withUserData(RedirectToHome))} />
                </Switch>
            </Suspense>
            <ApplicationLoader />
        </IntlProvider>
    )
};

const mapStateToProps = state => ({
    agencyRouteName: getAgencyRouteName(state),
    locale: getLanguage(state),
})

export default connect(mapStateToProps, null)(App);

