import React from 'react';

import { ReactComponent as DashboardIcon } from 'assets/client-menu-dashboard.svg';
import { ReactComponent as TeamIcon } from 'assets/client-menu-team.svg';
import { ReactComponent as TalentSearchesIcon } from 'assets/client-menu-talent-searches.svg';
import { ReactComponent as SavedTalentsIcon } from 'assets/client-menu-saved-talents.svg';
import { ReactComponent as InterviewsIcon } from 'assets/client-menu-interviews.svg';
import { ReactComponent as BillingIcon } from 'assets/client-menu-billing.svg';
import { ReactComponent as ContractsIcon } from 'assets/client-menu-contracts.svg';
import { ReactComponent as AccountManagerIcon } from 'assets/client-menu-account-manager.svg';

export const getClientMenuItems = agencyRouteName => [
    {
        TITLE: 'Dashboard',
        ICON: <DashboardIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/dashboard`
    },
    {
        TITLE: 'Team',
        ICON: <TeamIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/client-team`
    },
    {
        TITLE: 'Talent Searches',
        ICON: <TalentSearchesIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/talent-searches`
    },
    {
        TITLE: 'Saved Talents',
        ICON: <SavedTalentsIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/saved-talents`
    },
    {
        TITLE: 'Interviews',
        ICON: <InterviewsIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/interviews`
    },
    {
        TITLE: 'Billing',
        ICON: <BillingIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/billing`
    },
    {
        TITLE: 'Contracts',
        ICON: <ContractsIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/contracts`
    },
	{
		TITLE: 'Account Manager',
		ICON: <AccountManagerIcon className="orange" />,
		ROUTE: `/${agencyRouteName}/your-account-manager`,
	},
];

export const getClientMenuItemsSaas = agencyRouteName => [
    {
        TITLE: 'Dashboard',
        ICON: <DashboardIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/dashboard`
    },
    {
        TITLE: 'Team',
        ICON: <TeamIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/client-team`
    },
    {
        TITLE: 'Talent Searches',
        ICON: <TalentSearchesIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/talent-searches`
    },
    {
        TITLE: 'Saved Talents',
        ICON: <SavedTalentsIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/saved-talents`
    },
    {
        TITLE: 'Interviews',
        ICON: <InterviewsIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/interviews`
    },
    {
        TITLE: 'Billing',
        ICON: <BillingIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/billing`
    },
    {
        TITLE: 'Contracts',
        ICON: <ContractsIcon className="orange" />,
        ROUTE: `/${agencyRouteName}/contracts`
    },
	{
		TITLE: 'Account Manager',
		ICON: <AccountManagerIcon className="orange" />,
		ROUTE: `/${agencyRouteName}/your-account-manager`,
	},
];
