import React from 'react';
import { useIntl } from 'react-intl';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import './DisabledToSearchDialog.scss';

const DISABLE_VALUES = [
    { VALUE: 1, LABEL: true },
    { VALUE: 2, LABEL: false },
];

const DisabledToSearchDialog = ({
    handleSubmit,
    handleChange,
    values,
    onClose,
    open,
}) => {
    const intl = useIntl();

    const onFormSubmit = () => {
        handleSubmit();
    };

    return (
        <CommonFormikDialog
            className="disabled-to-search-dialog"
            open={open}
            onClose={onClose}
            dialogTitle={intl.formatMessage({ id: "disable-search-appearance" })}
            saveBtnText="save"
            discardBtnText="discard"
            onSave={onFormSubmit}
        >
            <form className="disabled-to-search-dialog-form" autoComplete="off">
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2">
                            <TextField
                                select
                                value={values.isDisabledForSearch}
                                variant="outlined"
                                onChange={handleChange}
                                name="isDisabledForSearch"
                                id="isDisabledForSearch"
                                fullWidth
                            >
                                {DISABLE_VALUES.map((option) => {
                                    return (
                                        <MenuItem key={option.VALUE} value={option.LABEL}>
                                            {option.LABEL === true ? "Yes" : "No"}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                    </div>
                </div>
            </form>
        </CommonFormikDialog>
    );
};

export default DisabledToSearchDialog;
