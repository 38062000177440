import React from 'react';
import Button from '@material-ui/core/Button';

import './SecondaryButton.scss';

const SecondaryButton = React.forwardRef(({
    text,
    icon,
    className,
    component,
    type,
    handleClick,
    disabled,
    buttonStyle,
    ...props
}, ref) => {
    const getClassName = () => {
        const classNames = ['secondary-button'];

        if (className) {
            classNames.push(className);
        }

        if (buttonStyle) {
            classNames.push(buttonStyle);
        }

        return classNames.join(' ');
    };

    return (
        <Button
            variant='outlined'
            className={getClassName()}
            type={type}
            onClick={handleClick}
            component={component ? component : 'button'}
            disabled={disabled}
            ref={ref}
            startIcon={icon || null}
            {...props}
        >
            {text}
        </Button>
    );
});

export default SecondaryButton;
