import React from 'react';
import { useHistory } from 'react-router-dom';

import { Avatar } from '@material-ui/core';

import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { getStateAgencyName } from 'utils/helpers';

import { ReactComponent as PeopleIcon } from 'assets/client-menu-team.svg';
import { ReactComponent as SavedIcon } from 'assets/client-menu-saved-talents.svg';

import defaultAvatar from 'assets/default-avatar.svg';

import './JobSearchCard.scss';

const JobSearchCard = ({
    jobSearch
}) => {
    const history = useHistory();

    return (
        <div className="job-search-card-wrapper">
            <div className="job-search-card-header">
                <Avatar className="job-img" src={jobSearch.imageData?.jobImgUrl || defaultAvatar} />
                <p className="subheading-l color-dark-grey">{jobSearch.position}</p>
            </div>
            <div className="job-search-card-container">
                <div className="info-row">
                    <PeopleIcon />
                    <p className="subheading-xxl color-dark-grey">{jobSearch.matchingCandidates?.length || 0}</p>
                    <p className="subheading-s color-grey">Matching Candidates</p>
                </div>
                <div className="info-row">
                    <SavedIcon />
                    <p className="subheading-xxl color-dark-grey">{jobSearch.likedCandidates?.length || 0}</p>
                    <p className="subheading-s color-grey">Saved Candidates</p>
                </div>
            </div>
            <hr className="custom-divider" />
            <div className="job-search-card-actions">
                <SecondaryButton className="white" text="Saved Talents" handleClick={() => history.push(`/${getStateAgencyName()}/talent-searches/${jobSearch._id}`)} />
                <PrimaryButton className="dark" text="Resume Search" handleClick={() => history.push(`/${getStateAgencyName()}/talent-searches/${jobSearch._id}/search-results`)} />
            </div>
        </div>
    )
};

export default JobSearchCard;
