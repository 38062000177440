import GuestLayout from 'layouts/Guest';
import ProdGuestLayout from 'layouts/ProdGuestLayout';

import preval from 'preval.macro';
import MotionSoftwareLoginBackground from 'assets/mountain-no-border.png';
import WhoIsAvailableLogo from 'assets/Logo.js';
import WhoIsAvailableLogoWhite from 'assets/LogoWhite.js';
import WhoIsAvailableLoginBackground from 'assets/login-background-WIA.svg';
import { ReactComponent as CoderyLogoWhite } from 'assets/codery-logo.svg';
import { ReactComponent as CoderyLogoDark } from 'assets/codery-logo-dark.svg';

const SERVER_ADDRESSES = {
    test: 'http://localhost:5000',
    development: 'http://localhost:5000',
    qa: 'https://qa.coderyteams.com',
    production: 'https://api.whoisavailable.io',
    whoisavailable: 'https://apicoderyteam.com',
    staging: 'https://api.wiastaging.motiontest.eu',
};

const APP_ADDRESS = {
    test: 'http://localhost:3000',
    development: 'http://localhost:3000',
    qa: 'https://wia-qa-ae414.firebaseapp.com',
    production: 'https://whoisavailable.motion-software.com',
    whoisavailable: 'https://app.teamcodery.com',
    staging: 'https://stagingsaas.motiontest.eu',
};

const FIREBASE_CONFIGS = {
    test: {
        apiKey: 'AIzaSyBNsCB1Pdd1ESpRi-cBN7i21dgPuaU7bO4',
        authDomain: 'who-is-available-qa.firebaseapp.com',
        databaseURL: 'https://who-is-available-qa.firebaseio.com',
        projectId: 'who-is-available-qa',
        storageBucket: 'who-is-available-qa.appspot.com',
        messagingSenderId: '913904170245',
        appId: '1:913904170245:web:8a35101b8cd6d708f9736c'
    },
    development: {
        apiKey: "AIzaSyC2rDMLKVz3Sxx1uSDIIIuVMeomXe-x878",
        authDomain: "wia-qa-ae414.firebaseapp.com",
        projectId: "wia-qa-ae414",
        storageBucket: "wia-qa-ae414.appspot.com",
        messagingSenderId: "357789332355",
        appId: "1:357789332355:web:baf024e3b7f40e80887cb6",
        measurementId: "G-1KEQX1D0T6"
    },
    qa: {
        apiKey: "AIzaSyC2rDMLKVz3Sxx1uSDIIIuVMeomXe-x878",
        authDomain: "wia-qa-ae414.firebaseapp.com",
        projectId: "wia-qa-ae414",
        storageBucket: "wia-qa-ae414.appspot.com",
        messagingSenderId: "357789332355",
        appId: "1:357789332355:web:baf024e3b7f40e80887cb6",
        measurementId: "G-1KEQX1D0T6"
    },
    production: {
        apiKey: 'AIzaSyBeeGcHEWVCYGf7Y7sDdXTTWaDZNh9ggqI',
        authDomain: 'who-is-available-production.firebaseapp.com',
        databaseURL: 'https://who-is-available-production.firebaseio.com',
        projectId: 'who-is-available-production',
        storageBucket: 'who-is-available-production.appspot.com',
        messagingSenderId: '866015689857',
        appId: '1:866015689857:web:aea47acafd6629c3856180',
        measurementId: 'G-GZC8BXWP14'
    },
    whoisavailable: {
        apiKey: "AIzaSyCz0tRDZJumnU6Up4OtDDCePZf4IyfsmWw",
        authDomain: "codery-wia-prod.firebaseapp.com",
        projectId: "codery-wia-prod",
        storageBucket: "codery-wia-prod.appspot.com",
        messagingSenderId: "660839794345",
        appId: "1:660839794345:web:fc4c1ef36ce4ea5efd4bb8",
        measurementId: "G-PNW2WPXM4Y"
    },
    staging: {
        apiKey: "AIzaSyAnYPmo54iPjUqszQdzGUJYgq0u6rfYUag",
        authDomain: "stagingsaas.motiontest.eu",
        projectId: "saas-staging-f5655",
        storageBucket: "saas-staging-f5655.appspot.com",
        messagingSenderId: "537690212628",
        appId: "1:537690212628:web:b67a7e8f7476c3af44da01"
    },
};

const AMPLITUDE_CONFIGS = {
    test: {
        API_KEY: '8e9cf650256af3bf6b80c9c90db53bcc',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
    development: {
        API_KEY: '8e9cf650256af3bf6b80c9c90db53bcc',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
    qa: {
        API_KEY: '8e9cf650256af3bf6b80c9c90db53bcc',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
    production: {
        API_KEY: '56995abf4d48cc0559b4e4c1acf7edd5',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
    whoisavailable: {
        API_KEY: 'cbcf47f1ec55d17d25ffea2d7c5e0928',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
    staging: {
        API_KEY: '8e9cf650256af3bf6b80c9c90db53bcc',
        OPTIONS: {
            includeUtm: true,
            includeReferrer: true,
        }
    },
};

const APP_TRADEMARKS = {
    test: { LOGO: WhoIsAvailableLogo, LOGO_WHITE: WhoIsAvailableLogoWhite, TITLE: 'WIA', LOGO_HREF: 'https://motion-software.com/' },
    development: { LOGO: <CoderyLogoDark className="company-logo" />, LOGO_WHITE: <CoderyLogoWhite className="company-logo" />, TITLE: 'Codery', LOGO_HREF: 'https://motion-software.com/' },
    qa: { LOGO: <CoderyLogoDark height={24} className="company-logo" />, LOGO_WHITE: <CoderyLogoWhite className="company-logo" />, TITLE: 'Codery', LOGO_HREF: 'https://motion-software.com/' },
    production: { LOGO: <CoderyLogoDark className="company-logo" />, LOGO_WHITE: <CoderyLogoWhite className="company-logo" />, TITLE: 'Codery', LOGO_HREF: 'https://motion-software.com/' },
    whoisavailable: { LOGO: <CoderyLogoDark className="company-logo" />, LOGO_WHITE: <CoderyLogoWhite className="company-logo" />, TITLE: 'Codery', LOGO_HREF: 'https://whoisavailable.io/' },
    staging: { LOGO: WhoIsAvailableLogo, LOGO_WHITE: WhoIsAvailableLogoWhite, TITLE: 'WIA', LOGO_HREF: 'https://motion-software.com/' },
};

const APP_LOGIN_BACKGROUNDS = {
    test: MotionSoftwareLoginBackground,
    development: MotionSoftwareLoginBackground,
    qa: MotionSoftwareLoginBackground,
    production: MotionSoftwareLoginBackground,
    whoisavailable: WhoIsAvailableLoginBackground,
    staging: MotionSoftwareLoginBackground,
};

const SUPPORT_EMAILS = {
    test: 'support@wiasaas.com',
    development: 'support@wiasaas.com',
    qa: 'support@wiasaas.com',
    production: 'support@wiasaas.com',
    whoisavailable: 'support@wiasaas.com',
    staging: 'support@wiasaas.com',
};

export const ENVIRONMENTS = {
    DEVELOPMENT: 'development',
    QA: 'qa',
    PRODUCTION: 'production',
    WHOISAVAILABLE: 'whoisavailable',
    STAGING: 'staging',
};

const CANDIDATE_SIGN_UP_LAYOUTS = {
    development: ProdGuestLayout,
    qa: ProdGuestLayout,
    production: ProdGuestLayout,
    whoisavailable: GuestLayout,
    staging: ProdGuestLayout,
}

const DEFAULT_AGENCY_IDS = {
    development: '661cec9faf14e018e768e662',
    qa: '661cec9faf14e018e768e662',
    production: '659bb2b757cea20ae30598db',
    whoisavailable: '659bb2b757cea20ae30598db',
    staging: '64b8e9f1f0880e7a44f50fff',
    unit_testing: '616e7d134ff6dd0f7443d8d9',
};

export const IS_WHOISAVAILABLE_ENV = process.env.REACT_APP_ENV === ENVIRONMENTS.WHOISAVAILABLE || process.env.NODE_ENV === ENVIRONMENTS.WHOISAVAILABLE;

export const MOTION_SOFTWARE_AGENCY_NAME = 'Motion Software';
export const MOTION_SOFTWARE_WEBSITE_URL = 'https://motion-software.com';
export const APP_VERSION = preval`module.exports = process.env.REACT_APP_VERSION + '.' + Math.trunc(Date.now() / 1000);`;
export const BASE_URL = SERVER_ADDRESSES[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const BASE_APP_URL = APP_ADDRESS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const FIREBASE_CONFIG = FIREBASE_CONFIGS[process.env.REACT_APP_ENV || process.env.NODE_ENV]
export const AMPLITUDE_CONFIG = AMPLITUDE_CONFIGS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const BASE_APP_TRADEMARK = APP_TRADEMARKS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const APP_LOGIN_BACKGROUND = APP_LOGIN_BACKGROUNDS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const SUPPORT_EMAIL = SUPPORT_EMAILS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const CANDIDATE_SIGN_UP_LAYOUT = CANDIDATE_SIGN_UP_LAYOUTS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const DEFAULT_AGENCY_ID = DEFAULT_AGENCY_IDS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
