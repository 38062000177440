import React, { useEffect, useState, createContext, useContext } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ProjectsOverviewSupplier from '../ProjectsOverviewSupplier';
import SupplierBilling from 'components/Billing/Billing';
import JobsList from 'components/JobsDashboard/JobsList';
import { mapQueryToFilters } from 'components/JobsDashboard/JobsDashboardUtils';

import CircularProgress from '@material-ui/core/CircularProgress';

import { parseQueryString, updateQuery } from 'utils/helpers';

import { fetchSupplierDashboardInformation } from 'actions/supplierActions';
import { fetchAgencyBasicInfo } from 'actions/agencyActions';
import { getAllInvoices, getDashboardDataFetchState } from 'reducers';

import { INITIAL_JOBS_DASHBOARD_FILTERS } from 'components/JobsDashboard/JobsDashboardConstants';

import './SupplierAdminDashboard.scss';

export const SupplierAdminDashboardContext = createContext({});

const { Provider } = SupplierAdminDashboardContext;

const SupplierAdminDashboard = ({
    agencyId,
    userId,
    fetchSupplierDashboardInformation,
    fetchAgencyBasicInfo,
    dataFetchState,
    invoices,
    children,
}) => {
    const [filters, setFilters] = useState(INITIAL_JOBS_DASHBOARD_FILTERS);

    const history = useHistory();

    useEffect(() => {
        fetchSupplierDashboardInformation(agencyId, userId);
        fetchAgencyBasicInfo(agencyId);
    }, []);

    useEffect(() => {
        const queryString = history.location.search;
        const parsedQuery = parseQueryString(queryString);

        if (Object.keys(parsedQuery).length > 0) {
            setFilters(mapQueryToFilters(parsedQuery));
        } else {
            setFilters(INITIAL_JOBS_DASHBOARD_FILTERS);
        }
    }, [history.location.search]);

    const handleSingleSelectFilterChange = (filterName, value) => {
        const filterObj = filters[filterName] !== value
            ? { [filterName]: value }
            : { [filterName]: '' };

        const updatedQuery = updateQuery(history.location.search, filterObj);

        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    const context = { userId, agencyId, handleSingleSelectFilterChange, filters, invoices };

    return (
        <div>
            {dataFetchState.isDataFetching && !dataFetchState.isDataFetched
                ? <CircularProgress thickness={5} size={50} disableShrink className="page-loader" />
                : <Provider value={context}>
                    <div className="supplier-admin-dashboard-wrapper">
                        {children}
                    </div>
                </Provider>
            }
        </div>
    )
};

const ProjectsOverviewWrapper = () => {
    const { userId, agencyId } = useContext(SupplierAdminDashboardContext);

    return (
        <div className="supplier-dashboard-inner-content-wrapper">
            <ProjectsOverviewSupplier userId={userId} agencyId={agencyId} isDataAvailable={true} />
        </div>
    )
};

const SupplierJobs = () => {
    const { userId, agencyId, filters, handleSingleSelectFilterChange } = useContext(SupplierAdminDashboardContext);

    return (
        <div className="supplier-dashboard-inner-content-wrapper">
            <JobsList
                agencyId={agencyId}
                filters={filters}
                handleSingleSelectFilterChange={handleSingleSelectFilterChange}
                supplierId={userId}
                isDashboardPage={true}
            />
        </div>
    )
}

const Invoices = () => {
    const { invoices } = useContext(SupplierAdminDashboardContext);

    return (
        invoices.length > 0 &&
        <div className="supplier-dashboard-inner-content-wrapper">
        </div>
    );
};

const Billing = () => {
    const { userId, agencyId } = useContext(SupplierAdminDashboardContext);

    return <SupplierBilling
        userId={userId}
        agencyId={agencyId}
        withoutFilter={true}
        isDataAvailable={true}
    />
};

SupplierAdminDashboard.ProjectsOverview = ProjectsOverviewWrapper;
SupplierAdminDashboard.Billing = Billing;
SupplierAdminDashboard.Invoices = Invoices;
SupplierAdminDashboard.SupplierJobs = SupplierJobs;

const mapStateToProps = state => {
    return {
        invoices: getAllInvoices(state),
        dataFetchState: getDashboardDataFetchState(state),
    }
}

const mapDispatchToProps = {
    fetchSupplierDashboardInformation,
    fetchAgencyBasicInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierAdminDashboard);
